import React, { ReactElement, useContext, useEffect, useState } from "react";
import { TabComponent } from "../Tab";
import Select from "react-select";
import shared from '../sharedstyle.module.scss';
import { OptionType } from "../../../models/OptionType";
import { defaultThresholdColor, defaultTitleFontIndex, defaultTitleFontSize, defaultLegendFontIndex, defaultLegendFontSize, FontOptions, FontSizes, ReactSelectStyles } from "../../../misc/Constants";
import { TabContext } from "../../WidgetOptions";
import { BarChartDataTabName, BarChartDataTabProps } from "./BarChartDataTab";
import { ColorPicker } from "../../ColorPicker";
import { Metric } from "../../../models/Metric";
import { getNextUnusedColor, IsNotUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../../misc/Helpers";

import style from './style.module.scss';
import classNames from "classnames";
import { UserPsaps } from "../../../models/UserPsaps";
import { BarChartMainTabName, BarChartMainTabProps } from "./BarChartMainTab";
import { WidgetLabel } from "../../../interfaces/WidgetLabel";

interface BarConfigurationProps {
    metricOrPsapId: string;
    defaultName: string;
    color: string;
    name: string;
    onChange: (key: string, value: string, id: string) => void;
}

export const BarChartDesignTabName = "BarChartDesignTab";

const BarConfiguration = (props: BarConfigurationProps): ReactElement => {

    const [name, setName] = useState(props.name);
    const [color, setColor] = useState(props.color);

    const onNameChange = (name: string) => {
        setName(name);
        props.onChange("name", name, props.metricOrPsapId);
    };

    const onColorChange = (color: string) => {
        setColor(color);
        props.onChange("color", color, props.metricOrPsapId);
    };

    return (
        <div className={style.barConfig}>
            <div className={style.row}>
                <div className={style.name}>
                    <div>
                        <label>Rename bar label</label>
                        {
                            name !== props.defaultName &&
                            <span onClick={() => onNameChange(props.defaultName)}>reset</span>
                        }
                    </div>
                    <input
                        type="text"
                        value={name}
                        className="form-control"
                        onChange={e => onNameChange(e.target.value)} />
                </div>


                <div className={style.color}>
                    <label>Pick color</label>
                    <ColorPicker color={color} onValueChange={color => onColorChange(color)} />
                </div>
            </div>
        </div>
    );
}

interface BarConfig {
    // Id of either a metric or PSAP for this bar
    id: string;

    // Name and color of the label
    name: string;
    color: string;
}

export function initBarConfig(psaps: UserPsaps[], selectedPsapIds: string[], metrics: Metric[], selectedMetricIds: string[], legendConfig: BarConfig[]): BarConfig[] {
    let newLegendConfig;

    // Bar chart has 2 modes, metric bars or psap bars
    if (selectedMetricIds?.length >= selectedPsapIds?.length) {
        // Create a new default bar config from selected metrics, in same order
        let selectedMetrics: Metric[] = [];
        selectedMetrics = selectedMetricIds.filter(s => IsNotUndefinedOrNull(metrics.find(m => m.id === s))).map((item) => metrics.find(x => x.id === item));

        newLegendConfig = selectedMetrics.map(i => { return { id: i.id, name: i.displayNameKey, color: "" } });
    }
    else {
        // Create a new default bar config from selected psaps, in same order
        let selectedPsaps = [];
        if (IsNotUndefinedOrNull(selectedPsapIds) && selectedPsapIds.length > 0)
            selectedPsaps = selectedPsapIds.map((item) => psaps.find(p => p.nenaIdentifier === item)).filter(x => IsNotUndefinedOrNull(x));

        newLegendConfig = selectedPsaps.map(i => { return { id: i.nenaIdentifier, name: i.psapName, color: "" } })
    }

    // Update with previous legend data if any
    newLegendConfig.forEach(i => {
        let item = legendConfig?.find(j => j.id === i.id)
        if (IsNotUndefinedOrNull(item)) {
            i.name = item.name;
            i.color = item.color;
        }
    })

    // Update bar config items with no color with an unused color
    newLegendConfig.forEach(i => {
        if (i.color === "")
            i.color = getNextUnusedColor(newLegendConfig.map(i => i.color))
    })

    return newLegendConfig;
};

// Initialize value label as a PSAP. This is for multi-metric bar charts
export function initMainLabelAsPsap(psaps: UserPsaps[], selectedPsapId: string, mainLabel: WidgetLabel): WidgetLabel {
    let newLabel: WidgetLabel = { id: selectedPsapId, name: "" };
    if (mainLabel?.id === selectedPsapId)
        newLabel.name = mainLabel?.name
    else
        newLabel.name = psaps.find(i => i.nenaIdentifier === selectedPsapId)?.psapName;

    return newLabel;
}

// Initialize value label as a PSAP. This is for multi-psap bar charts
export function initMainLabelAsMetric(metrics: Metric[], selectedMetricId: string, mainLabel: WidgetLabel): WidgetLabel {
    let newLabel: WidgetLabel = { id: selectedMetricId, name: "" };
    if (mainLabel?.id === selectedMetricId)
        newLabel.name = mainLabel?.name
    else
        newLabel.name = metrics.find(i => i.id === selectedMetricId)?.displayNameKey;

    return newLabel;
}

export interface BarChartDesignTabProps extends TabComponent {

    metrics: Metric[];
    psaps: UserPsaps[];

    // The list of bar label names and colors
    barConfig?: BarConfig[]

    // The label for the item for which the bars are shown. Can be a metric name (multi-psap chart), or a psap name (multi-metric chart)
    mainLabel?: WidgetLabel;

    titleFontSize?: number;
    titleFontValue?: number;

    legendFontSize?: number;
    legendFontValue?: number;

    fontColor?: string;

    thresholdColor?: string;
}

export const BarChartDesignTab = (props: BarChartDesignTabProps): ReactElement => {

    const context = useContext(TabContext);
    const dataTab = context.data[BarChartDataTabName] as BarChartDataTabProps;
    //const mainTab = context.data[BarChartMainTabName] as BarChartMainTabProps;

    const initialTitleFontSize = isNaN(props.titleFontSize) ? defaultTitleFontSize : props.titleFontSize;
    const [titleFontSize, setTitleFontSize] = useState(initialTitleFontSize);

    const titleFontValue = isNaN(props.titleFontValue) ? NaN : props.titleFontValue;
    let initialTitleFont = FontOptions.find(f => f.value === titleFontValue);
    if (typeof initialTitleFont === "undefined") {
        initialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    const initialLegendFontSize = isNaN(props.legendFontSize) ? defaultLegendFontSize : props.legendFontSize;
    const [legendFontSize, setLegendFontSize] = useState(initialLegendFontSize);

    let initialLegendFont = FontOptions.find(f => f.value === props.legendFontValue)
    if (typeof initialLegendFont === "undefined") {
        initialLegendFont = FontOptions[defaultLegendFontIndex];
    }

    const initialFontColor = StringIsNullOrWhiteSpace(props.fontColor) ? "#555555" : props.fontColor;
    const [fontColor, setFontColor] = useState(initialFontColor);

    const [titleFont, setTitleFont] = useState(initialTitleFont);
    const [legendFont, setLegendFont] = useState(initialLegendFont);

    const initialThresholdFontColor = StringIsNullOrWhiteSpace(props.thresholdColor) ? defaultThresholdColor : props.thresholdColor;
    const [thresholdColor, setThresholdColor] = useState(initialThresholdFontColor);

    const selectedMetricsIds: string[] = Array.isArray(dataTab.selectedMetrics) ? dataTab.selectedMetrics : [];
    const selectedMetrics: Metric[] = props.metrics.filter(m => selectedMetricsIds.some(p => p === m.id));

    const selectedPsapsIds: string[] = Array.isArray(dataTab.selectedPsapIds) ? dataTab.selectedPsapIds : [];
    const selectedPsaps: UserPsaps[] = selectedPsapsIds.map((item) => props.psaps.find(x => x.nenaIdentifier === item));

    // Only 16 bars supported at this time
    if (selectedMetrics.length > 16 || selectedPsaps.length > 16)
        console.error("Configuring bar charts with more than 16 bars is not supported")

    // True if bar labels represents metrics, false if bars represent PSAPs 
    let metricBars = selectedMetrics.length >= selectedPsaps.length;

    // Update initialBarConfig with the selected metrics (or PSAP) from the props
    let initialBarConfig = initBarConfig(props.psaps, selectedPsapsIds, props.metrics, selectedMetricsIds, props.barConfig)

    const [barConfiguration, setBarConfiguration] = useState(initialBarConfig);

    // If multi-metric bar chart, the value label represents a PSAP. If multi-psap bar chart, the value represent a metric name.
    let initialMainLabel;
    if (metricBars)
        initialMainLabel = initMainLabelAsPsap(props.psaps, selectedPsapsIds.length > 0 ? selectedPsapsIds[0] : "", props.mainLabel) as WidgetLabel;
    else
        initialMainLabel = initMainLabelAsMetric(props.metrics, selectedMetricsIds.length > 0 ? selectedMetricsIds[0] : "", props.mainLabel) as WidgetLabel;

    const [mainLabel, setMainLabel] = useState(initialMainLabel);

    // Get default value label for reset option
    const getDefaultMainLabel = (): string => {
        if (metricBars)
            return props.psaps.find(i => i.nenaIdentifier === selectedPsapsIds?.[0])?.psapName ?? "psap not selected";
        else
            return props.metrics.find(i => i.id === selectedMetricsIds?.[0])?.displayNameKey ?? "metric not selected";
    }

    const onBarConfigChange = (key: string, value: string, id: string): void => {

        let chg: BarConfig[];
        setBarConfiguration(old => {

            chg = old.map(e => {
                if (e.id === id) {
                    e[key] = value;
                }
                return e;
            });

            return [...chg];
        });
    };

    const titleFontValueChange = (option: OptionType): void => {
        setTitleFont(option);
        typeof props.onChange === "function" && props.onChange("titleFontValue", option.value);
    };

    const titleFontSizeChange = (value: number): void => {
        setTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("titleFontSize", value);
    };

    const legendFontValueChange = (option: OptionType): void => {
        setLegendFont(option);
        typeof props.onChange === "function" && props.onChange("legendFontValue", option.value);
    };

    const legendFontSizeChange = (value: number): void => {
        setLegendFontSize(value);
        typeof props.onChange === "function" && props.onChange("legendFontSize", value);
    };

    const fontColorChange = (color: string): void => {
        setFontColor(color);
        typeof props.onChange === "function" && props.onChange("fontColor", color);
    };

    const ThresholdColorChange = (color: string): void => {
        setThresholdColor(color);
        typeof props.onChange === "function" && props.onChange("thresholdColor", color);
    };

    const onMainLabelChange = (label: string): void => {
        let labelId;
        if (metricBars)
            labelId = selectedPsapsIds.length > 0 ? selectedPsapsIds[0] : ""
        else
            labelId = selectedMetricsIds.length > 0 ? selectedMetricsIds[0] : ""

        const newLabel: WidgetLabel = { name: label, id: labelId }
        setMainLabel(newLabel);
        typeof props.onChange === "function" && props.onChange("mainLabel", newLabel);
    }

    useEffect(() => {
        // Call onChange whenever barConfiguration changes
        props.onChange("barConfig", barConfiguration);
    }, [barConfiguration]);

    let mainLabelHeader = "Main label customization (PSAPs)"
    let barConfigLabelHeader = "Bar label customization (Metrics)";
    if (!metricBars) {
        barConfigLabelHeader = "Bar label customization (PSAPs)";
        mainLabelHeader = "Main label customization (Metrics)"
    }

    return (

        <React.Fragment>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change appearance</label>
                </div>

                <div className={style.name}>
                    <label>Title</label>
                </div>

                <div className={style.fontControl}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={titleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={titleFontValueChange}
                        />
                        <label>font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={titleFontSize} onChange={e => titleFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>

                </div>
            </div>

            <div className={shared.row}>

                <div className={style.name}>
                    <label>Legend</label>
                </div>

                <div className={style.fontControl}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={legendFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={legendFontValueChange}
                        />
                        <label>font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={legendFontSize} onChange={e => legendFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>

                </div>

            </div>

            <div className={shared.row}>
                <div className={style.coptions}>
                    <div className={style.color}>
                        <ColorPicker
                            label="font color"
                            color={fontColor}
                            onValueChange={fontColorChange}
                        />
                    </div>
                </div>
            </div>


            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>{barConfigLabelHeader}</label>
                </div>

                {
                    selectedMetrics.length === 0 &&
                    <div className={style.message}>
                        <span>Select metrics in Data tab first</span>
                    </div>
                }
                {
                    selectedMetrics.length > 0 &&

                    <div className={style.barConfig}>

                        {
                            metricBars &&
                            barConfiguration.filter(c => c.id !== "").map((option, idx) => {
                                const m = props.metrics.find(p => p.id === option.id);

                                return (
                                    <BarConfiguration
                                        key={idx}
                                        defaultName={m.displayNameKey}
                                        metricOrPsapId={option.id}
                                        onChange={onBarConfigChange}
                                        name={option.name}
                                        color={option.color} />
                                );
                            })
                        }
                        {
                            !metricBars &&
                            barConfiguration.filter(c => c.id !== "").map((option, idx) => {
                                const m = selectedPsaps.find(p => p.nenaIdentifier === option.id);
                                if (IsNotUndefinedOrNull(m)) {
                                    return (
                                        <BarConfiguration
                                            key={idx}
                                            defaultName={m.psapName}
                                            metricOrPsapId={option.id}
                                            onChange={onBarConfigChange}
                                            name={option.name}
                                            color={option.color} />
                                    );
                                }
                            })
                        }
                    </div>

                }
            </div>
            <div className={shared.row}>
                <div className={shared.heading}>
                    {
                        ((!metricBars && selectedMetricsIds.length > 0) || (metricBars && selectedPsapsIds.length > 0)) &&
                        <label>{mainLabelHeader}</label>
                    }
                </div>

                <div className={style.barConfig}>
                    <div className={style.row}>
                        <div className={style.name} key={"target"}>
                            <div>
                                {
                                    !metricBars && selectedMetricsIds.length > 0 &&
                                    <label>Rename Metric label </label>
                                }
                                {
                                    metricBars && selectedPsapsIds.length > 0 &&
                                    <label>Rename Psap label </label>
                                }
                                {
                                    ((!metricBars && selectedMetricsIds.length > 0) || (metricBars && selectedPsapsIds.length > 0)) && mainLabel.name !== getDefaultMainLabel() &&
                                    <span onClick={() => onMainLabelChange(getDefaultMainLabel())}>reset</span>
                                }
                            </div>
                            {
                                ((!metricBars && selectedMetricsIds.length > 0) || (metricBars && selectedPsapsIds.length > 0)) &&
                                <input
                                    type="text"
                                    value={mainLabel.name}
                                    className="form-control"
                                    onChange={e => onMainLabelChange(e.target.value)} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (dataTab.useMaxThreshold || dataTab.useMinThreshold) &&
                <div className={shared.row}>
                    <div className={shared.heading}>
                        <label>Threshold color</label>
                    </div>
                    <div className={style.threshold}>
                        <ColorPicker
                            color={thresholdColor}
                            onValueChange={ThresholdColorChange}
                        />
                    </div>
                </div>
            }

        </React.Fragment >
    );
}