import React, { useState } from "react";
import { TabComponent } from "../Tab";
import Switch from "react-switch";
import classNames from "classnames";

import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace, SetToZeroIfNegativeValue } from "../../../misc/Helpers";

import { MultiMetricMinMaxValues } from "../../../misc/Constants";
import { LabelControlWithToolTip } from "../../Labels/LabelControlWithToolTip";

export interface MultiMetricMainTabProps extends TabComponent {
    title?: string;
    minValue?: number;
    maxValue?: number;
    useThreshold?: boolean;
    thresholdValue?: number;
}

export const MultiMetricMainTabName = "MultiMetricMainTab";

export const MultiMetricMainTab = (props: MultiMetricMainTabProps) => {

    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;

    const [title, setTitle] = useState(initialTitle);

    const [minValueValid, setMinValueValid] = useState(true);
    const [minValue, setMinValue] = useState(isNaN(props.minValue) ? MultiMetricMinMaxValues.min : props.minValue);

    const [maxValueValid, setMaxValueValid] = useState(true);
    const [maxValue, setMaxValue] = useState(isNaN(props.maxValue) ? MultiMetricMinMaxValues.max : props.maxValue);

    const [useThreshold, setUseTrashold] = useState(props.useThreshold || false as boolean);
    const [thresholdValueValid, setThresholdValueValid] = useState(true);
    const [thresholdValue, setThresholdValue] = useState(isNaN(props.thresholdValue) ? 100 : props.thresholdValue);

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };


    const onMinValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setMinValue(value);

        let valid = true;
        valid = value < maxValue;
        setMinValueValid(valid);

        typeof props.onChange === "function" && props.onChange("minValue", value);
    };

    const onMaxValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setMaxValue(value);

        let valid = true;
        valid = (value > minValue);
        setMaxValueValid(valid);

        typeof props.onChange === "function" && props.onChange("maxValue", value);
    };

    const onUseThresholdChange = (active: boolean): void => {
        setUseTrashold(active);
        typeof props.onChange === "function" && props.onChange("useThreshold", active);
    };

    const onThresholdValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setThresholdValue(value);

        let valid = true;
        valid = (value >= minValue && value <= maxValue);
        setThresholdValueValid(valid);

        typeof props.onChange === "function" && props.onChange("thresholdValue", value);
    };

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="title"
                        maxLength={100}
                        onChange={e => TitleChange(e.target.value)}
                        value={title}
                    />
                </div>
            </div>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Scale Minimum"} placement="right" title={"Set the minimum value on the scale"} />
                </div>


                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={minValueValid ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMinValueChange(+e.target.value)}
                            type="number"
                            value={minValue} />
                    </div>
                </div>
                <div className={shared.spacer} />

                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Scale Maximum"} placement="right" title={"Set the maximum value on the scale"} />
                </div>

                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={maxValueValid ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMaxValueChange(+e.target.value)}
                            type="number"
                            value={maxValue} />
                    </div>
                </div>
                <div className={shared.spacer} />

                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Threshold"} placement="right" title={"Threshold configuration"} />
                </div>
                <div>
                    <Switch
                        onColor={"#0277CB"}
                        activeBoxShadow={null}
                        height={20}
                        width={48}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checked={useThreshold}
                        onChange={() => { onUseThresholdChange(!useThreshold) }}
                    />
                </div>

                {
                    useThreshold &&
                    <div className={classNames(style.inputarea, style.data)}>
                        <input
                            className={thresholdValueValid ? "form-control" : "form-control warn"}
                            min={0}
                            max={1000}
                            onChange={(e) => onThresholdValueChange(+e.target.value)}
                            type="number"
                            value={thresholdValue} />
                        <span>Threshold value</span>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}