// Data type of the metric data
export enum DataType {
    Number = 0,
    String,
    NumberArray,
    CallArray
}

// Metric definition type
export interface Metric {
    id?: string;
    displayNameKey?: string;
    version?: string;
    metricDataType?:DataType;
    metricGroup?: string;
    streamName?: string;
    streamDataType?: DataType;
    bindProperty?: string;
    bindFunction?:number;
    defaultValue?:number;
    rowDefinition?: number;
}


export enum RowDefinition {
    PSAP = 0,
    None = 1,
    Enum = 2
};