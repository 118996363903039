
export enum WidgetEquations {
    Min = "Min",
    Max = "Max",
    Sum = "Sum",
    Average = "Average",
    Percentage = "Percentage"	// Part of the definition in ECATS-3800, but I don't see how to calculate a percentage from an array of numbers.
}

const Min = (arr: Array<number>): number => {
    return Math.min(...arr);
}

const Max = (arr: Array<number>): number => {
    return Math.max(...arr);
}

const Sum = (arr: Array<number>): number => {
    let sum: number = 0;
    arr.forEach((val) => sum = sum + val);
    return sum;
}

const Average = (arr: Array<number>): number => {
    return +(Sum(arr) / arr.length).toFixed(2);
}

const Percentage = (arr: Array<number>, value: number): number => {
    let sum = 0;
    sum = Sum(arr);
    return +Math.round((value / Sum(arr)) * 100);
}

const EquationEnumToFunction = (eq: WidgetEquations): Function => {
    switch (eq) {
        case WidgetEquations.Min:
            return Min;
        case WidgetEquations.Max:
            return Max;
        case WidgetEquations.Sum:
            return Sum;
        case WidgetEquations.Average:
            return Average;
        case WidgetEquations.Percentage:
            return Percentage;            
        default:
            return undefined;
    }
}

export { Sum, Min, Max, Average, EquationEnumToFunction, Percentage }

