import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { DataAdapterProps } from "./DataAdapter";

import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import { SingleMetricMainTabProps, SingleMetricMainTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricMainTab";
import { SingleMetricDataTabProps, SingleMetricDataTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricDataTab";
import { SingleMetricDesignTabProps, SingleMetricDesignTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricDesignTab";
import { IsNotUndefinedOrNull, IsUndefinedOrNull } from "../misc/Helpers";
import EdashInterfaces from '@ecats911/edash-interfaces';
import { defaultTitleFontIndex, defaultTitleFontSize, defaultValueFontIndex, defaultValueFontSize, defaultLegendFontIndex, defaultLegendFontSize, FontOptions, SingleMetricWidgetMinMaxValues, WidgetName } from "../misc/Constants";
import { Styles } from "../interfaces/Dictionary";
import { EquationEnumToFunction } from "../misc/WidgetEquations";
import { ContainerOverride } from "../components/GenericContainer";
import { SemiCircleWidgetProps } from "@ecats911/edash-widgets-semicircle";

export class SingleMetricConfigAdapter extends WidgetConfigAdapter<SemiCircleWidgetProps> {

    widgetId: string = "";
    main: SingleMetricMainTabProps;
    data: SingleMetricDataTabProps;
    design: SingleMetricDesignTabProps;
    psaps: UserPsaps[];
    metrics: Metric[];
    containerOverride: ContainerOverride = null;

    constructor(widgetId: string, config: object, psaps: UserPsaps[], metrics: Metric[], containerOverride: ContainerOverride, sortOrder: string) {
        super();
        this.widgetId = widgetId;

        this.main = config[SingleMetricMainTabName];
        this.data = config[SingleMetricDataTabName];
        this.design = config[SingleMetricDesignTabName];

        if (IsUndefinedOrNull(this.main))
            this.main = {};

        if (IsUndefinedOrNull(this.data))
            this.data = { psaps: psaps, metrics: metrics, sortOrder: sortOrder, mainTabName: SingleMetricMainTabName, designTabName: SingleMetricDesignTabName };

        if (IsUndefinedOrNull(this.design))
            this.design = { dataTabName: SingleMetricDataTabName };


        this.psaps = psaps;
        this.metrics = metrics;
        this.containerOverride = containerOverride;
    }

    getDataConfiguration = (): DataAdapterProps => {


        const metric = this.metrics.find(m => m.id === this.data.selectedMetric);

        let selectedPsapNenaIds;
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            selectedPsapNenaIds = [this.containerOverride.psap.nenaIdentifier];
        }
        else {
            selectedPsapNenaIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];
            selectedPsapNenaIds = this.psaps.filter((p: UserPsaps) => selectedPsapNenaIds.some(x => x === p.nenaIdentifier)).map(g => g.nenaIdentifier)
        }

        const p: DataAdapterProps = {
            metricDefinitions: IsUndefinedOrNull(metric) ? [] : [metric],
            psapNenaIds: selectedPsapNenaIds,
            equation: EquationEnumToFunction(this.data.selectedEquation),
        };
        return p;
    };

    getWidgetConfiguration = (widgetName: WidgetName, widgetParams: EdashInterfaces.WidgetParameters, psapFilter: string | null): SemiCircleWidgetProps => {

        let style: Styles = {};

        switch (widgetName) {
            case WidgetName.Circle:
            case WidgetName.SemiCircle:
                style = this.getStylesForCircleWidget();
                break;
            case WidgetName.Ring:
                style = this.getStylesForRingWidget();
                break;
            default:
                break;
        }

        const metric = this.metrics.find(m => m.id === this.data.selectedMetric);

        const base: EdashInterfaces.WidgetBase = {
            id: this.widgetId,
            style: style,
            title: this.main.title,
            metric: this.design?.metricLabel?.name ? [this.design?.metricLabel.name] : IsNotUndefinedOrNull(metric) ? [metric.displayNameKey] : []
        };

        /*if (this.main.useMaxThreshold) {
            base.thresholdMax = isNaN(this.main.thresholdMaxValue) ? [100] : [this.main.thresholdMaxValue];
        }

        if (this.main.useMinThreshold) {
            base.thresholdMin = isNaN(this.main.thresholdMinValue) ? [0] : [this.main.thresholdMinValue]
        }*/

        let selectedPsapIds: string[] = [];
        let psapNames: string[];
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            psapNames = []; // To maximize the space for widgets, we won't display the PSAP name as it's already displayed in Sorted Container
            let psapNenaId = this.psaps.find(j => this.containerOverride.psap.nenaIdentifier === j.nenaIdentifier)?.nenaIdentifier            
            selectedPsapIds = IsNotUndefinedOrNull(psapNenaId) ? [psapNenaId] : []
        }
        else {
            selectedPsapIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];

            const filterApplies = psapFilter !== null && selectedPsapIds.some(id => id === psapFilter);

            psapNames = selectedPsapIds.filter(id => {
                if (!filterApplies) return true;
                return id === psapFilter;
            }).map(psapId => {
                let label = this.design?.psapLabels?.find(i => i.id === psapId)?.name
                if (IsUndefinedOrNull(label))
                    label = this.psaps.find(x => x.nenaIdentifier === psapId)?.psapName

                return label;
            })
        }

        // No psap is provided when a widget is set with a network metric. Avoid the 'Please select psap' that most single-widgets are displaying when no psap is provided
        if (psapNames.length === 0)
            psapNames.push("")

        const model = {
            thresholdMin: this.data.useMinThreshold ? isNaN(this.data.thresholdMinValue) ? [SingleMetricWidgetMinMaxValues.min] : [this.data.thresholdMinValue] : [],
            thresholdMax: this.data.useMaxThreshold ? isNaN(this.data.thresholdMaxValue) ? [SingleMetricWidgetMinMaxValues.max] : [this.data.thresholdMaxValue] : [],
            min: this.main.minValue ?? SingleMetricWidgetMinMaxValues.min,
            max: this.main.maxValue ?? SingleMetricWidgetMinMaxValues.max,
            selectedPSAPs: psapNames,
            selectedPSAPsNenaIds: selectedPsapIds,
            animatedThreshold: IsNotUndefinedOrNull(this.data?.animatedThreshold) ? this.data?.animatedThreshold : true,
            parameters: widgetParams
        };

        return { ...base, ...model };
    }

    private getStylesForCircleWidget(): Styles {
        return {
            title: {
                color: this.design.titleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
                fontSize: this.design.titleFontSize ?? defaultTitleFontSize,
                fontWeight: "bold", // not implemented
            },
            psap: {
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
                fontSize: 13,
                textDecoration: "underline"
            },
            legend: {
                color: typeof (this.design.titleFontColor) !== 'undefined' ? this.design.titleFontColor : '#000',
                fontFamily: FontOptions.find(font => font.value === this.design.legendFontValue)?.label ?? FontOptions[defaultLegendFontIndex].label,
                fontSize: this.design.legendFontSize ?? defaultLegendFontSize
            },
            threshold: {
                color: this.design.thresholdColor,
            },
            metric: {
                color: '#785ef0',
            },
            value: {
                color: this.design.titleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.valueFontValue)?.label ?? FontOptions[defaultValueFontIndex].label,
                fontSize: this.design.valueFontSize ?? defaultValueFontSize,
                textDecoration: "none"
            }
        }
    };

    private getStylesForRingWidget(): Styles {
        const base = this.getStylesForCircleWidget();

        const additional = {
            thresholdMarker: {
                color: "#000",
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? "Arial",
                fontSize: 13
            }
        }

        return { ...base, ...additional };
    };

    getUnfilteredSelectedPsapIds = (): string[] => {
        return this.data?.selectedPsapIds;
    }
}