import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { WidgetManifest } from '../interfaces/WidgetManifest';
import { SemiCircleWidget } from '@ecats911/edash-widgets-semicircle';
import { NumberWidget } from '@ecats911/edash-widgets-number';
import { CircleWidget } from '@ecats911/edash-widgets-circle';
import { RingWidget } from '@ecats911/edash-widgets-ring';
import { BarChart } from '@ecats911/edash-widgets-barchart';
import { MultipleCircles } from '@ecats911/edash-widgets-multiplerings';
import { IsUndefinedOrNull } from '../misc/Helpers';
import { defaultMetricColors, PubSubTopic, WidgetName } from '../misc/Constants';
import { Message } from '../misc/Messages';
import cuid from 'cuid';

import style from '../scss/widgetssidepanel.module.scss';

import chevronLeft from '../icons/chevron-left.svg'
import chevronLeftInCircle from '../icons/chevron-left-circle.svg'
import chevronRightInCircle from '../icons/chevron-right-circle.svg'
import containerIcon from '../icons/container-widget.svg'
import sortingContainer from '../icons/sorting-container-widget.svg'
import { TooltipControl } from './ToolTipControl';

export interface WidgetCategory {
    id: string;
    label: string;
    helpText: string;
    components: WidgetManifest[];
    expanded: boolean;
    image: string;
}

interface IPanelSection extends WidgetCategory {
    expanded: boolean;
    onTabClick: () => void;
    menuExpanded: boolean;
}

interface DragWrapperProps {
    widgetName: WidgetName;
    draggable?: boolean;
    children?: any;
}

const DragWrapper = (props: DragWrapperProps): JSX.Element => {

    if (IsUndefinedOrNull(props.children)) {
        return null;
    }

    let widgetId = "";

    const StartDragWidget = (e): void => {

        widgetId = cuid();

        // this line is required by FireFox
        e.dataTransfer.setData('text/plain', '');

        PubSub.publish(PubSubTopic.Action, {
            id: Message.StartDragWidget,
            data: {
                id: widgetId,
                name: props.widgetName
            }
        });
    };

    const EndDragWidget = (e): void => {

        PubSub.publish(PubSubTopic.Action, {
            id: Message.EndDragWidget,
            data: {
                id: widgetId,
                name: props.widgetName
            }
        });
    };

    return (
        <div
            draggable={props.draggable !== false}
            onDragStart={props.draggable !== false ? StartDragWidget : null}
            onDragEnd={props.draggable !== false ? EndDragWidget : null}
            className={style.widgetWrapper}>
            {props.children}
        </div>
    );
}

const PanelSection = (props: IPanelSection) => {

    if (props.expanded && props.menuExpanded) {
        return (
            <React.Fragment>
                <div className={classNames(style.section, style.expanded)} onClick={props.onTabClick}>
                    <img src={chevronLeft} />
                    <span className={style.expanded}>{props.label}</span>
                </div>
                <div className={style.widgets}>
                    {
                        props.components.map((w, i) => {

                            if (w.name === WidgetName.Numeric) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.Numeric}>
                                        <NumberWidget
                                            title="Numeric Widget"
                                            values={[50]} />
                                    </DragWrapper>
                                );
                            }

                            if (w.name === WidgetName.Circle) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.Circle}>
                                        <CircleWidget
                                            title="Circle Widget"
                                            selectedPSAPs={["PSAP"]}
                                            metric={["Metric"]}
                                            min={0}
                                            max={100}
                                            thresholdMax={[1000]}
                                            values={[50]} />
                                    </DragWrapper>
                                );
                            }

                            if (w.name === WidgetName.Ring) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.Ring}>
                                        <RingWidget
                                            title={"Ring Widget"}
                                            selectedPSAPs={["PSAP"]}
                                            metric={["Metric"]}
                                            min={0}
                                            max={100}
                                            thresholdMax={[1000]}
                                            values={[50]} />
                                    </DragWrapper>
                                );
                            }
                            if (w.name === WidgetName.MultiRing) {
                                const widgetStyle = {
                                    textTitle: {
                                        fontFamily: "Arial",
                                        fontSize: 15,
                                        color: "#626262",
                                    },
                                    colorMetric1: {
                                        color: defaultMetricColors[0]
                                    },
                                    colorMetric2: {
                                        color: defaultMetricColors[1]
                                    },
                                    colorMetric3: {
                                        color: defaultMetricColors[2]
                                    }
                                }
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.MultiRing} >
                                        <MultipleCircles
                                            style={widgetStyle}
                                            thresholdMax={[1000]}
                                            title={"Multi Ring Widget"}
                                            values={[80, 60, 40]}
                                            metric={[""]}
                                            psapsTitle={[""]}
                                        />
                                    </DragWrapper>
                                );
                            }

                            if (w.name === WidgetName.Bars) {
                                return (<DragWrapper key={i} widgetName={WidgetName.Bars}>
                                    <BarChart
                                        title={"Bar Chart"}
                                        values={[10, 20, 30]}
                                        min={0}
                                        max={100} />
                                </DragWrapper>
                                );
                            }

                            if (w.name === WidgetName.SemiCircle) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.SemiCircle}>
                                        <SemiCircleWidget
                                            min={0}
                                            max={100}
                                            thresholdMin={[0]}
                                            thresholdMax={[100]}
                                            selectedPSAPs={['PSAP']}
                                            title="Semi Circle Widget"
                                            values={[70]} />
                                    </DragWrapper>);
                            }

                            if (w.name === WidgetName.Map) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.Map}>
                                        <img draggable={false} src="map.png" alt="map" />
                                    </DragWrapper>);
                            }

                            if (w.name === WidgetName.GenericContainer) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.GenericContainer}>
                                        <img draggable={false} src={containerIcon} alt="generic-container" />
                                        <p className={style.widgetName}>Container</p>
                                        <p className={style.widgetDescription}>Add a container to group widgets</p>
                                    </DragWrapper>);
                            }
                            if (w.name === WidgetName.SortingContainer) {
                                return (
                                    <DragWrapper key={i} widgetName={WidgetName.SortingContainer}>
                                        <img draggable={false} src={sortingContainer} alt="sorting-container" />
                                        <p className={style.widgetName}>Sorting Container</p>
                                        <p className={style.widgetDescription}>Add a container to group and sort widgets</p>
                                    </DragWrapper>);
                            }

                            return <div key={i}>{w.name}</div>
                        })
                    }
                </div>
            </React.Fragment >
        );
    }
    else {
        return (
            <TooltipControl placement="right" title={props.helpText}>
            <div className={style.section} onClick={props.onTabClick}>
                <span>
                    {
                        props.menuExpanded &&
                        <span>
                            <img src={props.image} className={style.imageGrouping} />
                            {props.label}
                        </span>
                    }
                    {
                        !props.menuExpanded &&
                        <img src={props.image} />
                    }
                </span>
            </div>
            </TooltipControl>
        );
    }
};

export interface WidgetsSidePanelProps {

    widgetCategories: WidgetCategory[];
    onBeforeToggle: () => void;
    onToggleTab: (opt: WidgetCategory) => void;
    expanded: boolean;
}

export const WidgetsSidePanel = (props: WidgetsSidePanelProps) => {

    const onGlobalMenuToggle = (): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.ToggleWidgetsMenu
        });
    };

    if (!props.expanded) {
        return <section className={classNames(style.container, props.expanded ? null : style.collapsed)}>
            <div className={style.innterContainer}>
                <div className={style.heading} >
                    <img src={chevronRightInCircle} className={style.chevronRight} onClick={onGlobalMenuToggle} />
                </div>
            </div>
            {
                props.widgetCategories.map((category, index) =>
                    <PanelSection
                        key={index}
                        {...category}
                        expanded={category.expanded}
                        onTabClick={() => props.onToggleTab(category)}
                        menuExpanded={props.expanded} />)
            }
        </section>
    }

    const selectedCategory = props.widgetCategories.find(p => p.expanded === true);

    return (
        <section className={style.container}>
            <div className={style.innterContainer}>
                <div className={style.heading} onClick={onGlobalMenuToggle}>
                    <h3 id='add_widget_label'>Widgets</h3>
                    <img src={chevronLeftInCircle} className={style.chevronLeft} />
                </div>
                {
                    !IsUndefinedOrNull(selectedCategory) &&
                    <PanelSection
                        {...selectedCategory}
                        expanded={true}
                        onTabClick={() => props.onToggleTab(selectedCategory)}
                        menuExpanded={props.expanded} />
                }

                {
                    IsUndefinedOrNull(selectedCategory) &&
                    props.widgetCategories.map((category, index) =>
                        <PanelSection
                            key={index}
                            {...category}
                            expanded={false}
                            onTabClick={() => props.onToggleTab(category)}
                            menuExpanded={props.expanded} />)
                }

            </div>
        </section>
    );
}