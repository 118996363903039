import React, { useState, useContext } from "react";
import { TabComponent } from "../Tab";

import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import { TabContext } from "../../WidgetOptions";

import { IsFunction } from "../../../misc/Helpers";
import { TooltipControl } from "../../ToolTipControl";

import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";

export interface MapMainTabProps extends TabComponent {
    title?: string;
}

export const MapMainTabName = "MapMainTab";

export const MapMainTab = (props: MapMainTabProps) => {

    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;
    const [title, setTitle] = useState(initialTitle);

    const TitleChange = (title: string): void => {
        setTitle(title);
        typeof props.onChange === "function" && props.onChange("title", title);
    };

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="title"
                        maxLength={100}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={() => TitleChange(title)}
                        value={title}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}