import { useContext, useEffect, useState } from 'react';
import { app } from '..';
import { Message } from '../misc/Messages';
import { DashboardGridLayout, DroppingItem, GridItemWrapper } from '../components/DashboardGridLayout';
import { PubSubTopic, WidgetName } from '../misc/Constants';
import { SizeContextProvider } from '../components/SizeContextProvider';
import { WidgetsSidePanel } from '../components/WidgetsSidePanel';
import { GridItem } from '../components/GridItem';
import { AppContext } from '../interfaces/AppContext';
import { WidgetFactory } from '../services/WidgetFactory';
import { Modal } from '../components/Modal';
import { Styles } from '../interfaces/Dictionary';
import { Layout } from '../models/DashboardLayout';
import { MainStoreState } from '../interfaces/MainStoreState';
import { IsUndefinedOrNull, StringIsNullOrWhiteSpace } from '../misc/Helpers';
import { ValidationResult, WidgetOptions } from '../components/WidgetOptions';
import { ConfigBinder, WidgetConfigBinder } from '../services/WidgetConfigBinder';
import { SingleMetricConfigValidator } from '../components/WidgetOptions/SingleMetric/SingleMetricConfigValidator';
import { MapConfigValidator } from '../components/WidgetOptions/Map/MapConfigValidator';
import { ContainerConfigValidator } from '../components/WidgetOptions/Container/ContainerConfigValidator';
import { BarChartConfigValidator } from '../components/WidgetOptions/BarChart/BarChartConfigValidator';
import { WidgetConfiguration } from '../models/WidgetConfiguration';
import { MultiRingConfigValidator } from '../components/WidgetOptions/MultiRing/MultiRingConfigValidator';
import { SortingContainerConfigValidator } from '../components/WidgetOptions/SortingContainer/SortingContainerConfigValidator';

import style from '../scss/dashboard.module.scss';
import { EmptyWidget } from '../components/EmptyWidget';
import { LayoutItem } from '../models/LayoutItem';
import { SnackbarAlert } from '../components/SnackbarAlert';

export interface RegionalViewProps {
    layout?: Layout;
    layoutId?: string;
    layoutWidgets?: WidgetConfiguration[];
    widgetFactory?: WidgetFactory;
    isDesignMode: boolean;
    isAllowedEdit: boolean;
}

const RegionalView = (props: RegionalViewProps): JSX.Element => {

    const { localization } = useContext(AppContext);

    const [storeVisible, setStoreVisible] = useState(app.store.state.widgetsMenuExpanded);
    const [fireResizeEvents, setFireResizeEvents] = useState(true);
    const [widgetStore, setWidgetStore] = useState(app.store.state.widgetTabs);
    const [droppingWidget, setDroppingWidget] = useState<DroppingItem>(null);
    const [configBinder, setConfigBinder] = useState<ConfigBinder>(null);
    const [configWidget, setConfigWidget] = useState<WidgetConfiguration>(null);
    const [configWidgetLayoutId, setConfigWidgetLayoutId] = useState<string>(null);
    const [configValidators, setConfigValidators] = useState<((...args: any[]) => ValidationResult)[]>(null);

    const factory = props.widgetFactory;

    if (IsUndefinedOrNull(factory)) {
        throw new TypeError("WidgetFactory must be initialized");
    }

    const HoldLayoutResizeEvent = (duration: number): void => {
        setFireResizeEvents(false);
        window.setTimeout(() => {
            setFireResizeEvents(true);
        }, duration);
    }

    useEffect(() => {


        PubSub.publish(PubSubTopic.Action, {
            id: Message.LoadLayoutData,
            data: {
                layoutId: props.layout.id
            }
        });

        const OnStoreDataChange = (message, state: MainStoreState): void => {
            setWidgetStore(app.store.state.widgetTabs);

            let nextWidget: DroppingItem = null;

            if (!StringIsNullOrWhiteSpace(state.draggingWidget.id)) {
                nextWidget = {
                    i: state.draggingWidget.id,
                    name: state.draggingWidget.name as WidgetName
                };
            }
            setStoreVisible(state.widgetsMenuExpanded);
            setDroppingWidget(nextWidget);
        };

        const OnAction = (topic, message): void => {
            if (message.id === Message.EditContainerWidget) {
                onEditWidget(message.data.widget, message.data.containerLayoutId, message.data.psapOverride)
            }
        }

        const changesDispatcherRef = PubSub.subscribe(PubSubTopic.Changes, OnStoreDataChange);
        const actionDispatcherRef = PubSub.subscribe(PubSubTopic.Action, OnAction);

        return () => {
            PubSub.unsubscribe(changesDispatcherRef);
            PubSub.unsubscribe(actionDispatcherRef);
        }
    }, [props.layoutId]);

    const onWidgetDrop = (layout: LayoutItem[], widgetShadow: LayoutItem, widgetName: WidgetName): void => {        
        if (!IsUndefinedOrNull(widgetShadow)) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.AddWidget,
                data: {
                    id: widgetShadow.i,
                    name: widgetName,
                    layoutId: props.layout?.id,
                    layoutData: layout
                }
            });
        }
    };

    const onRemoveWidget = (widget: WidgetConfiguration) => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RemoveWidget,
            data: {
                widget: widget,
                layoutId: props.layout?.id
            }
        });
    };

    const onDuplicateWidget = (widget: WidgetConfiguration) => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.DuplicateWidget,
            data: {
                widget: widget,
                layoutId: props.layout?.id
            }
        });
    };

    const onEditWidget = (widget: WidgetConfiguration, widgetLayoutId: string, psapOverride: boolean): void => {
        setConfigBinder(b => {
            const tabs = factory.configurationTabs(widget.name as WidgetName, psapOverride);
            b = new WidgetConfigBinder();
            b.readConfiguration(widget.configuration);
            b.initialize(tabs, psapOverride);
            return b;
        });

        // Keep the layout ID update the correct layout on save
        setConfigWidgetLayoutId(widgetLayoutId);

        switch (widget.name) {
            case WidgetName.Numeric:
            case WidgetName.Circle:
            case WidgetName.SemiCircle:
            case WidgetName.Ring:
                setConfigValidators(validator => {
                    return SingleMetricConfigValidator;
                });
                break;

            case WidgetName.Map:
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.ToggleMapCallPlotting,
                    data: {
                        mapIsInEdit: true
                    }
                });

                setConfigValidators(validator => {
                    return MapConfigValidator;
                });
                break;
            case WidgetName.Bars:
                setConfigValidators(validator => {
                    return BarChartConfigValidator;
                });
                break;
            case WidgetName.MultiRing:
                setConfigValidators(validator => {
                    return MultiRingConfigValidator;
                });
                break;
            case WidgetName.GenericContainer:
                setConfigValidators(validator => {
                    return ContainerConfigValidator;
                });
                break;
            case WidgetName.SortingContainer:
                setConfigValidators(validator => {
                    return SortingContainerConfigValidator;
                });
                break;

            default:
                setConfigValidators(validator => {
                    return [];
                });
                break;
        }


        setConfigWidget(widget);
    };

    const cancelWidgetEdit = (): void => {
        setConfigWidget(null);
        setConfigWidgetLayoutId(null);

        if (configWidget.name === 'mapWidget') {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.ToggleMapCallPlotting,
                data: {
                    mapIsInEdit: false
                }
            });
        }
    };

    const modalStyles: Styles = {
        container: {
            padding: 0,
            width: '400px',
            background: 'none'
        }
    };

    const saveWidgetConfiguration = (isValid: boolean, errors: string[], config: any): void => {

        if (isValid) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.UpdateWidgetConfiguration,
                data: {
                    configurationId: configWidget.id,
                    configuration: config,
                    layoutId: configWidgetLayoutId
                }
            });
            cancelWidgetEdit();
        }
        else {
            console.log(errors);
        }
    };

    const items: GridItemWrapper[] = props.layoutWidgets?.map((w: WidgetConfiguration, index: number) => {

        const widgetObject = factory.render(w);
        const showEditButton = widgetObject.type !== EmptyWidget

        return {
            jsx: (
                <GridItem
                    reportSizes={w.name !== WidgetName.Map}
                    onDelete={() => { onRemoveWidget(w) }}
                    widgetName={w.name}
                    onDuplicate={() => { w.name === WidgetName.GenericContainer && onDuplicateWidget(w) }}
                    onEditSettings={(event: any, sender: any) => onEditWidget(w, props.layoutId, false)}
                    isDesignMode={props.isDesignMode}
                    isVisbileEdit={showEditButton}
                    onLockToggle={() => { }}>
                    {
                        widgetObject
                    }
                </GridItem>
            ),
            key: w.widgetId
        } as GridItemWrapper;
    });

    const showSnackbar = (): boolean => {
        return factory.isExtraPsapIncluded();
    };

    return (

        <div className={style.container}>
            {
                configWidget !== null &&
                <Modal style={modalStyles}>
                    <WidgetOptions
                        activeTab={configWidget.name !== "genericContainerWidget" ? localization["configTab1"] : localization["configTab2"]}
                        title={`Edit ${localization[configWidget.name]} Widget`}
                        configBinder={configBinder}
                        tabs={factory.configurationTabs(configWidget.name as WidgetName, configBinder.isPsapConfigurationDisabled())}
                        onBeforeTabChange={(tab) => { return true; }}
                        onTabChange={tab => { }}
                        onClose={cancelWidgetEdit}
                        validators={configValidators}
                        onSaveChanges={saveWidgetConfiguration}
                    />
                </Modal>
            }

            <div className={style.dashboard}>
                {
                    props.isDesignMode &&
                    <WidgetsSidePanel
                        onBeforeToggle={() => {
                            HoldLayoutResizeEvent(200);
                        }}
                        expanded={storeVisible}
                        widgetCategories={widgetStore}
                        onToggleTab={tab => {
                            if (!storeVisible) {
                                PubSub.publish(PubSubTopic.Action, {
                                    id: Message.ToggleWidgetsMenu
                                });
                            }
                            else {
                                PubSub.publish(PubSubTopic.Action, {
                                    id: Message.ToggleWidgetStoreTab,
                                    data: { tab: tab }
                                })
                            }
                        }}
                    />
                }

                <div className={style.canvas}>
                    {
                        props.layoutWidgets !== null &&
                        <SizeContextProvider>
                            <DashboardGridLayout
                                layoutId={props.layout?.id}
                                reportResizeEvents={fireResizeEvents && droppingWidget === null && true}
                                droppingItem={droppingWidget}
                                onDrop={onWidgetDrop}
                                layout={props.layout?.data}
                                items={items}
                                isDesignMode={props.isDesignMode}
                                isAllowedEdit={props.isAllowedEdit}
                            />
                        </SizeContextProvider>
                    }
                </div >
            </div >
            <SnackbarAlert open={showSnackbar()} />
        </div >
    );
}

export { RegionalView }