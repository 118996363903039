import { app } from '../..';
import { WidgetOptions } from '../../components/WidgetOptions';
import { WidgetConfig, WidgetConfigBinder } from '../../services/WidgetConfigBinder';
import { Tab } from '../../components/WidgetOptions/Tab';
import { Dictionary } from '../../interfaces/Dictionary';
import { NumberWidgetMainTab, NumberWidgetMainTabName } from '../../components/WidgetOptions/NumberWidget/NumberWidgetMainTab';
import { NumberWidgetDataTab, NumberWidgetDataTabName } from '../../components/WidgetOptions/NumberWidget/NumberWidgetDataTab';
import { NumberWidgetDesignTab, NumberWidgetDesignTabName } from '../../components/WidgetOptions/NumberWidget/NumberWidgetDesignTab';
import { NumberWidgetConfigValidator } from '../../components/WidgetOptions/NumberWidget/NumberWidgetConfigValidator';

import style from '../../scss/devview.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../interfaces/AppContext';


export const NumberWidgetConfigDevView = () => {

    // demo configuration (this will arrive from API)


    const widgetConfig: Dictionary<WidgetConfig> = {};

    const mainTabOptions: WidgetConfig = {};
    const dataTabOptions: WidgetConfig = {};
    const designTabOptions: WidgetConfig = {};



    mainTabOptions["useThreshold"] = true;
    mainTabOptions["thresholdValue"] = 0;
    mainTabOptions["title"] = "Enter title here";

    dataTabOptions["selectedPsapIds"] = ["28", "10"];
    dataTabOptions["useThreshold"] = true;
    dataTabOptions["thresholdValue"] = 120;
    dataTabOptions["selectedMetric"] = null;
    dataTabOptions["selectedMetricGroup"] = null;



    designTabOptions["titleFontValue"] = 1;
    designTabOptions["titleFontSize"] = 12;
    designTabOptions["titleFontColor"] = "#ff2800";

    widgetConfig[NumberWidgetMainTabName] = mainTabOptions;
    widgetConfig[NumberWidgetDataTabName] = dataTabOptions;
    widgetConfig[NumberWidgetDesignTabName] = designTabOptions;


    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(widgetConfig);


    // initialize tabs for WidgetOptions component
    // some properties are required and not part of the configuration
    // just pass directly to your tab component
    // ex: psap list or metric list
    // use your custom binder class to specify which properties to autobind

    const { localization } = useContext(AppContext);

    const singleMetricTabs = [

        new Tab({
            name: NumberWidgetDataTabName,
            label: localization["configTab1"],
            component: <NumberWidgetDataTab
                designTabName={NumberWidgetDesignTabName}
                psaps={app.store.state.psaps}
                metrics={app.store.state.metrics}
                sortOrder={app.store.state.sortOrder} />
        }),
        new Tab({
            name: NumberWidgetMainTabName,
            label: localization["configTab2"],
            component: <NumberWidgetMainTab />
        }),

        new Tab({
            name: NumberWidgetDesignTabName,
            label: localization["configTab3"],
            component: <NumberWidgetDesignTab
                dataTabName={NumberWidgetDataTabName} />
        })
    ];

    binder.initialize(singleMetricTabs);


    console.log(NumberWidgetDesignTabName, "name of NumberWidgetDesignTab");




    return (
        <div className={style.container}>
            <h3>Component development</h3>

            <WidgetOptions
                activeTab={localization["configTab1"]}
                title="Edit Single Metric Widget"
                configBinder={binder}
                tabs={singleMetricTabs}
                onBeforeTabChange={(tab) => { return true; }}
                onTabChange={tab => { }}

                validators={NumberWidgetConfigValidator}

                onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                    console.log(config);
                    if (allTabsValid) {
                        console.log("Configuration valid: ", config);
                    }
                    else {
                        console.log("Configuration invalid. Errors: ", errors);
                    }
                }}
            />

        </div>
    );
}
