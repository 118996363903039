
import { WidgetOptions } from '../../components/WidgetOptions';
import { WidgetConfig, WidgetConfigBinder } from '../../services/WidgetConfigBinder';
import { ContainerMainTab, ContainerMainTabName } from '../../components/WidgetOptions/Container/ContainerMainTab';
import { ContainerDesignTab, ContainerDesignTabName } from '../../components/WidgetOptions/Container/ContainerDesignTab';
import { ContainerConfigValidator } from '../../components/WidgetOptions/Container/ContainerConfigValidator';
import { Tab } from '../../components/WidgetOptions/Tab';
import { Dictionary } from '../../interfaces/Dictionary';

import style from '../../scss/devview.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../interfaces/AppContext';

const ContainerConfigDevView = (props) => {

    const widgetConfig: Dictionary<WidgetConfig> = {};
    const mainTabOptions: WidgetConfig = {};
    const designTabOptions: WidgetConfig = {};

    designTabOptions["containerTitleFontValue"] = 1;
    designTabOptions["containerTitleFontSize"] = 12;
    designTabOptions["containerTitleFontColor"] = "#000000";

    designTabOptions["widgetTitleFontValue"] = 1;
    designTabOptions["widgetTitleFontSize"] = 12;
    designTabOptions["widgetTitleFontColor"] = "#000000";
    designTabOptions["widgetThresholdColor"] = "#ff2800";

    widgetConfig[ContainerMainTabName] = mainTabOptions;
    widgetConfig[ContainerDesignTabName] = designTabOptions;

    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(widgetConfig);

    const { localization } = useContext(AppContext);

    const containerTabs = [
        new Tab({
            name: ContainerMainTabName,
            label: localization["configTab2"],
            component: <ContainerMainTab />
        }),
        new Tab({
            name: ContainerDesignTabName,
            label: localization["configTab3"],
            component: <ContainerDesignTab />
        })
    ];

    binder.initialize(containerTabs);

    

    return (
        <div className={style.container}>
            <h3>Component development</h3>

            <WidgetOptions
                activeTab={localization["configTab2"]}
                title="Edit Container Widget"
                configBinder={binder}
                tabs={containerTabs}
                onBeforeTabChange={(tab) => { return true; }}
                onTabChange={tab => { }}

                validators={ContainerConfigValidator}

                onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                    if (allTabsValid) {
                        console.log("Configuration valid: ", config);
                    }
                    else {
                        console.log("Configuration invalid. Errors: ", errors);
                    }
                }}
            />

        </div>
    );
}

export { ContainerConfigDevView }