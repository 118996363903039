import { BarChartDesignTabProps } from './BarChartDesignTab';
import { BarChartMainTabProps } from './BarChartMainTab';
import { BarChartDataTabProps } from './BarChartDataTab';

import { ValidationResult } from "../../WidgetOptions"
import { IsUndefinedNullOrEmpty } from '../../../misc/Helpers';
import { RowDefinition } from '../../../models/Metric';


/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const BarChartConfigValidator = [

    (data: BarChartDataTabProps, main: BarChartMainTabProps, design: BarChartDesignTabProps, tabLabel: string): ValidationResult => {

        const pass = data.selectedMetrics?.length <= 25;

        if (pass) {
            return { isValid: true, message: "" }
        }
        else {
            return { isValid: false, message: `Maximum 25 metrics allowed`, tabName: tabLabel }
        }

    },

    (data: BarChartDataTabProps, main: BarChartMainTabProps, design: BarChartDesignTabProps, tabLabel: string): ValidationResult => {

        if (main?.maxValue <= main?.minValue) {
            return { isValid: false, message: "Max value must be greater than min value", tabName: tabLabel }
        }
        else {
            return { isValid: true, message: "" }
        }
    },
    (data: BarChartDataTabProps, main: BarChartMainTabProps, design: BarChartDesignTabProps, tabLabel: string): ValidationResult => {

        if ((data.useMaxThreshold && data.useMinThreshold) && data?.thresholdValues?.some(i => i?.maxThresholdValue < i?.minThresholdValue)) {
                return { isValid: false, message: "Min threshold must be lower than max value threshold.", tabName: tabLabel }
        }
        else {
            return { isValid: true, message: "" }
        }
    },

    // Metric validator
    (data: BarChartDataTabProps, main: BarChartMainTabProps, design: BarChartDesignTabProps, tabLabel: string): ValidationResult => {
        if (IsUndefinedNullOrEmpty(data.selectedMetrics) || data.selectedMetrics.length === 0)
            return { isValid: false, message: "At least one metric (column) must be selected", tabName: tabLabel };
        return { isValid: true, message: "" }
    },
    // PSAP validator
    (data: BarChartDataTabProps, main: BarChartMainTabProps, design: BarChartDesignTabProps, tabLabel: string): ValidationResult => {

        // Allow not having any selected psap if the selected metric group contains metrics with rowDefinition=None
        let metricGroupWithNoRow = false;
        if (data.metrics.filter(m => m.metricGroup === data.selectedMetricGroup).every(m2 => m2.rowDefinition === RowDefinition.None))
            metricGroupWithNoRow = true;

        if (!data.psapOverride && !metricGroupWithNoRow && (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0))
            return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };
        return { isValid: true, message: "" }
    }
];