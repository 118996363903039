import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarProps {
    open: boolean;
}

export const SnackbarAlert = (props: SnackbarProps) => {
    const [openSnackBar, setOpenSnackBar] = useState(false);


    useEffect(() => {
        setOpenSnackBar(props.open)
    }, [props]);

    const handleClose = (): void => {
        setOpenSnackBar(false);
    };

    const snackBarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            action={snackBarAction}
            open={false}
        >
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Data is not showing for PSAPs for which you do not have access.
            </Alert>
        </Snackbar>
    );
}