import { Styles } from "../interfaces/Dictionary";
import style from '../scss/layout.module.scss';
import classNames from "classnames";

export interface ContainerTitleProps {
    className?: string;
    title?: string;
    style?: Styles;
}

export function ContainerTitle(props: ContainerTitleProps): JSX.Element {
    return (
        <div><p className={classNames(style.containerTitle, props.className)} style={props.style}>
            {props.title}
        </p></div>
    )
}