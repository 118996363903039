import { useEffect, useRef, useState } from "react";
import { Modal } from "../Modal";
import { IsFunction, StringIsNullOrWhiteSpace } from "../../misc/Helpers";
import style from "./style.module.scss";
import classNames from "classnames";

export interface LayoutCreateDialogProps {
    minLayoutTitleChars: number; // 10 - 100
    maxLayoutTitleChars: number; // 0 - 10
    title: string;
    value?: string;
    okButtonText?: string;
    onAction: (inputValue: string) => void;
    onCancel: () => void;

    inputValidator?: (value: string) => string | null;
}

export const LayoutNameHandlingDialog = (props: LayoutCreateDialogProps) => {

    const inputRef = useRef();


    let maxChars = props.maxLayoutTitleChars;

    if (maxChars < 10) {
        maxChars = 10;
    }
    if (maxChars > 100) {
        maxChars = 100;
    }

    let minChars = props.minLayoutTitleChars;
    if (minChars < 0) {
        minChars = 0;
    }
    if (minChars > 10) {
        minChars = 10;
    }

    const [layoutInputText, setLayoutInputText] = useState(StringIsNullOrWhiteSpace(props.value) ? "" : props.value.trim());
    const [errorMessage, setErrorMessage] = useState(null as string);

    const handleOk = (e: React.MouseEvent): void => {
        props.onAction(layoutInputText.trim());
    }

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const val = e.target.value.trimStart();
        setErrorMessage(null);
        setLayoutInputText(e.target.value);

        if (IsFunction(props.inputValidator)) {
            const result = props.inputValidator(e.target.value);
            if (typeof result === "string") {
                setErrorMessage(result);
            }
        }
    };

    const textLengthValidator = (text: string): number => {
        if (StringIsNullOrWhiteSpace(text))
            return 0;

        return text.trim().length;
    };

    useEffect(()=>{
        const elem = inputRef.current as HTMLInputElement;
        if(typeof elem !== "undefined"){
            elem.focus();
        }
    },[]);

    return (

        <Modal
            handleCancel={props.onCancel}
            handleOk={handleOk}
            okDisabled={textLengthValidator(layoutInputText) < minChars || errorMessage !== null}
            headerText={props.title}
            cancelButtonText={"Cancel"}
            okButtonText={StringIsNullOrWhiteSpace(props.okButtonText) ? "Create" : props.okButtonText}>
            <div className={style.container}>
                <input
                    ref={inputRef}
                    maxLength={maxChars}
                    value={layoutInputText}
                    onChange={onValueChange}
                    type="text"
                    className={textLengthValidator(layoutInputText) < minChars || errorMessage !== null ? "form-control warn" : "form-control"}
                    placeholder="type layout name" />
                {
                    errorMessage === null &&
                    <div className={style.requirements}>
                        {
                            textLengthValidator(layoutInputText) < minChars &&
                            <span>{minChars - textLengthValidator(layoutInputText)} more chars required</span>
                        }
                        {
                            textLengthValidator(layoutInputText) >= minChars &&
                            <span>{maxChars - textLengthValidator(layoutInputText)} chars left</span>
                        }
                    </div>
                }
                {
                    !errorMessage !== null &&
                    <div className={classNames(style.requirements, style.warning)}>
                        <span>{errorMessage}</span>
                    </div>
                }
            </div>
        </Modal>
    );
}