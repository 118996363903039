import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { app } from '../..';
import { PubSubTopic } from '../../misc/Constants';
import { Header } from '../partials/Header';

const DevMaster = (props): JSX.Element => {


    useEffect(() => {

        const elem = document.getElementsByTagName("body");

        const BackgroundClickHandler = (event) => {
            PubSub.publish(PubSubTopic.EventsBackgroundClick);
        };

        if (elem.length > 0) {
            elem[0].addEventListener("click", BackgroundClickHandler);
        }
        return () => {
            if(elem.length > 0){
                elem[0].removeEventListener("click", BackgroundClickHandler); 
            }
        }
    }, []);

    return (
        <React.Fragment>
            <Header layoutContext="regional" layouts={ app.store.state.layouts} />
            <Outlet />
        </React.Fragment>
    );
}

export { DevMaster }