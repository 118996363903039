import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from '@fortawesome/free-solid-svg-icons';
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/Messages";
import style from './style.module.scss';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ReactElement } from "react";
import { DashboardNotification } from "../../models/DashboardNotification";
import { app } from "../..";

export const DashboardNotificationAlertAtTheBottom = ({ dashboardNotification }: { dashboardNotification: DashboardNotification }): ReactElement => {

    function onCancel(reload: boolean): void {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.AcknowledgeNotification,
            data: {
                notificationId: dashboardNotification.id
            }
        });

        if (reload) {      
            localStorage.setItem(app.store.state.user.account.id + "_ShowConfirmationWindow", JSON.stringify(dashboardNotification));
            window.location.reload();
        }
    }

    return <div>
        <div className={style.notificationcontainerbottom}>
            <div className={style.close}>
                <span><FontAwesomeIcon onClick={(e) => onCancel(false)} className={style.closeIcon} icon={faX} /></span>
            </div>
            <div className={style.bodyContainer}>
                <div className={style.starContainer}>
                    <StarBorderIcon fontSize="large"></StarBorderIcon>
                </div>
                <div className={style.messagecontainer}>
                    {
                        typeof (dashboardNotification.title) !== 'undefined' && dashboardNotification.title.length > 0 &&
                        <div className={style.title}>
                            {dashboardNotification.title}
                        </div>
                    }
                    <div className={style.message}>
                        {dashboardNotification.message}
                    </div>
                </div>
            </div>
            {
                typeof (dashboardNotification.buttonText) !== 'undefined' && dashboardNotification.buttonText?.length > 0 &&
                <div className={style.buttonContainer}>
                    <button type="button" className={style.primary} onClick={(e) => onCancel(true)}>{dashboardNotification.buttonText}</button>
                </div>
            }
            {
                typeof (dashboardNotification.additionalText) !== 'undefined' && dashboardNotification.additionalText?.length > 0 &&
                <div className={style.bodyContainer}>
                    <div className={style.messagecontainer}>
                        <div className={style.additional}>
                            {dashboardNotification.additionalText}
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
};