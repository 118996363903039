import { SingleMetricDesignTab, SingleMetricDesignTabProps } from "../SingleMetric/SingleMetricDesignTab";

export interface NumberWidgetDesignTabProps extends SingleMetricDesignTabProps { }

export const NumberWidgetDesignTabName = "NumberWidgetDesignTab";

const NumberWidgetDesignTab = (args: NumberWidgetDesignTabProps) => {
    return SingleMetricDesignTab(args);
};

export { NumberWidgetDesignTab }