import classNames from 'classnames';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import style from './style.module.scss';
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsFunction } from "../../misc/Helpers";


export interface SortableItemProps {
    id: string;
    index: number;
    onRemove: (item: string) => void;
}

export const SortableItem = (props: SortableItemProps): ReactElement => {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const dndStyle = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    const onRemove = (item: string): void => {
        if (isDragging) {
            console.log("Click events don't work when dragging.");
            return;
        }

        IsFunction(props.onRemove) && props.onRemove(item)
    }

    return (
        <div ref={setNodeRef} style={dndStyle} {...attributes} {...listeners}>
            <div>
                <div className={classNames(style.itemDragable, props.index % 2 === 0 ? style.itemDragableOdd : style.itemDragableEven)} key={props.id}>
                    {props.id}<FontAwesomeIcon icon="x" title="Remove item from the list" onClick={() => {onRemove(props.id)}} />
                </div>
            </div>
        </div>
    )
}