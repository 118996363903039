import { ReactElement, useContext } from 'react';
import style from './style.module.scss';
import { AppContext } from '../../../interfaces/AppContext';
import { Link } from 'react-router-dom';
import { IsNotUndefinedOrNull } from '../../../misc/Helpers';
import { Logo, LogoStyle } from '../../../components/Logo';
import classNames from 'classnames';
import { Header } from '../Header';
export interface ErrorPageContentProps {
    heading?: string;
    message?: string;
    backButtonText?: string;
    loginInstructions?: string 
}

export const ErrorPageContent = (props: ErrorPageContentProps): ReactElement => {
    let { user, localization } = useContext(AppContext);

    let headingVal = props.heading;
    let messageVal = props.message;
    let backButtonVal = props.backButtonText;
    let loginInstructions = props.loginInstructions;

    const GetValidValue = (currentVal: string, expectedVal: string): string => {
        return IsNotUndefinedOrNull(expectedVal) && expectedVal !== "" ? expectedVal : currentVal;
    }
    
    if (IsNotUndefinedOrNull(localization) && Object.keys(localization).length > 0) {
        
        headingVal = GetValidValue(headingVal, localization["page404heading"]);
        messageVal = GetValidValue(messageVal, localization["page404message"]);
        backButtonVal = GetValidValue(backButtonVal, localization["page404backbutton"]);
        loginInstructions = GetValidValue(loginInstructions, localization["page404logininstruction"]);
    }

    return (
        <div className={style.container}>
            {
                user === null &&
                <header>
                    <div className={classNames(style.logo, style.section)}>
                        <Logo width={115} style={LogoStyle.Light} />
                        <span id="ng911_label">NG9-1-1 Dashboard</span>
                    </div>
                </header>
            }
            {
                user !== null &&
                <Header
                    hideLayoutControls={true}
                    layouts={[]} layoutContext="regional" />
            }
            <div className={style.page}>
                <div className={style.graphics} />
                <div className={style.message}>
                    <h3>{headingVal}</h3>
                    <p>{messageVal}</p>

                    {
                        user !== null &&
                        <div className={style.back}>
                            <Link to="/">{loginInstructions}</Link>
                        </div>
                        
                    }
                    {
                        user === null &&
                        <div className={style.back}>
                            <a href={backButtonVal}>{loginInstructions}</a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}