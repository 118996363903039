import React from 'react'

export enum LogoStyle{
    Light,
    Dark
}
export interface LogoProps{
    width?: number;
    style? : LogoStyle;
}

const Logo = (props : LogoProps) : JSX.Element =>{

    let w = 467;
    let h = 123;
    const ratio = w / h;

    if(typeof props.width === "number" && props.width > 0){
        w = props.width;
        h = w / ratio;
    }

    const darkLogo = (<svg version="1.2" 
        baseProfile="tiny-ps" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 467 123" width={w} height={h}> 
        <g id="Layer">
            <g id="Layer">
                <path id="Layer" 
                    style={{fill: '#2980b9'}} 
                    d="M464.5 18.57L448.1 33.07C442.34 25.06 436.5 21.07 430.5 21.07C427.6 21.07 425.22 21.85 423.36 23.42C421.52 24.96 420.6 26.72 420.6 28.67C420.6 30.64 421.25 32.49 422.58 34.24C424.39 36.54 429.8 41.54 438.88 49.24C447.32 56.33 452.48 60.84 454.28 62.64C458.77 67.18 461.96 71.54 463.83 75.64C465.7 79.81 466.64 84.32 466.64 89.24C466.64 98.79 463.34 106.74 456.74 112.94C450.14 119.16 441.54 122.27 430.94 122.27C422.63 122.27 415.44 120.24 409.24 116.18C403.07 112.09 397.84 105.68 393.44 96.98L412.04 85.68C417.64 95.98 424.04 101.08 431.34 101.08C435.15 101.08 438.35 99.97 440.94 97.75C443.54 95.53 444.81 92.96 444.81 90.07C444.81 87.42 443.84 84.79 441.89 82.14C439.94 79.49 435.64 75.46 428.99 70.04C416.29 59.64 408.09 51.64 404.39 46.04C400.71 40.41 398.84 34.84 398.84 29.24C398.84 21.15 401.92 14.24 408.09 8.44C414.23 2.65 421.79 -0.24 430.89 -0.24C436.71 -0.24 442.19 1.11 447.49 3.79C452.74 6.5 458.39 11.42 464.59 18.49M140.59 -0.31C160.49 -0.31 178.29 9.22 189.39 23.99L174.69 38.69C167.42 27.59 154.89 20.19 140.59 20.19C118.09 20.19 99.89 38.39 99.89 60.89C99.89 83.39 118.09 101.59 140.59 101.59C154.89 101.59 167.39 94.24 174.69 83.09L189.39 97.79C178.19 112.59 160.49 122.09 140.59 122.09C106.79 122.09 79.29 94.69 79.29 60.79C79.29 26.99 106.69 -0.51 140.59 -0.51L140.59 -0.31ZM0.59 2.66L68.89 2.66L68.89 24.36L22.59 24.36L22.59 45.46L64.19 45.46L64.19 66.76L22.59 66.76L22.59 97.36L68.89 97.36L68.89 119.16L0.59 119.16L0.59 2.16L0.59 2.66ZM320.59 2.66L384.99 2.66L389.32 2.66L389.32 24.56L384.99 24.56L363.79 24.56L363.79 119.26L341.29 119.26L341.29 24.56L320.59 24.56L316.26 24.56L316.26 2.66L320.59 2.66Z" />
                <path 
                    id="Layer"
                    style={{fill: '#2980b9'}} 
                    fillRule="evenodd" d="M243.49 7.18C258.79 7.18 267.69 12.18 277.89 22.48L277.89 9.78L299.29 9.78L299.29 109.38L277.89 109.38L277.89 97.38C268.59 106.29 257.29 112.08 244.29 112.08C230.99 112.08 220.05 106.78 210.99 97.18C201.17 86.78 196.89 74.18 196.89 59.88C196.89 45.58 200.7 32.48 210.49 21.88C219.39 12.24 230.29 7.18 243.49 7.18ZM227.49 36.58C221.5 42.98 219.03 50.9 219.03 59.58C219.03 68.38 221.54 76.41 227.61 82.88C233.31 89.01 240.51 92.1 248.91 92.1C257.41 92.1 264.66 89.17 270.51 83.02C276.68 76.52 279.08 68.35 279.08 59.52C279.08 50.72 276.62 42.75 270.5 36.42C264.6 30.31 257.24 27.51 248.8 27.51C240.4 27.51 233.25 30.43 227.5 36.57L227.49 36.58Z" />
            </g>
        </g>
    </svg>);


    const lightLogo = (
        <svg
            xmlnsXlink="http://www.w3.org/1999/02/22-rdf-syntax-ns#"  
            xmlns="http://www.w3.org/2000/svg" 
            xmlSpace="preserve" 
            viewBox="0 0 466.4 122.5" 
            version="1.1" y="0px" x="0px" width={w} height={h}>

            <g transform="translate(-84.7 -813)">
                    <g transform="translate(-17.6 649)">
                                <path style={{fill: '#ffffff', stroke : '#ffffff', strokeMiterlimit: 10}} d="m567 183l-16.4 14.5c-5.8-8-11.6-12-17.6-12-2.9 0-5.3 0.8-7.1 2.4-1.8 1.5-2.8 3.3-2.8 5.2 0 2 0.7 3.8 2 5.6 1.8 2.3 7.2 7.3 16.3 15 8.4 7.1 13.6 11.6 15.4 13.4 4.5 4.5 7.7 8.9 9.6 13 1.9 4.2 2.8 8.7 2.8 13.6 0 9.6-3.3 17.5-9.9 23.7s-15.2 9.3-25.8 9.3c-8.3 0-15.5-2-21.7-6.1s-11.4-10.5-15.8-19.2l18.6-11.3c5.6 10.3 12 15.4 19.3 15.4 3.8 0 7-1.1 9.6-3.3s3.9-4.8 3.9-7.7c0-2.7-1-5.3-2.9-7.9-1.9-2.7-6.2-6.7-12.9-12.1-12.7-10.4-20.9-18.4-24.6-24s-5.5-11.2-5.5-16.8c0-8.1 3.1-15 9.3-20.8 6.1-5.8 13.7-8.7 22.8-8.7 5.8 0 11.3 1.4 16.6 4 6 3 11 8 18 15m-324-19c19.9 0 37.7 9.5 48.8 24.3l-14.7 14.7c-7.3-11.1-19.8-18.5-34.1-18.5-22.5 0-40.7 18.2-40.7 40.7s18.2 40.7 40.7 40.7c14.3 0 26.8-7.3 34.1-18.5l14.7 14.7c-11.2 14.8-28.9 24.3-48.8 24.3-33.8 0-61.3-27.4-61.3-61.3 0-35 27-62 61-62zm-141 3h68.3v21.7h-46.3v21.1h41.6v21h-41.6v30.6h46.3v21.8h-68.3v-117zm320 0h68.7v21.9h-25.5v94.7h-22.5v-94.7h-24.3v-21.9h4z" />
                                <path style={{fill: '#ffffff', stroke : '#ffffff', strokeMiterlimit: 10}} d="m330 200c5.8-6.1 12.9-9.1 21.3-9.1s15.8 2.8 21.7 8.9c6.1 6.3 8.6 14.3 8.6 23.1s-2.4 17-8.6 23.5c-5.8 6.1-13.1 9.1-21.6 9.1-8.4 0-15.6-3.1-21.3-9.2-6.1-6.5-8.6-14.5-8.6-23.3 0-8 3-16 9-23m-17-14c-9.8 10.6-13.6 23.7-13.6 38s4.3 26.9 14.1 37.3c9.1 9.6 20 14.9 33.3 14.9 13 0 24.3-5.8 33.6-14.7v12h21.4v-99.6h-21.4v12.7c-10.2-10.3-19.1-15.3-34.4-15.3-13 0-24 5-33 15z"/>
                </g>
            </g>
        </svg>
    );

    switch(props.style){
        case LogoStyle.Light:
            return lightLogo;
        case LogoStyle.Dark:
            return darkLogo;
        default:
            return darkLogo;
    }
}
 
export { Logo }