import React, { useEffect, useState } from "react";
import { StreamingData } from "../interfaces/StreamingData";
import { PubSubTopic } from "../misc/Constants";
import { bindFunctionEnumToFct, BindFunctions, GoogleCoordinates } from "../misc/BindFunctions";
import { IsFunction, DefaultIfUndefinedOrNull, IsUndefinedOrNull, IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, StringIsNullOrWhiteSpace } from "../misc/Helpers"
import { Metric, RowDefinition } from "../models/Metric";
import { LocationData } from "@ecats911/edash-widgets-map";

export interface MapDataAdapterProps {
    // Data binding and calculation
    metricDefinitions?: Metric[]
    psapNenaIds?: string[];	// List of PSAPs from which data should be considered

    // Miscellaneous
    clearTimeoutMS?: number;
    children?: any;
}

const MapDataAdapter = (props: MapDataAdapterProps): JSX.Element => {
    // Set initialData and intialValue. 
    let initialData = [] as number[];
    let initialValue = [] as number[];
    if (IsUndefinedOrNull(props.metricDefinitions)) {
        initialData = [0];
        initialValue = [0];
    }
    else if (props.metricDefinitions.length > 1 || props.psapNenaIds.length === 0) {
        initialData = props.metricDefinitions.map(def => DefaultIfUndefinedOrNull(def.defaultValue, 0));
    }
    else {
        initialData = props.psapNenaIds.map(() => DefaultIfUndefinedOrNull(props.metricDefinitions?.[0]?.defaultValue, 0));
    }

    // State for map widget coordinates. mapWidgetData is the list of coordinates per psap in a map of array, mapWidgetValue is the array of values sent to the map widget
    const [mapWidgetValue, setMapWidgetValue] = useState([] as LocationData[]);
    const [mapWidgetData, setMapWidgetData] = useState(new Map<string, Array<LocationData>>());
    const [mapWidgetNumericValue, setMapWidgetNumericValue] = useState({} as { [key: string]: number });

    let clearTimeoutMS = IsNotUndefinedOrNull(props.clearTimeoutMS) ? props.clearTimeoutMS : 3000;

    useEffect(() => {
        // We need one timer per widget value, and this happen either because of multiple-metrics or multiple PSAPs.
        let timeoutRefs: Map<string, number> = new Map<string, number>()

        // Callback for clearing a metric after timeout
        function ClearMetric(psapId, metricId, timerContext) {
            timeoutRefs.set(psapId + "_" + metricId, null);

            clearTimeout(timerContext.timerId);

            mapWidgetData.set(psapId, []);
            setMapWidgetData(mapWidgetData);

            mapWidgetNumericValue[psapId + "_" + metricId] = 0;
            setMapWidgetNumericValue(mapWidgetNumericValue);

            updateMapWidgetData();
        }

        const KickTimer = (psapId, metricId) => {
            let timerId = timeoutRefs.get(psapId + "_" + metricId);
            if (IsNotUndefinedOrNull(timerId)) {
                clearTimeout(timerId);
                timeoutRefs.set(psapId + "_" + metricId, null);
            }
            let timerContext = { timerId: null };
            let newTimerId = window.setTimeout(() => ClearMetric(psapId, metricId, timerContext), clearTimeoutMS);
            timerContext.timerId = newTimerId;
            timeoutRefs.set(psapId + "_" + metricId, newTimerId);
        };

        // Start a timer on each metric/psap pair on re-render
        props?.psapNenaIds?.forEach((psap) => {
            props?.metricDefinitions?.forEach((metric) => {
                if (metric.rowDefinition !== RowDefinition.None) {
                    KickTimer(psap, metric.id)
                }
            })
        });

        const onDataMapWidget = (metric, stream: StreamingData): void => {
            // Create a list of google coordinates from list of calls
            let transformFct = IsFunction(metric.transformFct) ? metric.transformFct : GoogleCoordinates;
            let psapCoords: LocationData[] = transformFct({ data: stream.data });

            // Compare coordinates for this PSAP with previous to avoid setting state if nothing has changed
            let changeDetected = false;
            let oldPsapCoords = mapWidgetData.get(stream.target)
            if (typeof (oldPsapCoords) === "undefined" || psapCoords.length !== oldPsapCoords.length) {
                changeDetected = true;
            }
            else {
                psapCoords.every((coord, index) => {
                    if (coord.locationName !== oldPsapCoords[index].locationName || !coord.location.equals(oldPsapCoords[index].location)) {
                        changeDetected = true;
                        return false;
                    }
                    return true;
                })
            }

            // Update state with new coordinates for the psap
            if (changeDetected) {
                const updatedData = new Map(mapWidgetData);
                updatedData.set(stream.target, psapCoords);
                setMapWidgetData(updatedData);

                updateMapWidgetData();
            }
        };

        const onNumericDataMapWidget = (metric: Metric, stream: StreamingData, bindFunction?: BindFunctions): void => {
            // Determine the index of the value to update. This depends whether this widget is a multi-metric or a multi-psap
            let metricIndex = 0;
            let updateIndex = !StringIsNullOrWhiteSpace(stream.target) ? props.psapNenaIds.indexOf(stream.target) : metric.rowDefinition !== RowDefinition.None ? -1 : 0;
            if (props.metricDefinitions.length > 1) {
                updateIndex = props.metricDefinitions.indexOf(metric);
                metricIndex = updateIndex;
            }

            // Apply transformation function to stream to obtain metric value
            let transformFct = bindFunctionEnumToFct(typeof bindFunction === 'undefined' ? props.metricDefinitions[metricIndex].bindFunction : bindFunction);
            let val = transformFct({ data: stream.data, bind: (DefaultIfUndefinedOrNull(props.metricDefinitions[metricIndex].bindProperty, "")) });

            if (mapWidgetNumericValue[stream.target + "_" + metric.id] !== val) {
                const updatedArray = { ...mapWidgetNumericValue, [stream.target + "_" + metric.id]: val }; // create a new object with the updated key-value pair
                setMapWidgetNumericValue(updatedArray); // set the state to the updated object
            }
        }

        const updateMapWidgetData = (): void => {
            let newCoords = [] as LocationData[];
            mapWidgetData.forEach(psapCallList => psapCallList.forEach(coord => newCoords.push(coord)))
            setMapWidgetValue([...newCoords]);
        };

        const OnDataUpdate = (msg: any, stream: StreamingData): void => {
            // If metric definitions or psaps are not set, do not attempt to update widget data.
            if (!IsUndefinedNullOrEmpty(props.metricDefinitions) && !IsUndefinedOrNull(props.psapNenaIds)) {

                props.metricDefinitions.forEach(metric => {
                    // Match stream name with metric definition and PSAP.
                    if (metric.streamName === stream.report) {
                        let psapIndex = !StringIsNullOrWhiteSpace(stream.target) ? props.psapNenaIds.indexOf(stream.target) : metric.rowDefinition !== RowDefinition.None ? -1 : 0
                        if (IsNotUndefinedOrNull(metric) && psapIndex > -1) {

                            //KickTimer(metric, psapIndex);
                            KickTimer(stream.target, metric.id);

                            // Update widget
                            if (metric.id === "MapActiveCalls") {
                                onDataMapWidget(metric, stream);

                                onNumericDataMapWidget(metric, stream, BindFunctions.Aggregate);
                            }
                            else {
                                onNumericDataMapWidget(metric, stream);
                            }
                        }
                    }
                })
            }
        };

        const dispatcherRef = PubSub.subscribe(PubSubTopic.Stream, OnDataUpdate);

        return () => {

            timeoutRefs?.forEach(timer => IsNotUndefinedOrNull(timer) && clearTimeout(timer))
            PubSub.unsubscribe(dispatcherRef);
        };

    }, [props.metricDefinitions, props.psapNenaIds, props.clearTimeoutMS, mapWidgetNumericValue, mapWidgetData]);

    if (Array.isArray(props.children)) {
        if (props.children.length > 1) {
            console.warn("Only 1 child component supported per MapDataAdapter", props.children);
        }
    }

    return (
        <React.Fragment>
            {
                React.Children.map(props.children, child => {
                    return React.cloneElement(child, { numericValues: mapWidgetNumericValue, values: mapWidgetValue })
                })
            }
        </React.Fragment>
    )
}

export { MapDataAdapter }