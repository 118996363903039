import React, { useState } from "react";
import { TabComponent } from "../Tab";

import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace, SetToZeroIfNegativeValue } from "../../../misc/Helpers";

import { SingleMetricWidgetMinMaxValues } from "../../../misc/Constants";
import { LabelControlWithToolTip } from "../../Labels/LabelControlWithToolTip";

export interface SingleMetricMainTabProps extends TabComponent {
    title?: string;
    minValue?: number;
    maxValue?: number;
}

export const SingleMetricMainTabName = "SingleMetricMainTab";

export const SingleMetricMainTab = (props: SingleMetricMainTabProps) => {


    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;

    const [title, setTitle] = useState(initialTitle);

    const [minValue, setMinValue] = useState(isNaN(props.minValue) ? SingleMetricWidgetMinMaxValues.min : props.minValue);
    const [maxValue, setMaxValue] = useState(isNaN(props.maxValue) ? SingleMetricWidgetMinMaxValues.max : props.maxValue);

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };

    const onMinValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setMinValue(value);

        typeof props.onChange === "function" && props.onChange("minValue", value);
    };

    const onMaxValueChange = (value: number): void => {
        value =SetToZeroIfNegativeValue(value);
        setMaxValue(value);

        typeof props.onChange === "function" && props.onChange("maxValue", value);
    };

    return (
        <React.Fragment>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>

                <input
                    type="text"
                    className="form-control"
                    placeholder="title"
                    maxLength={100}
                    onChange={e => TitleChange(e.target.value)}
                    value={title}
                />
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Scale Minimum"} placement="right" title={"Set the minimum value on the scale"} />
                </div>

                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={ maxValue > minValue ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMinValueChange(+e.target.value)}
                            type="number"
                            value={minValue} />                       
                    </div>
                </div>
                <div className={shared.spacer} />

                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Scale Maximum"} placement="right" title={"Set the maximum value on the scale"} />
                </div>

                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={ maxValue > minValue  ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMaxValueChange(+e.target.value)}
                            type="number"
                            value={maxValue} />                        
                    </div>
                </div>
            </div>           

        </React.Fragment >
    );
}