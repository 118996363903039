import { IsUndefinedNullOrEmpty } from '../../../misc/Helpers';
import { MapMainTabProps } from "./MapMainTab"
import { MapDataTabProps } from "./MapDataTab"
import { MapDesignTabProps } from "./MapDesignTab"
import { ValidationResult } from "../../WidgetOptions"

/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const MapConfigValidator = [

    // Data tab validator
    (data: MapDataTabProps, main: MapMainTabProps, design: MapDesignTabProps, tabLabel: string): ValidationResult => {
        if (IsUndefinedNullOrEmpty(data.selectedMetrics) || data.selectedMetrics.length === 0)
            return { isValid: false, message: "At least one metric (column) must be selected", tabName: tabLabel };

        return { isValid: true, message: "" }
    },
    (data: MapDataTabProps, main: MapMainTabProps, design: MapDesignTabProps, tabLabel: string): ValidationResult => {

        if (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0)
            return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };

        return { isValid: true, message: "" }
    }
]
