import { HandleTypeAsync, Handler, MessageArg, HandleType } from "../interfaces/AppCore";
import { MainStoreState } from "../interfaces/MainStoreState";
import { Message } from "../misc/Messages";
import { GridLayout, Layout, LayoutContext, LayoutDTO } from "../models/DashboardLayout";
import axios from 'axios';
import { IsLayoutDataValid, IsNotUndefinedOrNull, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { app } from "..";
import { DefaultWidgetDimensions, WidgetName } from "../misc/Constants";
import { WidgetManifest } from "../interfaces/WidgetManifest";
import { StatusCodes } from "http-status-codes";
import { Dictionary } from "../interfaces/Dictionary";
import { WidgetConfiguration } from "../models/WidgetConfiguration";

export class LayoutHandler implements Handler {

    constructor() {
        this.CreateLayout.message = Message.CreateLayout;
        this.Update.message = Message.UpdateLayout;
        this.RenameLayout.message = Message.RenameLayout;
        this.DuplicateLayout.message = Message.DuplicateLayout;
        this.DeleteLayout.message = Message.DeleteLayout;


        this.LayoutDataChanged.message = Message.LayoutDataChanged;
        this.RefreshLayoutState.message = Message.RefreshLayoutState;
        this.StartDragWidget.message = Message.StartDragWidget;
        this.EndDragWidget.message = Message.EndDragWidget;
        this.LoadLayoutData.message = Message.LoadLayoutData;
        this.SelectLayout.message = Message.SelectLayout;

        this.AddWidget.message = Message.AddWidget;
        this.RemoveWidget.message = Message.RemoveWidget;

        this.UpdateWidgetConfiguration.message = Message.UpdateWidgetConfiguration;
        this.UpdateMasterContainerHeight.message = Message.UpdateMasterContainerHeight;

        this.DuplicateWidget.message = Message.DuplicateWidget;

    }

    CreateLayout = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        if (StringIsNullOrWhiteSpace(arg.data.layoutName)) {
            return Promise.reject("layout name is required");
        }

        const activate = arg.data.activate === true;

        const newLayout = await this.doCreateLayout(arg.data.layoutName, arg.data.context, arg.data.parentLayoutId);

        if (newLayout !== null) {
            
            state.layouts.push(newLayout);

            if (activate) {
                const nextArg: MessageArg = {
                    id: 0,
                    data: {
                        layoutId: newLayout.id
                    }
                }
                await this.SelectLayout(nextArg, state);
            }

        }

        return Promise.resolve(true);

    });


    private doCreateLayout = async (name: string, context: LayoutContext, parentLayoutId: string | undefined): Promise<Layout | null> => {

        const dto = {
            name: name,
            context: context,
            parentLayoutId: undefined
        };

        if (!StringIsNullOrWhiteSpace(parentLayoutId)) {
            dto.parentLayoutId = parentLayoutId;
        }
        else {
            delete dto.parentLayoutId;
        }

        const result = await axios.post(app.apiBaseUrl + '/api/layout/create', dto);

        if (result.status === StatusCodes.NO_CONTENT)
            return null;

        return Promise.resolve(result.data);
    }

    private getLayouts = async (): Promise<Layout[]> => {
        const result = await axios.get(`${app.apiBaseUrl}/api/layout/all`);
        return result.data as Layout[];
    };

    RenameLayout = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const dto = {
            id: arg.data.layoutId,
            name: arg.data.layoutName
        };

        if (StringIsNullOrWhiteSpace(dto.id)) {
            return Promise.reject("layout id is required");
        };

        if (StringIsNullOrWhiteSpace(dto.name)) {
            return Promise.reject("layout name is required");
        };

        const request = await axios.post(app.apiBaseUrl + '/api/layout/rename', dto);

        state.layouts = state.layouts.map(l => {
            if (l.id === dto.id) {
                l.name = dto.name;
            }
            return l;
        })

        return true;
    });

    DuplicateLayout = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        if (StringIsNullOrWhiteSpace(arg.data.layoutId)) {
            return Promise.reject("layout id is required");
        };

        if (StringIsNullOrWhiteSpace(arg.data.layoutName)) {
            return Promise.reject("layout name is required");
        };

        const result = await axios.post(app.apiBaseUrl + '/api/layout/clone', {
            id: arg.data.layoutId,
            name: arg.data.layoutName
        });

        if (result.status === StatusCodes.OK) {

            // reload layouts
            const layout = result.data as Layout;

            state.layouts = await this.getLayouts();

            if (arg.data.select === true) {

                await this.SelectLayout({
                    id: Message.SelectLayout,
                    data: {
                        layoutId: layout.id
                    }
                }, state);
            }
        }
        return true;
    });

    OnUpdateLayout = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        let layoutId = arg.data.layoutId;
        let breakPoint = arg.data.bp;
        let data: GridLayout[] = arg.data.layout;

        if (typeof data === "undefined") {
            return Promise.reject("Layout breakpoint is required");
        }

        if (StringIsNullOrWhiteSpace(layoutId)) {
            return Promise.reject("layout id is required");
        }

        if (StringIsNullOrWhiteSpace(breakPoint)) {
            return Promise.reject("break point is required");
        }

        return new Promise((resolve, reject) => {

            const dto = {
                layoutId: layoutId,
                breakPoint: breakPoint ?? 'lg',
                data: data
            };

            const request = axios.post(`${app.apiBaseUrl}/api/layout/update`, dto);

            request.then(result => {
                resolve(true);
            });

        });

    });

    DeleteLayout = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const id = arg.data.layoutId;

        if (StringIsNullOrWhiteSpace(id)) {
            return Promise.reject("layout id is required");
        };


        return new Promise<boolean>((resolve, reject) => {

            const request = axios.delete(app.apiBaseUrl + '/api/layout/delete/' + id);

            request.then(result => {

                // Remove layout from state if present
                let index = state.layouts.findIndex(i => i.id === id);
                if (index !== -1)
                    state.layouts.splice(index, 1)

                resolve(true);
            });
        });
    });

    private getWidgetsByLayoutIds = async (ids: string[]): Promise<Dictionary<WidgetManifest[]>> => {

        if (IsUndefinedOrNull(ids))
            return Promise.reject("ids required");

        const result = await axios.post(`${app.apiBaseUrl}/api/widgets/configuration/layouts`, ids);

        let data = result.data as Dictionary<WidgetManifest[]>;

        return Promise.resolve(data);
    };

    LoadLayoutData = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const id = arg.data.layoutId;

        const layoutDataRequest = await axios.get(`${app.apiBaseUrl}/api/layout/${id}`);

        if (layoutDataRequest.status === StatusCodes.NO_CONTENT) {
            return Promise.resolve(true);
        }

        const layouts = layoutDataRequest.data as LayoutDTO[];

        layouts.forEach(l => {
            let current = state.layouts.find(g => g.id === l.id);
            if (typeof current !== "undefined") {
                current.data = l.breakPoints?.find(x => x.name === "lg")?.data ?? null;

                if (current.data) {
                    current.data.forEach(d => {
                        if (d.w < DefaultWidgetDimensions.minW) {
                            d.w = DefaultWidgetDimensions.minW;
                        }

                        if (d.h < DefaultWidgetDimensions.minH) {
                            d.h = DefaultWidgetDimensions.minH;
                        }
                    });
                }
            }        
        });

        const layoutIds = layouts.map(p => p.id);

        const layoutWidgetsConfigResult = await axios.post(`${app.apiBaseUrl}/api/widgets/configuration/layouts`, {
            layoutIds: layoutIds
        });

        state.layoutsWidgets = layoutWidgetsConfigResult.data;

        return Promise.resolve(true);
    });

    SelectLayout = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        state.psapFilter = "";
        
        if (arg.data.layoutId === state.user.account.settings.activeLayoutId)
            return Promise.resolve(true);

        await axios.post(`${app.apiBaseUrl}/api/user/settings`, {
            activeLayoutId: arg.data.layoutId
        });

        state.layoutsWidgets[arg.data.layoutId] = null;
        state.user.account.settings.activeLayoutId = arg.data.layoutId;

        return Promise.resolve(true);
    });

    updateTimeoutRef = 0;

    Update = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {
        clearTimeout(this.updateTimeoutRef);

        const commitLayoutChanges = IsLayoutDataValid(arg.data.layout);

        if (!commitLayoutChanges) {
            return Promise.resolve(true);
        }

        for (let i = 0; i < state.layouts.length; i++) {
            let old = state.layouts[i];
            if (old.id === arg.data.layoutId) {
                old.data = arg.data.layout;
                break;
            }
        }

        if (IsNotUndefinedOrNull(arg.data?.immediateUpdate) && arg.data?.immediateUpdate === true) {
            return this.OnUpdateLayout(arg, state);
        }
        else {
            this.updateTimeoutRef = window.setTimeout(() => {
                return this.OnUpdateLayout(arg, state);
            }, 1000);
        }

        return Promise.resolve(true);
    });

    StartDragWidget = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        state.draggingWidget = {
            id: arg.data.id as string,
            name: arg.data.name as WidgetName
        };

        return true;
    });

    EndDragWidget = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        state.draggingWidget = { id: "", name: WidgetName.Empty };
        return true;
    });


    LayoutDataChanged = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        if (StringIsNullOrWhiteSpace(arg.data.layoutId)) {
            console.warn("layout id not provided.");
            return false;
        }

        const filteredLayout = state.layouts.find(p => p.id === arg.data.layoutId);

        if (IsUndefinedOrNull(filteredLayout)) {
            console.warn("active layout not found. id: ", arg.data.layoutId)
            return false;
        }

        filteredLayout.data = arg.data.layoutData;

        // async call
        this.OnUpdateLayout({
            id: 0,
            data: {
                layoutId: arg.data.layoutId,
                bp: "lg",
                layout: arg.data.layoutData
            }
        }, state);

        return true;
    });

    RefreshLayoutState = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        if (StringIsNullOrWhiteSpace(arg.data.layoutId)) {
            console.warn("layout id not provided.");
            return false;
        }

        const filteredLayout = state.layouts.find(p => p.id === arg.data.layoutId);

        if (IsUndefinedOrNull(filteredLayout)) {
            console.warn("active layout not found. id: ", arg.data.layoutId)
            return false;
        }
        filteredLayout.data = arg.data.layoutData;

        return true;
    });

    AddWidget = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const newWidgetId = arg.data.id as string;
        const newWidgetName = arg.data.name as WidgetName;
        const layoutId = arg.data.layoutId;


        if (StringIsNullOrWhiteSpace(newWidgetId))
            return Promise.reject("id required");

        if (StringIsNullOrWhiteSpace(layoutId))
            return Promise.reject("layoutId required");

        if (StringIsNullOrWhiteSpace(newWidgetName))
            return Promise.reject("name required");

        const widget = state.widgets.find(p => p.name === newWidgetName);

        if (IsUndefinedOrNull(widget))
            return Promise.reject(`widget ${newWidgetName} not found`);


        const widgetDTO: WidgetConfiguration = {
            id: "",
            layoutId: layoutId,
            widgetId: newWidgetId,
            name: widget.name,
            containerLayoutId: "",
            configuration: widget.configuration
        };

        if (widget.name === WidgetName.GenericContainer || widget.name === WidgetName.SortingContainer) {

            // If a new container widget is being added, we must create a new layout.            
            let newLayout = await this.doCreateLayout("default", "container", arg.data.layoutId);

            if (newLayout === null) {
                throw new TypeError("failed to create layout");
            }

            state.layouts.push(newLayout);
            widgetDTO.containerLayoutId = newLayout?.id;

            // We search for the widget in all layouts and not only in main layout to eventually support container within a container
            for (let key in state.layoutsWidgets) {

                let value = state.layoutsWidgets[key] as WidgetConfiguration[];

                value = value.map(manifest => {
                    if (manifest.id === widget.id) {
                        manifest.containerLayoutId = newLayout?.id;
                    }
                    return manifest;
                });
            }
        }

        const response = await axios.post(`${app.apiBaseUrl}/api/widgets/configuration`, widgetDTO);

        widgetDTO.id = response.data.id;

        // Add the widget in layoutWidgets
        if (IsUndefinedOrNull(state.layoutsWidgets[layoutId])) {
            state.layoutsWidgets[layoutId] = [];
        }

        state.layoutsWidgets[layoutId].push(widgetDTO);

        const layoutChangeRequest = {
            id: 0,
            data: {
                layoutId: layoutId,
                layoutData: arg.data.layoutData
            }
        }

        const result = this.LayoutDataChanged(layoutChangeRequest, state);

        return Promise.resolve(result);
    });

    RemoveWidget = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const w = arg.data.widget as WidgetManifest;
        const layoutId = arg.data.layoutId as string;

        // remove widget from layout active widgets
        state.layoutsWidgets[layoutId] = state.layoutsWidgets[layoutId].filter(p => p.id !== w.id);

        // remove layout element
        state.layouts = state.layouts.map(l => {
            if (l.id === layoutId) {
                if (Array.isArray(l.data)) {
                    let d = l.data as GridLayout[];
                    d = d.filter(x => x.i !== w.id);
                }
            }

            return l;
        });

        // send async delete config request
        axios.delete(`${app.apiBaseUrl}/api/widgets/configuration/${w.id}`);

        if (arg.data.widget.name === WidgetName.GenericContainer || arg.data.widget.name === WidgetName.SortingContainer) {
            return this.RemoveContainerWidget(arg, state);
        }

        return Promise.resolve(true);
    });

    RemoveContainerWidget = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const containerLayoutId = arg.data.widget.containerLayoutId;

        if (StringIsNullOrWhiteSpace(containerLayoutId))
            return Promise.reject("containerLayoutId is empty");

        // Delete widgets within the container
        state.layoutsWidgets?.[containerLayoutId]?.forEach(w => {
            axios.delete(`${app.apiBaseUrl}/api/widgets/configuration/${w.id}`);
        })

        // Clear layout widgets
        state.layoutsWidgets[containerLayoutId] = [];

        //Clear data for a parent layout
        const widgetContainerLayoutData = state.layouts.find(ly => ly.id === arg.data.layoutId);
        widgetContainerLayoutData.data.splice(widgetContainerLayoutData.data.findIndex(item => item.i === arg.data.widget.widgetId), 1);

        // Delete the container layout from database.
        let msg: MessageArg = {
            id: Message.DeleteLayout,
            data: {
                layoutId: containerLayoutId,
            }
        }

        return this.DeleteLayout(msg, state);
    });

    UpdateWidgetConfiguration = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        if (StringIsNullOrWhiteSpace(arg.data.configurationId)) {
            return Promise.reject("configurationId required");
        }

        return new Promise((resolve, reject) => {

            const request = axios.post(`${app.apiBaseUrl}/api/widgets/configuration/update`, {
                id: arg.data.configurationId,
                configuration: arg.data.configuration
            });

            request.then(result => {
                if (result.status === StatusCodes.NOT_FOUND) {
                    return reject(`widget configuration by id: ${arg.data.configurationId} not found`)
                }

                // Update the layout
                if (!IsUndefinedOrNull(arg.data.layoutId)) {
                    let layout = state.layoutsWidgets[arg.data.layoutId];
                    if (!IsUndefinedOrNull(layout)) {
                        state.layoutsWidgets[arg.data.layoutId] = layout.map(w => {
                            if (w.id === arg.data.configurationId) {
                                w.configuration = arg.data.configuration;
                            }
                            return w;
                        })
                    }
                }

                resolve(true);
            });
        });
    });

    // Update the masterContainerHeight param of a sorting container. 
    UpdateMasterContainerHeight = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        if (StringIsNullOrWhiteSpace(arg.data.configurationId)) {
            return Promise.reject("configurationId required");
        }

        if (StringIsNullOrWhiteSpace(arg.data.layoutId)) {
            return Promise.reject("layoutId required");
        }

        // Get sorting container config, update size
        const widget = state.layoutsWidgets?.[arg.data.layoutId].find(p => p.id === arg.data.configurationId);
        let config = widget?.configuration
        if (IsUndefinedOrNull(config)) {
            return Promise.reject(`widget configuration by id: ${arg.data.configurationId} not found`)
        }
        config.masterContainerHeight = arg.data.masterContainerHeight;

        // Call UpdateWidgetConfiguration with updated widget config
        let newArg: MessageArg = {
            id: Message.UpdateWidgetConfiguration,
            data: {
                configurationId: arg.data.configurationId,
                configuration: config,
                layoutId: arg.data.layoutId
            }
        }
        return this.UpdateWidgetConfiguration(newArg, state)
    });

    DuplicateWidget = <HandleTypeAsync>(async (arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        const result = await axios.post(`${app.apiBaseUrl}/api/layout/duplicatewidget/`, {
            widgetId: arg.data.widget.widgetId
        });

        if (result.status === StatusCodes.OK) {
            const containerLayout = result.data;

            if (!IsUndefinedOrNull(containerLayout)) {
                state.layouts.push(containerLayout);
            }

            await this.LoadLayoutData({
                id: 0, data: { layoutId: arg.data.layoutId }
            }, state);


            return true;
        }
        else {
            console.warn(`failed to duplicate widget id: ${arg.data.widget.widgetId}`);
            return false;
        }

    });
}