import { IsUndefinedOrNull } from "../misc/Helpers";
import { GetPsapShape } from "../misc/StartupRequests";
import { PsapShape } from "../models/PsapShape";
import { IndexedDbService } from "./IndexedDbService";

export interface PSAPShapeServiceArgs {
    userPsaps: string[];
    apiBaseUrl: string;
}

export class PSAPShapeService extends IndexedDbService {
    psapTableName: string;
    apiBaseUrl: string;
    psaps: string[] = [];


    constructor(args: PSAPShapeServiceArgs) {
        const tableName = "psaps";
        super(tableName);
        this.psapTableName = tableName
        this.apiBaseUrl = args.apiBaseUrl;
        this.psaps = args.userPsaps;
    }

    onError: (msg: string) => void;

    onPsapShapeDataReady: (data: any, isRefreshed: boolean) => void;

    onReady = async (): Promise<void> => {
        const p = this.psaps;

        for (let i = 0; i < p.length; i++) {
            const psapId = p[i];
            let refreshed = false;
            let shapesReceived = false;
            try {
                
                let shape = await this.fetchShapeFromDb(psapId);
                
                if (shape === null) {

                    try {
                        let shapeFromSource = await GetPsapShape(this.apiBaseUrl, psapId);
                        shape = shapeFromSource as PsapShape;
                        shapesReceived = true;
                    }
                    catch (error) {
                        console.error("failed to fetch psap share from API");
                    }

                    if(!shapesReceived){
                        return;
                    }

                    try {
                        await this.putShapeIntoDb(shape);
                        refreshed = true;
                    }
                    catch (error) {
                        console.error(error, "failed to insert shape into db");
                    }
                }

                if (shape !== null && typeof this.onPsapShapeDataReady === "function") {
                    this.onPsapShapeDataReady(shape, refreshed);
                }
            }
            catch (dbRejectError) {
                console.warn(dbRejectError, "rejected at fetchShapeFromDb");
                // fallback
                let shapeFromSource = await GetPsapShape(this.apiBaseUrl, psapId);
                this.onPsapShapeDataReady(shapeFromSource, false);
            }
        }

        return Promise.resolve();
    }

    private fetchShapeFromDb = async (psapId: string): Promise<PsapShape | null> => {

        return new Promise<PsapShape | null>((resolve, reject) => {

            const tx = this.db.transaction(this.psapTableName);
            const store = tx.objectStore(this.psapTableName);
            const request = store.get(psapId);

            request.onsuccess = () => {
                if (!IsUndefinedOrNull(request.result)) {
                    resolve(request.result as PsapShape);
                }
                else {
                    resolve(null);
                }
            };
            request.onerror = () => reject(request.error);
        });
    }

    private putShapeIntoDb = async (shape: PsapShape): Promise<void> => {
        return new Promise((resolve, reject) => {
            const tx = this.db.transaction([this.psapTableName], "readwrite");
            const store = tx.objectStore(this.psapTableName);
            const request = store.put(shape);
            request.onsuccess = () => {
                resolve()
            };
            request.onerror = () => reject(request.error);
        });
    };

}