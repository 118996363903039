import { IndexedDbService } from "./IndexedDbService";

interface LogTableItem {
    tableName: string;
    lastFetchedAt: number;
}

export class IndexedDbTimestampService extends IndexedDbService {

    logTableName: string;

    constructor(){
        const tableName = "dataFetchedLog";
        super(tableName);
        this.logTableName = tableName;
    }

    updateTimeStamp = (service : IndexedDbService) : void =>{
        const logItem: LogTableItem = {
            tableName: service.tableName,
            lastFetchedAt: Date.now()
        };
        
        this.putShapeIntoDb(logItem);
    };

    getTimeStampFor = (service : IndexedDbService) : Promise<number> =>{
        return Promise.reject("not implemmented");
    }

    private putShapeIntoDb = async (logItem: LogTableItem) => {
        const tx = this.db.transaction([this.logTableName], "readwrite");
            const store = tx.objectStore(this.logTableName);
            const request = store.put(logItem);
        request.onerror = () => console.error("Error while updating last fetched date in table " + this.logTableName);
    }
}