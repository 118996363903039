import { HandleType, Handler, MessageArg } from "../interfaces/AppCore";
import { MainStoreState } from "../interfaces/MainStoreState";
import { Message } from "../misc/Messages";
import { PsapShape } from "../models/PsapShape";

export class StateHandler implements Handler {

    constructor() {
        this.UpdatePSAPShape.message = Message.UpdatePSAPShape;
    }

    UpdatePSAPShape = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
       
        // put into psap state
        const psapData = arg.data as PsapShape;
        const psap = state.psaps.find((p) => p.psapId === psapData.PsapId)

        if (psap) {
            psap.shapeCoords = psapData.PsapShapeGoogleMaps;
        }
        return true;
    });

}