import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { DataAdapterProps } from "./DataAdapter";
import { IsNotUndefinedOrNull, IsUndefinedOrNull } from "../misc/Helpers";
import { NumberWidgetMainTabName, NumberWidgetMainTabProps } from "../components/WidgetOptions/NumberWidget/NumberWidgetMainTab";
import { NumberWidgetDataTabName, NumberWidgetDataTabProps } from "../components/WidgetOptions/NumberWidget/NumberWidgetDataTab";
import { NumberWidgetDesignTabName, NumberWidgetDesignTabProps } from "../components/WidgetOptions/NumberWidget/NumberWidgetDesignTab";
import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import { Styles } from "../interfaces/Dictionary";
import { NumberWidgetProps } from '@ecats911/edash-widgets-number';
import { defaultTitleFontIndex, defaultTitleFontSize, defaultValueFontIndex, defaultNumberWidgetValueFontSize, FontOptions, WidgetName } from "../misc/Constants";
import { WidgetEquations, EquationEnumToFunction } from "../misc/WidgetEquations";
import { ContainerOverride } from "../components/GenericContainer";
import EdashInterfaces from "@ecats911/edash-interfaces";

export class NumberWidgetConfigAdapter extends WidgetConfigAdapter<NumberWidgetProps> {

    main: NumberWidgetMainTabProps;
    data: NumberWidgetDataTabProps;
    design: NumberWidgetDesignTabProps;
    psaps: UserPsaps[];
    metrics: Metric[];
    equation: WidgetEquations;
    containerOverride: ContainerOverride = null;

    constructor(config: object, psaps: UserPsaps[], metrics: Metric[], containerOverride: ContainerOverride, sortOrder: string) {

        super();
        this.main = config[NumberWidgetMainTabName];
        this.data = config[NumberWidgetDataTabName];
        this.design = config[NumberWidgetDesignTabName];


        if (IsUndefinedOrNull(this.main))
            this.main = {};

        if (IsUndefinedOrNull(this.data))
            this.data = { psaps: psaps, metrics: metrics, sortOrder: sortOrder, designTabName: NumberWidgetDesignTabName };

        if (IsUndefinedOrNull(this.design))
            this.design = { dataTabName: NumberWidgetDataTabName };

        this.psaps = psaps;
        this.metrics = metrics;
        this.containerOverride = containerOverride;
    }

    getDataConfiguration = (): DataAdapterProps => {

        const metric = this.metrics.find(m => m.id === this.data.selectedMetric);

        let selectedPsapIds;
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            selectedPsapIds = [this.containerOverride.psap.nenaIdentifier];
        }
        else {
            selectedPsapIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];
        }

        const p: DataAdapterProps = {
            equation: EquationEnumToFunction(this.data.selectedEquation),
            metricDefinitions: IsUndefinedOrNull(metric) ? [] : [metric],
            psapNenaIds: selectedPsapIds
        };

        return p;
    };


    getWidgetConfiguration = (widgetName: WidgetName, widgetParams: EdashInterfaces.WidgetParameters, psapFilter: string | null): NumberWidgetProps => {

        let titleFontSize = isNaN(this.design.titleFontSize) || this.design.titleFontSize < 8 ? defaultTitleFontSize : this.design.titleFontSize;
        let valueFontSize = isNaN(this.design.valueFontSize) || this.design.valueFontSize < 8 ? defaultNumberWidgetValueFontSize : this.design.valueFontSize;

        if (titleFontSize > 100)
            titleFontSize = 100;

        if (valueFontSize > 100)
            valueFontSize = 100;

        const style: Styles = {
            valueThreshold: {
                fill: IsUndefinedOrNull(this.design.thresholdColor) ? "#ED1E1E" : this.design.thresholdColor
            },

            value: {
                fontSize: `${valueFontSize}px`,
                fontFamily: FontOptions.find(font => font.value === this.design.valueFontValue)?.label ?? FontOptions[defaultValueFontIndex].label,
                fill: '#555'
            },

            title: {
                fontSize: `${titleFontSize}px`,
                color: this.design.titleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
            },
            psap: {
                color: this.design.titleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
                fontSize: '13px',
                textDecoration: 'underline'
            },
            mlabel: {
                color: this.design.titleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
                fontSize: '12px'
            }
        };

        const metric = this.metrics.find(m => m.id === this.data.selectedMetric);

        let selectedPsapIds = [];
        let psapNames: string[];
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            psapNames = [];
            let psapNenaId = this.psaps.find(j => this.containerOverride.psap.nenaIdentifier === j.nenaIdentifier)?.nenaIdentifier            
            selectedPsapIds = IsNotUndefinedOrNull(psapNenaId) ? [psapNenaId] : []
        }
        else {

            selectedPsapIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];

            const filterApplies = psapFilter !== null && selectedPsapIds.some(id => id === psapFilter);

            psapNames = selectedPsapIds
                .filter(id => {
                    if (!filterApplies) return true;
                    return id === psapFilter;
                }).map(psapId => {
                    let label = this.design?.psapLabels?.find(i => i.id === psapId)?.name
                    if (IsUndefinedOrNull(label))
                        label = this.psaps.find(x => x.nenaIdentifier === psapId)?.psapName

                    return label;
                })
        }

        const model: NumberWidgetProps = {
            thresholdMin: this.data.useMinThreshold ? isNaN(this.data.thresholdMinValue) ? [0] : [this.data.thresholdMinValue] : [],
            thresholdMax: this.data.useMaxThreshold ? isNaN(this.data.thresholdMaxValue) ? [100] : [this.data.thresholdMaxValue] : [],
            style: style,
            title: this.main.title ?? "",
            metric: this.design?.metricLabel?.name ? [this.design?.metricLabel.name] : IsNotUndefinedOrNull(metric) ? [metric.displayNameKey] : [],
            selectedPSAPs: psapNames,
            selectedPSAPsNenaIds: selectedPsapIds,
            parameters: widgetParams
        };

        return model;
    }

    getUnfilteredSelectedPsapIds = (): string[] => {
        return this.data?.selectedPsapIds;
    }
}