import { IsUndefinedNullOrEmpty } from '../../../misc/Helpers';
import { SingleMetricMainTabProps } from "./SingleMetricMainTab"
import { SingleMetricDataTabProps } from "./SingleMetricDataTab"
import { SingleMetricDesignTabProps } from "./SingleMetricDesignTab"
import { ValidationResult } from "../../WidgetOptions"
import { RowDefinition } from '../../../models/Metric';
import { SingleMetricWidgetMinMaxValues, SingleMetricWidgetMinMaxThresholdValues } from "../../../misc/Constants";


/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const SingleMetricConfigValidator = [

	// Main tab validator
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (Number.isNaN(main.minValue))
			return { isValid: false, message: "A valid UserMin value must be provided", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (Number.isNaN(main.maxValue))
			return { isValid: false, message: "A valid UserMax value must be provided", tabName: tabLabel };

		return { isValid: true, message: "" }
	},
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {
		const minValue = typeof main.minValue === 'undefined' ? SingleMetricWidgetMinMaxValues.min : main.minValue;
		const maxValue = typeof main.maxValue === 'undefined' ? SingleMetricWidgetMinMaxValues.max : main.maxValue;
		if (maxValue <= minValue)
			return { isValid: false, message: "Max value must be greater than min value", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(main: SingleMetricMainTabProps, data: SingleMetricDataTabProps, design: SingleMetricDesignTabProps): ValidationResult => {
		if (main.minValue <= main.maxValue) {
			return { isValid: false, message: "Max Value must be greater than Min Value", tabName: "Main" }
		}
		else {
			return { isValid: true, message: "" }
		}
	},

	// Data tab validator
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (IsUndefinedNullOrEmpty(data.selectedMetric))
			return { isValid: false, message: "A metric (column) must be selected", tabName: "Data" };
		if (data.useMinThreshold === true && data.useMaxThreshold === false && Number.isNaN(data.thresholdMinValue)) {
			return { isValid: false, message: "A valid MinThreshold value must be provided", tabName: "Data" };
		}
		else if (data.useMinThreshold === false && data.animatedThreshold === true && Number.isNaN(data.thresholdMaxValue)) {
			return { isValid: false, message: "A valid MaxThreshold value must be provided", tabName: "Data" };
		}
		else if (data.useMinThreshold === true && data.useMaxThreshold === true) {
			if (Number.isNaN(data.thresholdMinValue) || Number.isNaN(data.thresholdMaxValue)) {
				return { isValid: false, message: "A valid MinThreshold and/or MaxThreshold value must be provided", tabName: "Data" };
			}
			else {
				const minThresholdValue = typeof data.thresholdMinValue === 'undefined' ? SingleMetricWidgetMinMaxThresholdValues.min : data.thresholdMinValue;
				const maxThresholdValue = typeof data.thresholdMaxValue === 'undefined' ? SingleMetricWidgetMinMaxThresholdValues.max : data.thresholdMaxValue;
				if (maxThresholdValue <= minThresholdValue) {
					return { isValid: false, message: "Threshold Max value must be greater than Threshold Min value", tabName: "Data" };
				}
				else {
					return { isValid: true, message: "" }
				}
			}
		}
		return { isValid: true, message: "" }
	},
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {

		// Allow not having any selected psap if the selected metric group contains metrics with rowDefinition=None
		let metricGroupWithNoRow = false;
		if (data.metrics.filter(m => m.metricGroup === data.selectedMetricGroup).every(m2 => m2.rowDefinition === RowDefinition.None))
			metricGroupWithNoRow = true;

		if (!data.psapOverride && !metricGroupWithNoRow && (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0))
			return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(data: SingleMetricDataTabProps, main: SingleMetricMainTabProps, design: SingleMetricDesignTabProps, tabLabel: string): ValidationResult => {

		if (data.selectedPsapIds?.length > 1 && IsUndefinedNullOrEmpty(data?.selectedEquation))
			return { isValid: false, message: "An equation must be selected when multiple rows (PSAPs) are selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	}
];
