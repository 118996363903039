import React, { useState } from "react";
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { FontOptions, FontSizes, ReactSelectStyles } from "../../../misc/Constants";
import { ColorPicker } from "../../ColorPicker";
import Select from "react-select";
import style from './style.module.scss';
import classNames from "classnames";
import { OptionType } from "../../../models/OptionType";
import { TabComponent } from "../Tab";

export interface MultiMetricDesignTabProps extends TabComponent {

    titleFontValue?: number;
    titleFontSize?: number;
    titleFontColor?: string;

}

export const MultiMetricDesignTabName = "MultiMetricDesignTab";

export const MultiMetricDesignTab = (props: MultiMetricDesignTabProps): JSX.Element => {

    const fontValue = isNaN(props.titleFontValue) ? 0 : props.titleFontValue;
    let initialTitleFont = FontOptions.find(f => f.value === fontValue);
    if (typeof initialTitleFont === "undefined") {
        initialTitleFont = FontOptions[0];
    }

    const initialTitleFontSize = isNaN(props.titleFontSize) ? FontSizes[2] : props.titleFontSize;
    const initialTitleFontColor = StringIsNullOrWhiteSpace(props.titleFontColor) ? "#555555" : props.titleFontColor;


    const [titleFont, setTitleFont] = useState(initialTitleFont);
    const [titleFontSize, setTitleFontSize] = useState(initialTitleFontSize);
    const [titleColor, setTitleColor] = useState(initialTitleFontColor);


    const FontValueChange = (option: OptionType): void => {
        setTitleFont(option);
        typeof props.onChange === "function" && props.onChange("titleFontValue", option.value);
    };

    const FontSizeChange = (value: number): void => {
        setTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("titleFontSize", value);
    };

    const ColorChange = (color: string): void => {
        setTitleColor(color);
        typeof props.onChange === "function" && props.onChange("titleFontColor", color);
    };


    return (
        <React.Fragment>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change appearance</label>
                </div>


                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.option, style.type)}>
                        <Select
                            value={titleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={FontValueChange}
                        />
                        <label>font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={titleFontSize} onChange={e => FontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>

                    <div className={classNames(style.option, style.color)}>
                        <ColorPicker
                            label="font color"
                            color={titleColor}
                            onValueChange={ColorChange}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}