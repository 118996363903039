import React from "react";

export interface Dimensions{
    height: number;
    width: number;
    left: number;
    top: number;
}

const SizeContext = React.createContext<Dimensions>({
    height: null,
    width: null,
    left: 0,
    top: 0
});

export { SizeContext }