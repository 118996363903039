import React, { useContext, useEffect, useState } from "react";
import { StreamingData } from "../interfaces/StreamingData";
import { PubSubTopic } from "../misc/Constants";
import { bindFunctionEnumToFct } from "../misc/BindFunctions";
import { DefaultIfUndefinedOrNull, IsUndefinedOrNull, IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, StringIsNullOrWhiteSpace } from "../misc/Helpers"
import { Metric, RowDefinition } from "../models/Metric";
import { Percentage } from "../misc/WidgetEquations";
import { AppContext } from "../interfaces/AppContext";

// DataAdapterProps - Configuration to bind to data source and calculate metric values. The widget configuration included in props here will be removed.
export interface DataAdapterProps {

    // Data binding and calculation
    metricDefinitions?: Metric[]
    psapNenaIds?: string[];			// List of PSAPs from which data should be considered
    equation?: Function			// Equation function to use when a single widget has multiple values selected (for instance many PSAPs) and must return only one value. This is defined in single widget configuration.

    // Miscellaneous
    clearTimeoutMS?: number;
    children?: any;

    isMultimetricMode?: boolean;

    debug?: string; // use for conditional console logging. If troubleshooting specific widget, you can pass here widget title as example and conditionally log data for specific widget.
}

const DataAdapter = (props: DataAdapterProps): JSX.Element => {
    let { psapNenaIds } = props;
    let { metricDefinitions } = props;
    let { equation } = props;
    const { psapFilter } = useContext(AppContext);

    const isMultimetricMode = props.isMultimetricMode === true;

    const isPSAPFilterInUse = (): boolean => {
        if (psapFilter === null) {
            return false;
        }
        return psapNenaIds.some(n => n === psapFilter);
    };

    if (metricDefinitions?.length > 1 && psapNenaIds.length > 1) {
        console.error("Selecting multiple metrics and multiple PSAPs/rows simultaneously is not supported.");
    }

    // Set initialData and intialValue. 
    let initialData = [] as number[];

    if (IsUndefinedOrNull(metricDefinitions)) {
        initialData = [0];
    }
    else if (metricDefinitions.length > 1 || psapNenaIds.length === 0) {
        initialData = metricDefinitions.map(def => DefaultIfUndefinedOrNull(def?.defaultValue, 0));
    }
    else {
        initialData = psapNenaIds.map(() => DefaultIfUndefinedOrNull(metricDefinitions?.[0]?.defaultValue, 0));
    }

    const [numericalWidgetData, setNumericalWidgetData] = useState([...initialData]);

    // Keep last metrics, psaps and equation to reset data upon change
    const [lastSelectedMetrics, setLastSelectedMetrics] = useState(null);
    const [lastSelectedPsaps, setLastSelectedPsaps] = useState(null);
    const [lastEquation, setLastEquation] = useState(null);

    let clearTimeoutMS = IsNotUndefinedOrNull(props.clearTimeoutMS) ? props.clearTimeoutMS : 3000;

    const resetData = (): void => {
        setNumericalWidgetData(oldData => {
            oldData.length = initialData.length;
            oldData = [...initialData];
            return [...oldData];
        });
    };

    useEffect(() => {

        // We need one timer per widget value, and this happen either because of multiple-metrics or multiple PSAPs.
        let timeoutRefs = null;
        if (!IsUndefinedNullOrEmpty(metricDefinitions) && !IsUndefinedOrNull(psapNenaIds))
            timeoutRefs = new Array<number>(Math.max(metricDefinitions?.length, psapNenaIds.length));

        // Check if selected metrics have changed since last update
        if (IsNotUndefinedOrNull(lastSelectedMetrics) && (lastSelectedMetrics?.length !== metricDefinitions?.length || !lastSelectedMetrics.every((m, i) => metricDefinitions?.[i]?.id === m?.id))) {
            resetData();
        }
        // Check if selected PSAPs have changed since last update
        else if (IsNotUndefinedOrNull(lastSelectedPsaps) && (lastSelectedPsaps?.length !== psapNenaIds.length || !lastSelectedPsaps.every((p, i) => psapNenaIds[i] === p))) {
            resetData();
        }

        // Check if equation has changed since last update        
        else if (equation !== lastEquation) {
            // If not first time setting equation, reset data.
            if (lastEquation !== null) {
                resetData();
            }

            setLastEquation(_ => { return equation; });
        }

        setLastSelectedMetrics(metricDefinitions);
        setLastSelectedPsaps(psapNenaIds);

        // Callback for clearing a metric after timeout
        const clearMetric = (metricIndex: number, psapIndex: number, timerContext): void => {
            // The index of the value and timer to clear depends on whether this widget is a multi-metric or a multi-psap
            let valueIndex = Math.max(metricIndex, psapIndex);
            timeoutRefs[valueIndex] = null;

            clearTimeout(timerContext.timerId);


            if (numericalWidgetData[valueIndex] !== DefaultIfUndefinedOrNull(metricDefinitions[metricIndex]?.defaultValue, 0)) {
                // Set the widget data to default. 
                setNumericalWidgetData(oldData => {
                    oldData[valueIndex] = DefaultIfUndefinedOrNull(metricDefinitions[metricIndex].defaultValue, 0);
                    return [...oldData];
                })
            }
        };

        const kickTimer = (metric: Metric, psapIndex: number): void => {
            // Kick timer - Multi-value widgets have a timer for each values, which can be indexed by metric or PSAPs.
            let metricIndex = metricDefinitions.map(p => p.id).indexOf(metric.id);
            let valueIndex = Math.max(metricIndex, psapIndex);
            if (metricIndex > -1) {
                let timerId = timeoutRefs[valueIndex]
                if (IsNotUndefinedOrNull(timerId)) {
                    clearTimeout(timerId);
                    timeoutRefs[valueIndex] = null;
                }
                let timerContext = { timerId: null };
                let newTimerId = window.setTimeout(() => clearMetric(metricIndex, psapIndex, timerContext), clearTimeoutMS)
                timerContext.timerId = newTimerId;
                timeoutRefs[valueIndex] = newTimerId;
            }
        }

        // Start a timer on each metric/psap pair on re-render
        metricDefinitions?.forEach((metric: Metric) => {
            if (metric.rowDefinition === RowDefinition.None) {
                kickTimer(metric, 0);
            }
            else {
                psapNenaIds.forEach((psapId: string, index: number) => kickTimer(metric, index));
            }
        });

        const onDataNumericalWidget = (metric: Metric, stream: StreamingData): void => {

            // Determine the index of the value to update. This depends whether this widget is a multi-metric or a multi-psap
            let metricIndex = 0;
            let updateIndex = !StringIsNullOrWhiteSpace(stream.target) ? psapNenaIds.indexOf(stream.target) : metric.rowDefinition !== RowDefinition.None ? -1 : 0;

            if (metricDefinitions.length > 1) {
                updateIndex = metricDefinitions.map(p => p.id).indexOf(metric.id);
                metricIndex = updateIndex;
            }

            // Apply transformation function to stream to obtain metric value
            let transformFct = bindFunctionEnumToFct(metricDefinitions[metricIndex].bindFunction);
            let val = transformFct({ data: stream.data, bind: (DefaultIfUndefinedOrNull(metricDefinitions[metricIndex].bindProperty, "")) });

            // Update data only if it changed     

            if (numericalWidgetData[updateIndex] !== val) {
                // Set the widget data.
                setNumericalWidgetData(oldData => {
                    oldData[updateIndex] = val;
                    return [...oldData];
                });
            }
        };

        const onDataUpdate = (msg: any, stream: StreamingData): void => {

            // If metric definitions or psaps are not set, do not attempt to update widget data.
            if (!IsUndefinedNullOrEmpty(metricDefinitions) && !IsUndefinedOrNull(psapNenaIds)) {

                metricDefinitions.forEach(metric => {
                    // Match stream name with metric definition and PSAP.
                    if (metric?.streamName === stream.report) {
                        let psapIndex = !StringIsNullOrWhiteSpace(stream.target) ? psapNenaIds.indexOf(stream.target) : metric.rowDefinition !== RowDefinition.None ? -1 : 0;
                        if (IsNotUndefinedOrNull(metric) && psapIndex > -1) {
                            kickTimer(metric, psapIndex);
                            onDataNumericalWidget(metric, stream);
                        }
                    }
                });
            }
        };

        const dispatcherRef = PubSub.subscribe(PubSubTopic.Stream, onDataUpdate);

        return () => {

            timeoutRefs?.forEach(timer => IsNotUndefinedOrNull(timer) && clearTimeout(timer))
            PubSub.unsubscribe(dispatcherRef);
        };
    }, [metricDefinitions, psapNenaIds, equation, props.clearTimeoutMS, lastEquation, numericalWidgetData]);

    if (Array.isArray(props.children)) {
        if (props.children.length > 1) {
            console.warn("Only 1 child component supported per DataAdapter", props.children);
        }
    }

    let outputValue = numericalWidgetData;

    const filtering = isPSAPFilterInUse();

    if (filtering && isMultimetricMode === false) {
        const idx = psapNenaIds.indexOf(psapFilter);
        outputValue = [outputValue[idx]];
    }
  
    else {
        if (IsNotUndefinedOrNull(equation)) {
            if (equation === Percentage) {
                outputValue = outputValue.map(value => equation(numericalWidgetData, value));
            }
            else {
                outputValue = [equation(numericalWidgetData)];
            }
        }
    }

    return (
        <React.Fragment>
            {
                React.Children.map(props.children, child => {
                    return React.cloneElement(child, { values: outputValue })
                })
            }
        </React.Fragment>
    );
}

export { DataAdapter }