import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useRef } from "react";
import { IsFunction } from "../../misc/Helpers";
import { ContentCopy, Share, TextFormat, SvgIconComponent } from "@mui/icons-material";
import style from "./style.module.scss";
import React from "react";
import { LayoutClickAction } from "./LayoutDropdown";

export type ContextOption = {
    icon: IconProp | SvgIconComponent
    label: string;
    disabled?: boolean;
    action : LayoutClickAction;
}

export interface ContextMenuProps {
    onClose?: () => void;
    onOptionClick?: (opt: ContextOption) => void;
    options: ContextOption[];
}

export const LayoutInheritorOptions: ContextOption[] = [
    {
        label: "Duplicate",
        disabled: false,
        icon: ContentCopy,
        action: "duplicate"
    }
];

export const LayoutOwnerOptions: ContextOption[] = [
    {
        label: "Rename",
        disabled: false,
        icon: TextFormat,
        action: "rename"
    },
    {
        label: "Share",
        disabled: false,
        icon: Share,
        action: "share"
    },
    {
        label: "Duplicate",
        disabled: false,
        icon: "clone",
        action: "duplicate"
    },
    {
        label: "Delete",
        disabled: false,
        icon: "trash",
        action: "delete"
    }
];

export const ContextMenu = (props: ContextMenuProps): ReactElement => {

    const closeFn = IsFunction(props.onClose) ? props.onClose : null;

    const timerRef = useRef(0);

    const onMouseEnter = (e: React.MouseEvent): void => {
        window.clearTimeout(timerRef.current);
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        timerRef.current = window.setTimeout(triggerClose, 400);
    };

    const triggerClose = (): void => {
        closeFn !== null && closeFn();
    };

    const onOptionClick = (opt: ContextOption, event: React.MouseEvent): void => {
        if (opt.disabled === true) {
            return;
        }
        IsFunction(props.onOptionClick) && props.onOptionClick(opt);
    };

    return (
        <div
            onMouseEnter={closeFn !== null ? onMouseEnter : null}
            onMouseLeave={closeFn !== null ? onMouseLeave : null}
            className={style.context}>
            {
                props.options.map((opt: ContextOption, idx: number) => {

                    return (
                        <div onClick={e => onOptionClick(opt, e)} className={style.option} key={idx}>
                            {
                                typeof opt.icon === "string" &&
                                <FontAwesomeIcon icon={opt.icon as IconProp} />
                            }
                            {
                                typeof opt.icon === "object" &&
                                React.createElement(opt.icon as any, null)
                            }                          
                            <span>{opt.label}</span>
                        </div>
                    )
                })
            }
        </div>
    )
};