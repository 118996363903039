import { SingleMetricDataTab, SingleMetricDataTabProps } from "../SingleMetric/SingleMetricDataTab";

export interface NumberWidgetDataTabProps extends SingleMetricDataTabProps { }

export const NumberWidgetDataTabName = "NumberWidgetDataTab";

const NumberWidgetDataTab = (args: NumberWidgetDataTabProps) => {
    return SingleMetricDataTab(args);
};


export { NumberWidgetDataTab }