import EdashInterfaces from "@ecats911/edash-interfaces";
import { DataAdapterProps } from "../services/DataAdapter";
import { WidgetName } from "../misc/Constants";
import { UserPsaps } from "../models/UserPsaps";

export class WidgetConfigAdapter<T> {
    constructor() { }

    // Return configuration formatted for the Data Adapter
    getDataConfiguration: () => DataAdapterProps;

    // Return configuration formatted for the widget rendering
    getWidgetConfiguration: (widgetName?: WidgetName, widgetParams?: EdashInterfaces.WidgetParameters, psapFilter?: string | null) => T;

    // Return all configured PSAP ids of the widget, whether they are accessible for the user or not. Used to determine if the widget is configured exclusively with inaccessible psaps
    getUnfilteredSelectedPsapIds: () => string[];

    // Returns true if all psaps in the widget's configuration are accessible to the user, false otherwise
    isDataAccessible(psaps: UserPsaps[], selectedPsapIds: string[]): boolean {
        if (selectedPsapIds?.length > 0)
            return selectedPsapIds.every(s => psaps.some(p => p.nenaIdentifier === s));
        else return true;
    }
}
