
import { WidgetCategory } from "../components/WidgetsSidePanel";
import { HandleType, Handler, MessageArg } from "../interfaces/AppCore";
import { MainStoreState } from "../interfaces/MainStoreState";
import { IsNotUndefinedOrNull, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { Message } from "../misc/Messages";
import { DashboardNotification } from "../models/DashboardNotification";


export class ActionsHandler implements Handler {

    constructor() {
        this.ChangePSAPView.message = Message.ChangePSAPView;
        this.ToggleWidgetStoreTab.message = Message.ToggleWidgetStoreTab;

        this.ToggleWidgetsMenu.message = Message.ToggleWidgetsMenu;
        this.OpenWidgetsMenu.message = Message.OpenWidgetsMenu;
        this.CloseWidgetsMenu.message = Message.CloseWidgetsMenu;
        this.ToggleDashboardViewMode.message = Message.ToggleDashboardViewMode;
        this.PushNotification.message = Message.PushNotification;
        this.AcknowledgeNotification.message = Message.AcknowledgeNotification;

        this.EmitStateChange.message = Message.EmitStateChange;
        this.ChangePSAPFilter.message = Message.ChangePSAPFilter;

        this.ToggleMapCallPlotting.message = Message.ToggleMapCallPlotting;
    }

    ChangePSAPView = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        state.psapView = arg.data.value;
        return true;
    });

    ChangePSAPFilter = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        if (StringIsNullOrWhiteSpace(arg.data.psapNenaIdentifier)) {
            state.psapFilter = null;
        }
        else state.psapFilter = arg.data.psapNenaIdentifier;
        return true;
    });

    ToggleWidgetStoreTab = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        const tab = arg.data.tab as WidgetCategory;

        state.widgetTabs = state.widgetTabs.map(w => {
            if (w.id === tab.id) {
                w.expanded = !w.expanded;
            }
            else {
                w.expanded = false;
            }
            return w;
        });

        return true;
    });


    ToggleDashboardViewMode = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        const currentLayout = state.layouts
            .filter(l => l.context === "regional")
            .find(x => x.id === state.user.account.settings.activeLayoutId);

        if (IsNotUndefinedOrNull(currentLayout)) {

            if (currentLayout.ownerId === state.user.account.userId) {
                state.isDesignMode = !state.isDesignMode;

                if (state.widgetsMenuExpanded === false && state.isDesignMode) {
                    state.widgetsMenuExpanded = true;
                }
            }
            else {

            }
        }

        return true;
    });

    // The emit state change handler must return true so that states changes get emitted.
    EmitStateChange = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        return true;
    });

    OpenWidgetsMenu = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        state.widgetsMenuExpanded = true;
        return true;
    });

    CloseWidgetsMenu = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        state.widgetsMenuExpanded = false;

        // close any expanded tabs
        const expandedTab = state.widgetTabs.find(x => x.expanded === true);
        if (typeof (expandedTab) !== "undefined") {
            expandedTab.expanded = false;
        }

        return true;
    });

    ToggleWidgetsMenu = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        state.widgetsMenuExpanded = !state.widgetsMenuExpanded;

        // close any expanded tabs
        if (!state.widgetsMenuExpanded) {
            const expandedTab = state.widgetTabs.find(x => x.expanded === true);
            if (typeof (expandedTab) !== "undefined") {
                expandedTab.expanded = false;
            }
        }

        return true;
    });

    PushNotification = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {

        const notification = arg.data as DashboardNotification;

        if (!IsUndefinedOrNull(notification) && !StringIsNullOrWhiteSpace(notification.id)) {

            const filteredList = state.notifications.filter(p => p.id !== notification.id);
            filteredList.push(notification);
            state.notifications = filteredList;
        }

        return true;
    });

    AcknowledgeNotification = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        console.log(arg.data.notificationId);
        if (!StringIsNullOrWhiteSpace(arg.data.notificationId)) {
            const notification = state.notifications.find(x => x.id === arg.data.notificationId);
            if (!IsUndefinedOrNull(notification)) {
                notification.acknowledged = true;
            }
        }
        return true;
    });

    ToggleMapCallPlotting = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        state.mapWidgetInEdit = arg.data.mapIsInEdit;
        return true;
    });
}