import classNames from 'classnames';
import style from '../scss/layout.module.scss';

export const EmptyNotificationSettings = (): JSX.Element => {

    let emptyNotificationSettingsImageName = "EmptyNotificationSettings.png";

    return (
        <div className={style.container}>
            <div className={style.emptyState}>
                {
                    <div className={style.emptyStateContainer}>
                        <img src={emptyNotificationSettingsImageName} className={style.emptyStateImage} draggable={false} />
                        <div className={classNames(style.emptyStateTitle, style.morePadding)}><span>Nothing to see here, yet!</span></div>
                        <div><span>Set up a new notification to see what's</span></div>
                        <div><span>going on while you're away from</span></div>
                        <div><span>your dashboard.</span></div>
                    </div>
                }
            </div >
        </div >
    );
}
