import React, { useState } from "react";
import { TabComponent } from "../Tab";
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { TooltipControl } from "../../ToolTipControl";

export interface ContainerMainTabProps extends TabComponent {
    title?: string;
}

export const ContainerMainTabName = "ContainerMainTab";

export const ContainerMainTab = (props: ContainerMainTabProps) => {
    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;

    const [title, setTitle] = useState(initialTitle);

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };

    return (
        <React.Fragment>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>

                <input
                    type="text"
                    className="form-control"
                    placeholder="title"
                    maxLength={100}
                    onChange={e => TitleChange(e.target.value)}
                    value={title}
                />
            </div>

        </React.Fragment>
    );
}