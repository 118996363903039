import React, { useState } from "react";
import classNames from "classnames";
import style from './style.module.scss';
import { IsNotUndefinedOrNull, SetToZeroIfNegativeValue } from "../../../misc/Helpers";
import { BarChartThresholdData, BarChartThresholdValues } from "./BarChartShared";

export interface BarChartThresholdConfigProps {
    isForPsaps: boolean;
    useMinThreshold: boolean;
    useMaxThreshold: boolean;
    thresholdValues: BarChartThresholdValues[];
    useOneValueMinThreshold?: boolean;
    useOneValueMaxThreshold?: boolean;
    oneValueMaxThreshold?: number;
    oneValueMinThreshold?: number;
    onCancel: () => void;
    onThresholdValuesChanged: (thresholdValues: BarChartThresholdValues[]) => void;
    onSave: (thresholdData: BarChartThresholdData) => void;
}

export const BarChartThresholdConfig = (props: BarChartThresholdConfigProps): JSX.Element => {
    const [oneValueForMaxThresholdChecked, setOneValueForMaxThresholdChecked] = useState(typeof props.useOneValueMaxThreshold !== 'undefined' && props.useMaxThreshold ? props.useOneValueMaxThreshold : false);
    const [oneValueForMinThresholdChecked, setOneValueForMinThresholdChecked] = useState(typeof props.useOneValueMinThreshold !== 'undefined' && props.useMinThreshold ? props.useOneValueMinThreshold : false);
    const [oneValueMaxThreshold, setOneValueMaxThreshold] = useState(typeof props.oneValueMaxThreshold !== 'undefined' ? props.oneValueMaxThreshold : 100);
    const [oneValueMinThreshold, setOneValueMinThreshold] = useState(typeof props.oneValueMinThreshold !== 'undefined' ? props.oneValueMinThreshold : 0);
    const [thresholdValues, setThresholdValues] = useState(props.thresholdValues);

    const onOneValueForMaxThreshold = (): void => {
        setOneValueForMaxThresholdChecked(!oneValueForMaxThresholdChecked);

        let data = thresholdValues;

        data.forEach((item) => {
            item.maxThresholdValue = oneValueMaxThreshold;
        });

        setThresholdValues(data);
    };

    const onOneValueForMinThreshold = (): void => {
        setOneValueForMinThresholdChecked(!oneValueForMinThresholdChecked);

        let data = thresholdValues;

        data.forEach((item) => {
            item.minThresholdValue = oneValueMinThreshold;
        });

        setThresholdValues(data);
    };

    const onForAllMaxThresholdValueChange = (value: number): void => {
        let data = thresholdValues;

        data.forEach((item) => {
            item.maxThresholdValue = value;
        });

        setOneValueMaxThreshold(value);
        setThresholdValues(data);
        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdValues);
    }

    const onForAllMinThresholdValueChange = (value: number): void => {
        let data = thresholdValues;

        data.forEach((item) => {
            item.minThresholdValue = value;
        });

        setOneValueMinThreshold(value);
        setThresholdValues(data);
        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdValues);
    }

    const onThresholdValueChange = (id_metric: string, id_psap, value: number, isMax: boolean, thresholdsAreForPsap: boolean): void => {
        let data = thresholdValues;

        value = SetToZeroIfNegativeValue(value);

        // Max threshold value change
        if (isMax) {
            // Threshold are assigned to metrics (multiple metrics selected)
            if (!thresholdsAreForPsap) {
                let item = data.find(x => x.id_metric === id_metric)
                if (IsNotUndefinedOrNull(item)) {
                    item.maxThresholdValue = value;
                    item.id_psap = id_psap;
                }
            }
            // Threshold are assigned to psaps (multiple psap selected, or 1 metric 1 psap)
            else {
                let item = data.find(x => x.id_psap === id_psap);
                if (IsNotUndefinedOrNull(item)) {
                    item.maxThresholdValue = value;
                    item.id_metric = id_metric;
                }
            }
        }
        // Min threshold value change
        else {
            // Threshold are assigned to psaps (multiple psap selected, or 1 metric 1 psap)
            if (!thresholdsAreForPsap) {
                let item = data.find(x => x.id_metric === id_metric)
                if (IsNotUndefinedOrNull(item)) {
                    item.minThresholdValue = value;
                    item.id_psap = id_psap;
                }
            }
            // Threshold are assigned to psaps (multiple psap selected, or 1 metric 1 psap)
            else {
                let item = data.find(x => x.id_psap === id_psap)
                if (IsNotUndefinedOrNull(item)) {
                    item.minThresholdValue = value;
                    item.id_metric = id_metric;
                }
            }
        }
        setThresholdValues(data);
        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdValues);
    };

    const oneValueThresholdRangeValid = (min: number, max: number): boolean => {
        if (props.useMinThreshold && props.useMaxThreshold) {
            if (isNaN(min) || isNaN(max)) return false;
            if (oneValueForMinThresholdChecked && oneValueForMaxThresholdChecked)
                return min < max;
            else if (oneValueForMinThresholdChecked && !oneValueForMaxThresholdChecked) {
                if (isNaN(min)) return false;
                const data = thresholdValues;
                for (let i = 0; i < data.length; i++) {
                    if (min >= data[i].maxThresholdValue) return false;
                }
                return true;
            }
            else if (!oneValueForMinThresholdChecked && oneValueForMaxThresholdChecked) {
                if (isNaN(max)) return false;
                const data = thresholdValues;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].minThresholdValue >= max || max === 0) return false;
                }
                return true;
            }
        }
        else
            return true;
    }

    const thresholdRangeValid = (min: number, max: number, disabled: boolean): boolean => {
        if (isNaN(min) || isNaN(max)) return false;

        if (props.useMinThreshold && props.useMaxThreshold && !disabled) {
            return min < max;
        }
        else
            return true;
    }

    const popupValid = (): boolean => {
        let isValid = true;

        if (props.useMinThreshold && props.useMaxThreshold) {
            if (oneValueForMinThresholdChecked && oneValueForMaxThresholdChecked) {
                isValid = oneValueThresholdRangeValid(oneValueMinThreshold, oneValueMaxThreshold);
            }
            else {
                const data = thresholdValues;

                if (oneValueForMinThresholdChecked && !oneValueForMaxThresholdChecked) {
                    for (let i = 0; i < data.length; i++) {
                        if (oneValueMinThreshold >= data[i].maxThresholdValue) {
                            isValid = false;
                            break;
                        }
                    }
                }
                else if (!oneValueForMinThresholdChecked && oneValueForMaxThresholdChecked) {
                    for (let i = 0; i < data.length; i++) {
                        if (oneValueMaxThreshold <= data[i].minThresholdValue) {
                            isValid = false;
                            break;
                        }
                    }
                }
                else {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].minThresholdValue >= data[i].maxThresholdValue) {
                            isValid = false;
                            break;
                        }
                    }
                }
            }
        }
        else if (!props.useMinThreshold && props.useMaxThreshold) {
            if (oneValueForMaxThresholdChecked) {
                isValid = oneValueMaxThreshold === 0 ? false : true;
            }
            else {
                const data = thresholdValues;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].maxThresholdValue === 0) {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        return isValid;
    }

    const onSaveData = (): void => {
        const thresholdData: BarChartThresholdData = {
            useOneValueMaxThreshold: oneValueForMaxThresholdChecked,
            useOneValueMinThreshold: oneValueForMinThresholdChecked,
            oneValueMaxThreshold: oneValueMaxThreshold,
            oneValueMinThreshold: oneValueMinThreshold,
            thresholdValues: thresholdValues
        };
        typeof props.onSave === "function" && props.onSave(thresholdData);
    }

    return (
        <div className={style.thresholdConfigContainer}>
            <div className={style.header}>
                <div className={style.title}>
                    Set Threshold Values
                </div>
                <div className={style.buttons}>
                    <button className={classNames(style.button, style.cancel)} onClick={props.onCancel}>Cancel</button>
                    <button disabled={!popupValid()} className={classNames(style.button, style.save)} onClick={onSaveData}>Save</button>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.widthLong}>{props.isForPsaps ? "PSAP Name(s)" : "Metric(s)"}</div>
                    {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                    {props.useMinThreshold && <div className={style.widthShort}>Min Threshold</div>}
                    {props.useMaxThreshold && <div className={style.widthShort}>Max Threshold</div>}
                </div>
                <div className={style.oneValueForAll}>
                    <div className={style.widthLong}></div>
                    {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                    {
                        props.useMinThreshold &&
                        <div className={style.widthShort}>
                            <input type="checkbox" onClick={onOneValueForMinThreshold} defaultChecked={oneValueForMinThresholdChecked} /> Set one value for all
                        </div>
                    }
                    {
                        props.useMaxThreshold &&
                        <div className={style.widthShort}>
                            <input type="checkbox" onClick={onOneValueForMaxThreshold} defaultChecked={oneValueForMaxThresholdChecked} /> Set one value for all
                        </div>
                    }
                </div>
                {
                    (oneValueForMaxThresholdChecked || oneValueForMinThresholdChecked) &&
                    <div className={style.data}>
                        <div className={style.dataRow}>
                            <div className={style.widthLong}>All</div>
                            {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                            {
                                props.useMinThreshold &&
                                <div className={style.widthShort}>
                                    {
                                        oneValueForMinThresholdChecked &&
                                        <input
                                            className={oneValueMinThreshold >= 0 && oneValueThresholdRangeValid(oneValueMinThreshold, oneValueMaxThreshold) ? "form-control" : "form-control warn"}
                                            min={0}
                                            onChange={(e) => onForAllMinThresholdValueChange(+e.target.value)}
                                            type="number"
                                            value={oneValueMinThreshold} />
                                    }
                                </div>
                            }
                            {
                                props.useMaxThreshold &&
                                <div className={style.widthShort}>
                                    {
                                        oneValueForMaxThresholdChecked &&
                                        <input
                                            className={oneValueMaxThreshold > 0 && oneValueThresholdRangeValid(oneValueMinThreshold, oneValueMaxThreshold) ? "form-control" : "form-control warn"}
                                            min={0}
                                            onChange={(e) => onForAllMaxThresholdValueChange(+e.target.value)}
                                            type="number"
                                            value={oneValueMaxThreshold} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    (props.useMaxThreshold || props.useMinThreshold) && thresholdValues.length > 0 &&
                    <div className={style.data}>
                        {
                            thresholdValues.map((item, index) => {
                                const thresholdId = item.id_metric + "_" + item.id_psap
                                return (
                                    <React.Fragment key={thresholdId}>
                                        <div className={classNames(style.dataRow, index % 2 !== 0 ? style.dataRowOdd : null)}>
                                            <div className={style.widthLong}>{item.name}</div>
                                            {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                                            {
                                                props.useMinThreshold &&
                                                <div className={style.widthShort}>
                                                    <input disabled={!props.useMinThreshold || oneValueForMinThresholdChecked}
                                                        className={(item.minThresholdValue >= 0 || oneValueForMinThresholdChecked) && thresholdRangeValid(item.minThresholdValue, item.maxThresholdValue, oneValueForMinThresholdChecked) ? "form-control" : "form-control warn"}
                                                        min={0}
                                                        onChange={(e) => onThresholdValueChange(item.id_metric, item.id_psap, +e.target.value, false, props.isForPsaps)}
                                                        type="number"
                                                        value={item.minThresholdValue} />
                                                </div>
                                            }
                                            {
                                                props.useMaxThreshold &&
                                                <div className={style.widthShort}>
                                                    <input disabled={!props.useMaxThreshold || oneValueForMaxThresholdChecked}
                                                        className={(item.maxThresholdValue > 0 || oneValueForMaxThresholdChecked) && thresholdRangeValid(item.minThresholdValue, item.maxThresholdValue, oneValueForMaxThresholdChecked) ? "form-control" : "form-control warn"}
                                                        min={0}
                                                        onChange={(e) => onThresholdValueChange(item.id_metric, item.id_psap, +e.target.value, true, props.isForPsaps)}
                                                        type="number"
                                                        value={item.maxThresholdValue} />
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}