import { app } from '../..';
import { WidgetOptions } from '../../components/WidgetOptions';
import { WidgetConfig, WidgetConfigBinder } from '../../services/WidgetConfigBinder';
import { Tab } from '../../components/WidgetOptions/Tab';
import { Dictionary } from '../../interfaces/Dictionary';
import { MultipleCircles } from '@ecats911/edash-widgets-multiplerings';
import { BarChartDataTab, BarChartDataTabName } from '../../components/WidgetOptions/BarChart/BarChartDataTab';
import { BarChartMainTab, BarChartMainTabName } from '../../components/WidgetOptions/BarChart/BarChartMainTab';
import { BarChartDesignTab, BarChartDesignTabName } from '../../components/WidgetOptions/BarChart/BarChartDesignTab';
import { BarChartConfigValidator } from '../../components/WidgetOptions/BarChart/BarChartConfigValidator';

import style from '../../scss/devview.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../interfaces/AppContext';

const BarChartConfigDevView = (props) => {

    const widgetConfig: Dictionary<WidgetConfig> = {};

    const mainTabOptions: WidgetConfig = {};
    const dataTabOptions: WidgetConfig = {};
    const designTabOptions: WidgetConfig = {};

    mainTabOptions["title"] = "Enter title here";
    mainTabOptions["minValue"] = 0;
    mainTabOptions["maxValue"] = 100;
    mainTabOptions["useThreshold"] = false;
    mainTabOptions["thresholdValue"] = 0;

    dataTabOptions["selectedPsapIds"] = ["28", "10"];
    dataTabOptions["useThreshold"] = true;
    dataTabOptions["thresholdValue"] = 120;
    dataTabOptions["selectedMetric"] = null;
    dataTabOptions["selectedMetricGroup"] = null;

    designTabOptions["titleFontValue"] = 1;
    designTabOptions["titleFontSize"] = 12;
    designTabOptions["titleFontColor"] = "#ff2800";

    widgetConfig[BarChartMainTabName] = mainTabOptions;
    widgetConfig[BarChartDataTabName] = dataTabOptions;
    widgetConfig[BarChartDesignTabName] = designTabOptions;

    // initialize tabs for WidgetOptions component
    // some properties are required and not part of the configuration
    // just pass directly to your tab component
    // ex: psap list or metric list
    // use your custom binder class to specify which properties to autobind

    const { localization } = useContext(AppContext);

    const tabs = [
      

        new Tab({
            name: BarChartDataTabName,
            label: localization["configTab1"],
            component: <BarChartDataTab
                psaps={app.store.state.psaps}
                metrics={app.store.state.metrics}
                psapOverride={false}
                sortOrder="" />
        }),

        new Tab({
            name: BarChartMainTabName,
            label: localization["configTab2"],
            component: <BarChartMainTab
                psaps={app.store.state.psaps}
                metrics={app.store.state.metrics}
                psapOverride={false} />
        }),

        new Tab({
            name: BarChartDesignTabName,
            label: localization["configTab3"],
            component: <BarChartDesignTab
                metrics={app.store.state.metrics} psaps={app.store.state.psaps} />
        })
    ];

    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(null);
    binder.initialize(tabs);

    return (
        <div className={style.container}>
            <h3>Component development</h3>

            <div className={style.BarChartcontainer}>
                <WidgetOptions
                    activeTab={"Data"}
                    title="Edit Bar Chart Widget"
                    configBinder={binder}
                    tabs={tabs}
                    onBeforeTabChange={(tab) => { return true; }}
                    onTabChange={tab => { }}

                    validators={BarChartConfigValidator}

                    onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                        if (allTabsValid) {
                            console.log("Configuration valid: ", config);
                        }
                        else {
                            console.log("Configuration invalid. Errors: ", errors);
                            console.log(config);
                        }
                    }}
                />

                <div className={style.widget}>
                    <MultipleCircles />
                </div>

            </div>

        </div>
    );
}

export { BarChartConfigDevView }