export enum DashboardNotificationLevel {
    Info,
    Warning,
    Error,
    Confirmation
}
// order by level then  by priority asc

export enum DashboardNotificationLocation {
    Default = 0,
    Bottom
}
export type DashboardNotification = {
    id: string;
    acknowledged: boolean;
    priority: number;
    title?: string;
    message: string;
    buttonText?: string;
    additionalText?: string;
    level: DashboardNotificationLevel;
    renderLocation?: DashboardNotificationLocation;
    confirmationMessage?: string;
};