import { OptionsTab } from "../components/WidgetOptions/Tab";
import { Dictionary } from "../interfaces/Dictionary";
import { IsUndefinedOrNull } from "../misc/Helpers";
import { CloneJSON } from "../misc/Helpers";

export type WidgetConfig = {
    [key: string]: any;
};

export interface ConfigBinder {

    readConfiguration: (options: Dictionary<WidgetConfig>) => void;

    getConfiguration: () => Dictionary<WidgetConfig>;

    initialize: (tabs: OptionsTab[], psapOverride?: boolean) => void;

    onDataChange: (tabName: string, property: string, newValue: any) => void;

    getProps: (componentName: string, component: JSX.Element) => any;

    getData: () => Dictionary<WidgetConfig>;

    isPsapConfigurationDisabled: () => boolean;
}

export class WidgetConfigBinder implements ConfigBinder {

    onDataChange: (tabName: string, property: string, newValue: any) => void;

    _outputConfig: Dictionary<WidgetConfig> = {}; // output config

    readConfiguration = (options: Dictionary<WidgetConfig>) => {
        if (!IsUndefinedOrNull(options)) {
            this.loadConfiguration(CloneJSON(options));
        }
    };

    psapOverride: boolean;

    constructor() {
        this.psapOverride = false;
    }

    isPsapConfigurationDisabled(): boolean {
        return this.psapOverride;
    }

    private loadConfiguration = (options: Dictionary<WidgetConfig>): void => {
        Object.getOwnPropertyNames(options).forEach((tabName: string) => {
            
            this._outputConfig[tabName] = {};

            const config = options[tabName];

            // Top level configuration items can be tab config which is a dictionary of value
            if (Array.isArray(config)) {
                Object.getOwnPropertyNames(config).forEach((property: string) => {
                    this._outputConfig[tabName][property] = config[property];
                });
            }
            // Or can be a single value
            else {
                this._outputConfig[tabName] = config;
            }
        });
    };


    initialize = (tabs: OptionsTab[], psapOverride?: boolean): void => {
        this.psapOverride = psapOverride;
        tabs.forEach(tab => {
            if (IsUndefinedOrNull(this._outputConfig[tab.name])) {
                this._outputConfig[tab.name] = {};
            }
        });
    };

    getConfiguration = (): Dictionary<WidgetConfig> => this._outputConfig;

    onChange = (tab: string, key: string, value: string): void => {
        this._outputConfig[tab][key] = value;
    };

    getProps = (componentName: string, component: JSX.Element): any => {
        return this.getPropsFor(componentName, component.props);
    };

    private getPropsFor = (tab: string, props: any): any => {

        const nextProps = { ...props };

        nextProps.onChange = (key: string, value: string) => {
            this.onChange(tab, key, value);
            typeof this.onDataChange === "function" && this.onDataChange(tab, key, value);
        };

        if (this._outputConfig === null) {
            return nextProps;
        }

        const config = this._outputConfig[tab];

        if (!IsUndefinedOrNull(config)) {
            Object.getOwnPropertyNames(config).forEach((property: string) => {
                nextProps[property] = config[property];
            });
        }    

        return nextProps;
    };

    getData = (): Dictionary<WidgetConfig> => {
        return this._outputConfig;
    }
}