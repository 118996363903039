import React, { useState } from "react";
import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { defaultTitleFontIndex, defaultTitleFontSize, FontOptions, FontSizes, ReactSelectStyles } from "../../../misc/Constants";
import { ColorPicker } from "../../ColorPicker";
import Select from "react-select";
import classNames from "classnames";
import { OptionType } from "../../../models/OptionType";
import { TabComponent } from "../Tab";

import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';

export interface ContainerDesignTabProps extends TabComponent {
    containerTitleFontValue?: number;
    containerTitleFontSize?: number;
    containerTitleFontColor?: string;
    widgetTitleFontValue?: number;
    widgetTitleFontSize?: number;
    widgetTitleFontColor?: string;
    widgetThresholdColor?: string;
}

export const ContainerDesignTabName = "ContainerDesignTab";

export const ContainerDesignTab = (props: ContainerDesignTabProps): JSX.Element => {

    // Flag to control whether the widget appearance can be configured. Depends on whether ECATS-4454 will be completed or not
    const configureWidgetAppearance = false;

    const containerFontValue = isNaN(props.containerTitleFontValue) ? NaN : props.containerTitleFontValue;
    let containerInitialTitleFont = FontOptions.find(f => f.value === containerFontValue);
    if (typeof containerInitialTitleFont === "undefined") {
        containerInitialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    const widgetFontValue = isNaN(props.widgetTitleFontValue) ? NaN : props.widgetTitleFontValue;
    let widgetInitialTitleFont = FontOptions.find(f => f.value === widgetFontValue);
    if (typeof widgetInitialTitleFont === "undefined") {
        widgetInitialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    const containerInitialTitleFontSize = isNaN(props.containerTitleFontSize) ? defaultTitleFontSize : props.containerTitleFontSize;
    const containerInitialTitleFontColor = StringIsNullOrWhiteSpace(props.containerTitleFontColor) ? "#000000" : props.containerTitleFontColor;

    const widgetInitialTitleFontSize = isNaN(props.widgetTitleFontSize) ? FontSizes[2] : props.widgetTitleFontSize;
    const widgetInitialTitleFontColor = StringIsNullOrWhiteSpace(props.widgetTitleFontColor) ? "#000000" : props.widgetTitleFontColor;
    const widgetInitialThresholdFontColor = StringIsNullOrWhiteSpace(props.widgetThresholdColor) ? "#ff2800" : props.widgetThresholdColor;

    const [containerTitleFont, setContainerTitleFont] = useState(containerInitialTitleFont);
    const [containerTitleFontSize, setContainerTitleFontSize] = useState(containerInitialTitleFontSize);
    const [containerTitleColor, setContainerTitleColor] = useState(containerInitialTitleFontColor);

    const [widgetTitleFont, setWidgetTitleFont] = useState(widgetInitialTitleFont);
    const [widgetTitleFontSize, setWidgetTitleFontSize] = useState(widgetInitialTitleFontSize);
    const [widgetTitleColor, setWidgetTitleColor] = useState(widgetInitialTitleFontColor);
    const [widgetThresholdColor, setWidgetThresholdColor] = useState(widgetInitialThresholdFontColor);

    const ContainerFontValueChange = (option: OptionType): void => {
        setContainerTitleFont(option);
        typeof props.onChange === "function" && props.onChange("containerTitleFontValue", option.value);
    };

    const ContainerFontSizeChange = (value: number): void => {
        setContainerTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("containerTitleFontSize", value);
    };

    const ContainerTitleColorChange = (color: string): void => {
        setContainerTitleColor(color);
        typeof props.onChange === "function" && props.onChange("containerTitleFontColor", color);
    };

    const WidgetFontValueChange = (option: OptionType): void => {
        setWidgetTitleFont(option);
        typeof props.onChange === "function" && props.onChange("widgetTitleFontValue", option.value);
    };

    const WidgetFontSizeChange = (value: number): void => {
        setWidgetTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("widgetTitleFontSize", value);
    };

    const WidgetTitleColorChange = (color: string): void => {
        setWidgetTitleColor(color);
        typeof props.onChange === "function" && props.onChange("widgetTitleFontColor", color);
    };

    const WidgetThresholdColorChange = (color: string): void => {
        setWidgetThresholdColor(color);
        typeof props.onChange === "function" && props.onChange("widgetThresholdColor", color);
    };


    return (
        <React.Fragment>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change container appearance</label>
                </div>

                <div className={style.name}>
                    <label>Title</label>
                </div>

                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={containerTitleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={ContainerFontValueChange}
                        />
                        <label>font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={containerTitleFontSize} onChange={e => ContainerFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>
                </div>
            </div>
            <div className={shared.row}>
                <div className={style.coptions}>
                    <div className={style.color}>
                        <ColorPicker
                            label="font color"
                            color={containerTitleColor}
                            onValueChange={ContainerTitleColorChange}
                        />
                    </div>
                </div>
            </div>
            {
                configureWidgetAppearance &&
                <div className={shared.row}>
                    <div className={shared.heading}>
                        <label>Change container widgets appearance</label>
                    </div>

                    <div className={style.fontcontrol}>
                        <div className={classNames(shared.select, style.option, style.type)}>

                            <Select
                                value={widgetTitleFont}
                                hideSelectedOptions={false}
                                menuPortalTarget={document.body}
                                options={FontOptions}
                                styles={ReactSelectStyles}
                                onChange={WidgetFontValueChange}
                            />
                            <label>change font</label>
                        </div>

                        <div className={classNames(shared.dropdown, style.option, style.size)}>
                            <select value={widgetTitleFontSize} onChange={e => WidgetFontSizeChange(+e.target.value)}>
                                {
                                    FontSizes.map((val: any, index: number) => {
                                        return <option key={index} value={val}>{val}</option>
                                    })
                                }
                            </select>
                            <label>font size</label>
                        </div>
                    </div>
                    <div className={shared.row}>
                        <div className={style.coptions}>
                            <div className={style.color}>
                                <ColorPicker
                                    label="font color"
                                    color={widgetTitleColor}
                                    onValueChange={WidgetTitleColorChange}
                                />
                            </div>
                            <div className={style.color}>
                                <ColorPicker
                                    label="threshold color"
                                    color={widgetThresholdColor}
                                    onValueChange={WidgetThresholdColorChange}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            }

        </React.Fragment>
    );
}