import React from 'react';
import { IsFunction } from '../misc/Helpers';
import style from '../scss/layout.module.scss';

interface EmptyDashboardProps {
    // Flag indicating whether the empty dashboard should display with edit mode images, or render mode images
    isEditMode: boolean;

    // Flag indicating whether the user has admin rights
    hasEditRights: boolean;

    // Handler for when the switch to edit mode button is clicked
    switchToEditModeHandleClick: Function;
}

export const EmptyDashboard = (props: EmptyDashboardProps): JSX.Element => {

    // Empty state dashboard image files
    let renderModeImageName = "DashboardEmptyStateRenderMode.png";
    let editModeImageName = "DashboardEmptyStateEditMode.png";
    let switchToEditModeButton = "SwitchToEditModeButton.png";

    const handleSwitchButtonClick = event => {
        if (IsFunction(props.switchToEditModeHandleClick)) {
            props.switchToEditModeHandleClick();
        }
    };

    return (
        <div className={style.emptyState}>
            {
                props.isEditMode &&
                <div className={style.emptyStateContainer}>
                    <img src={editModeImageName} className={style.emptyStateImage} draggable={false} />
                    <div className={style.emptyStateTitle}><span>Welcome to Edit Mode</span></div>
                    <div><span>Drag and drop widgets from</span></div>
                    <div><span>the Widget Library.</span></div>
                </div>
            }
            {
                !props.isEditMode &&
                <div className={style.emptyStateContainer}>
                    <img src={renderModeImageName} className={style.emptyStateImage} draggable={false} />
                    <div className={style.emptyStateTitle}><span>Welcome to your new dashboard</span></div>
                    <div><span>It's looking pretty empty in here.</span></div>
                    {
                        props.hasEditRights &&
                        <React.Fragment>
                            <div><span>Get's started by adding some widgets.</span></div>
                            <img src={switchToEditModeButton} onClick={handleSwitchButtonClick} className={style.emptyStateButton} draggable={false} />
                        </React.Fragment>
                    }
                    {
                        !props.hasEditRights &&
                        <div><span>Please contact your administrator to get set up.</span></div>
                    }
                </div>
            }
        </div >
    );
}
