import { AppContext } from '../interfaces/AppContext';
import { ReactElement, useContext, useEffect } from 'react';
import { PubSubTopic } from '../misc/Constants';
import { ErrorPageContent } from './partials/ErrorPageContent/ErrorPageContent';

export const Page404 = (): ReactElement => {

    let { user, localization } = useContext(AppContext);

    useEffect(() => {
        const elem = document.getElementsByTagName("body");

        const BackgroundClickHandler = (event) => {
            PubSub.publish(PubSubTopic.EventsBackgroundClick);
        };

        if (user !== null) {
            if (elem.length > 0) {
                elem[0].addEventListener("click", BackgroundClickHandler);
            }
        }

        return () => {
            if (user !== null && elem.length > 0) {
                elem[0].removeEventListener("click", BackgroundClickHandler);
            }
        }
    }, []);

    return (
        <ErrorPageContent 
                heading={localization["page404heading"]} 
                message={localization["page404message"]} 
                backButtonText={localization["page404backbutton"]} 
                loginInstructions={localization["page404logininstruction"]} />

    )
};