import * as Axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { DashboardLocale } from '..';
import { LocalConfiguration, WebClientConfig } from '../interfaces/Configuration';
import { Dictionary } from '../interfaces/Dictionary';
import { DashboardUserVm } from '../models/DashboardUserVm';
import { Layout } from '../models/DashboardLayout';
import { AdminSettings } from '../components/AdminSettingsDialog';
import { PsapShape } from '../models/PsapShape';

export interface FaultResult{
    reason: any;
    args: any;
}

export const getHubToken = () : Promise<string> => {
    return new Promise((resolve, reject) => {

        const request = Axios.default.post("https://hubconnector-dan.azurewebsites.net/api/GenerateSignalRJWT");
        request.then(result=>{               
            resolve(result.data.content);
        });
        request.catch(e=>{
            reject(e);                
        });
    });
};

export const GetUserProfile = (apiBaseUrl: string) : Promise<DashboardUserVm|FaultResult> => {

    return new Promise((resolve, reject) => {

        const request = Axios.default.get(apiBaseUrl + "/api/user/get");

        request.then(result=>{
            resolve(result.data);
        });

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else if(error.response.status === StatusCodes.NOT_FOUND){
                reject({
                    reason: "user-not-found",
                    args: error.response.data
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};

export const GetPsapShape = (apiBaseUrl: string, psapId: string) : Promise<PsapShape|FaultResult> => {

    return new Promise((resolve, reject) => {

        const request = Axios.default.get(apiBaseUrl + "/api/psap/getpsapshape?psapId=" + psapId);

        request.then(result=>{
            resolve(result.data);
        });

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else if(error.response.status === StatusCodes.NOT_FOUND){
                reject({
                    reason: "psap-not-found",
                    args: error.response.data
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};

export const GetLocalConfiguration = () : Promise<LocalConfiguration|FaultResult> => {
    
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);

    return new Promise((resolve, reject) => {
        const request = Axios.default.get("/config.json");
        request.then(result=>{
            resolve(result.data[process.env.NODE_ENV] as LocalConfiguration);
        }); 

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};


export const GetApiConfiguration = (apiBaseUrl: string) : Promise<WebClientConfig> => {
    
    return new Promise((resolve, reject) => {

        const request = Axios.default.get(apiBaseUrl + "/api/configuration/get");
        request.then(result=>{
            resolve(result.data as WebClientConfig);
        });
        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};

export const GetGlobalConfiguration = (apiBaseUrl: string) : Promise<AdminSettings> => {
    
    return new Promise((resolve, reject) => {

        const request = Axios.default.get(apiBaseUrl + "/api/configuration/getadminsettings");
        request.then(result=>{
            resolve(result.data as AdminSettings);
        });
        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};

export const GetLocalizationResource = (locale: DashboardLocale) : Promise<Dictionary<string>> => {
    return new Promise((resolve, reject) => {
        const request = Axios.default.get(`/localization/dict.${locale}.json`);
        request.then(result=>{
            resolve(result.data);
        });

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            console.error(error);
        });
    });   
};

export const GetWidgetManifest = (apiBaseUrl: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const request = Axios.default.get(`${apiBaseUrl}/api/widgets/manifest/all`);
        request.then(result=>{
            resolve(result.data);
        });

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });   
};

export const GetMetricDefinitions = (apiBaseUrl: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const request = Axios.default.get(`${apiBaseUrl}/api/metrics/all`);
        request.then(result=>{
            resolve(result.data);
        });

        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });   
};

export const GetUserLayouts = (apiBaseUrl: string) : Promise<Layout[]> => {
    return new Promise((resolve, reject) => {

        const request = Axios.default.get(`${apiBaseUrl}/api/layout/all`);
        request.then(result=>{
            resolve(result.data as Layout[]);
        });
        request.catch((error: Axios.AxiosError<{additionalInfo:string}>)=>{
            if(error.response.status === StatusCodes.UNAUTHORIZED){
                reject({
                    reason: "login",
                    args: null
                } as FaultResult);
            }
            else {
                reject({
                    reason : error,
                    args: null
                });
            }
        });
    });
};

export const GetWidgetsConfiguration = (apiBaseUrl: string) : Promise<any> => {
     


    return Promise.reject("not implemented");
};

