import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import style from '../scss/modal.module.scss';
import { IsNotUndefinedOrNull, StringIsNullOrWhiteSpace, IsFunction } from '../misc/Helpers'
import { PubSubTopic } from '../misc/Constants';
import { Dictionary, Styles } from '../interfaces/Dictionary';
import classNames from 'classnames';


export enum ModalType {
    OkCancel,   // A dialog with a header, a message, a Ok button and a Cancel button. Set handleCancel to undefined if there should not be any Cancel button
    Empty       // An empty dialog, where a custom children component can be inserted. Standard Ok and Cancel button can be present, if handleOk or handleCancel are set
}

export interface ModalProps {
    modalType?: ModalType      // Type of the closeDialog
    headerText?: string,       // Header text of the dialog displayed on dialog types OnOk and OkCancel. Set to empty if no header is required
    messageText?: string,      // Message text of the dialog displayed on dialog types OnOk and OkCancel. Set to empty if no message is required
    okButtonText?: string,     // Button text for the Ok button. Default is Ok. Button will not be rendered if set to undefined
    cancelButtonText?: string, // Button text for the Cancel button. Default is Cancel. Button will not be rendered if set to undefined
    handleOk?: (event: React.MouseEvent | null) => void;                 // Handler for when the dialog is closed by clicking the Ok button. Button will not be rendered if set to undefined
    handleCancel?: () => void;              // Handler for when the dialog is closed by clicking the Cancel button, or the escape key (OkCancel dialog only). Button will not be rendered if set to undefined
    useOverlay?: boolean,      // Set to false to deactivate the background overlay for when the modal is displaying. Default is with overlay.
    width?: string              // Width of the modal dialog. Default is 500px
    children?: JSX.Element | JSX.Element[];    // Childrens of the modal, allowing to insert a custom form into the modal.
    style?: Styles;
    okDisabled?: boolean;
}

export const Modal = (props: ModalProps): JSX.Element => {

    const containerId = "modalpopup_container";

    const [container] = useState(() => {
        const div = document.createElement("div");
        div.id = containerId;
        return div;
    })

    useEffect(() => {

        document.body.prepend(container);

        const keyDownSub = PubSub.subscribe(PubSubTopic.EventsKeyDown, (msg: string, key: string) => {

            if (key === "Escape" && IsFunction(props.handleCancel)) {
                props.handleCancel();
            }
        });

        return () => {
            PubSub.unsubscribe(keyDownSub);
            document.body.removeChild(container);
        };
    }, []);

    const defaultStyle = {
        maxWidth: IsNotUndefinedOrNull(props.width) ? props.width : "500px"
    };

    const mergedStyle = { ...defaultStyle, ...props.style?.container };

    return (
        ReactDOM.createPortal(
            <React.Fragment>
                {
                    props.useOverlay !== false &&
                    <div className={style.modaloverlay} />
                }
                <div className={style.modalwrapper} aria-modal aria-hidden tabIndex={-1} role="dialog">
                    <div className={style.modal} style={mergedStyle}>

                        {
                            (!StringIsNullOrWhiteSpace(props.headerText) ||
                                IsFunction(props.handleCancel)) &&

                            <div className={style.header}>
                                {
                                    !StringIsNullOrWhiteSpace(props.headerText) &&
                                    <span>{props.headerText}</span>
                                }
                                {
                                    IsFunction(props.handleCancel) &&
                                    <button
                                        type="button"
                                        className={style.cancelbtn}
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={props.handleCancel}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                }

                            </div>
                        }


                        {
                            props.modalType !== ModalType.Empty &&
                            !StringIsNullOrWhiteSpace(props.messageText) &&

                            <div className={style.messageSection}>
                                <p>
                                    {props.messageText}
                                </p>
                            </div>
                        }

                        {props.children}

                        {
                            (IsFunction(props.handleOk) || IsFunction(props.handleCancel)) &&
                            <div className={style.buttonsSection}>
                                {
                                    IsFunction(props.handleCancel) && props.cancelButtonText !== "" &&
                                    <button
                                        type="button"
                                        className={classNames(style.modalButton, style.cancel)}
                                        onClick={props.handleCancel}>
                                        {props.cancelButtonText}
                                    </button>
                                }
                                {
                                    IsFunction(props.handleOk) &&
                                    <button
                                        disabled={props.okDisabled}
                                        type="button"
                                        className={classNames(style.modalButton, style.ok)}
                                        onClick={props.handleOk}>
                                        {props.okButtonText}
                                    </button>
                                }

                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>, container
        ));
}