import * as React from 'react';
import { DashboardUserVm } from '../models/DashboardUserVm';
import { Dictionary } from './Dictionary';

export interface DashboardContext {
    user:  DashboardUserVm;
    psapId?: string;
    localization: Dictionary<string>;
    psapFilter: string | null;
}

const AppContext = React.createContext<DashboardContext>({
    user: null, 
    localization: {},
    psapFilter: null
});

export { AppContext }