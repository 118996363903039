import { useContext, useEffect, useState, ReactElement } from 'react';
import { Logo, LogoStyle } from '../../components/Logo';
import { PubSubTopic } from '../../misc/Constants';
import { AppContext } from '../../interfaces/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { HeadingOptions } from '../../components/HeadingOptions/HeadingOptions';
import { app } from '../..';
import { IsUndefinedNullOrEmpty, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from '../../misc/Helpers';
import { Message } from '../../misc/Messages';
import { Layout, LayoutContext } from '../../models/DashboardLayout';
import { LayoutNameHandlingDialog } from '../../components/LayoutNameHandlingDialog/LayoutNameHandlingDialog';
import { ShareLayoutDialog } from '../../components/ShareLayoutDialog';
import { AdminSettingsDialog } from '../../components/AdminSettingsDialog';
import { AssociatedUser } from '../../models/AssociatedUser';
import { LayoutClickAction, LayoutDropdown, LayoutOption } from '../../components/LayoutDropdown/LayoutDropdown';
import { UserDropdownMenu } from '../../components/UserDropdownMenu/UserDropdownMenu';
import classNames from 'classnames';
import axios from 'axios';
import style from '../../scss/header.module.scss';
import { TooltipControl } from '../../components/ToolTipControl';


type DropdownMenuType = "none" | "dashboards" | "user";

export interface HeaderProps {
    layouts: Layout[];
    layoutContext: LayoutContext;
    hideLayoutControls?: boolean;
}

enum LayoutDialogView {
    None,
    Create,
    Rename,
    Duplicate
}

export const Header = (props: HeaderProps): ReactElement => {

    const { user, localization } = useContext(AppContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const defaultMenuId: DropdownMenuType = "none";
    const [menuId, setMenuId] = useState(defaultMenuId as DropdownMenuType);
    const [layoutDialogView, setLayoutDialogView] = useState(LayoutDialogView.None);
    const [editLayoutName, setEditLayoutName] = useState("");
    const [editLayoutId, setEditLayoutId] = useState("");
    const [showShareLayoutDialog, setShowShareLayoutDialog] = useState(false);
    const [showAdminSettings, setShowAdminSettings] = useState(false);
    const [associatedUser, setAssociatedUser] = useState(null as AssociatedUser[]);

    const activeLayout = props.layouts.find(l => l.id === user.account.settings.activeLayoutId);
    const isAllowedEdit = (user.isAllowedEdit ?? false) && (activeLayout?.ownerId === user?.account?.userId);

    const isAdmin = user.isAdmin ?? false;

    const onClickMenuItem = (target: DropdownMenuType, event): void => {
       if (menuId === target && isMenuOpen) {
            setIsMenuOpen(false);
        }
        else {
            setMenuId(target);
            setIsMenuOpen(true);
        }
    }

    const switchLayout = (layoutId: string): void => {
        if (!StringIsNullOrWhiteSpace(layoutId)) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.SelectLayout,
                data: {
                    layoutId: layoutId
                }
            });
        }
    };

    const getAssociatedUsers = async (userId: string): Promise<AssociatedUser[]> => {

        const { data }: { data: AssociatedUser[] } = await axios.get(`${app.apiBaseUrl}/api/user/getassociatedusers`);
        return data.filter(user => user.userId !== userId);
    };

    const displayShareLayoutDialog = async (): Promise<void> => {
        const result = await getAssociatedUsers(user.account.userId);
        setAssociatedUser(result);
        setShowShareLayoutDialog(true);
    };

    const displayAdminSettings = async (): Promise<void> => {
        setShowAdminSettings(true);
    };


    useEffect(() => {

        const ref1 = PubSub.subscribe(PubSubTopic.EventsBackgroundClick, () => {
            setIsMenuOpen(false);
        });

        const ref2 = PubSub.subscribe(PubSubTopic.EventsKeyDown, (msg: string, key: string) => {
            if (key === "Escape") {
                setIsMenuOpen(false);
            }
        });

        return () => {
            PubSub.unsubscribe(ref1);
            PubSub.unsubscribe(ref2);
        };
    }, []);

    const toggleDesignView = (): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.ToggleDashboardViewMode
        });
    };

    const layoutOptions: LayoutOption[] = props.layouts?.filter(x => x.context === props.layoutContext).map(l => {
        return {
            label: l.name,
            layoutId: l.id,
            viewOnly: l.ownerId !== user.account.userId
        } as LayoutOption
    });

    const onLayoutDropdownActionClick = (layoutId: string, action: LayoutClickAction): void => {
        switch (action) {
            case "create-layout":
                setLayoutDialogView(LayoutDialogView.Create);
                break;
            case "switch-layout":
                switchLayout(layoutId);
                break;
            case "delete":
                onDeleteLayoutAction(layoutId);
                break;
            case "rename":
                onRenameLayoutAction(layoutId);
                break;
            case "duplicate":
                onDuplicateLayoutAction(layoutId);
                break;
            case "share":
                onShareLayoutAction(layoutId);
                break;
            default:
                break;
        }

        setIsMenuOpen(false);
    };

    const onDeleteLayoutAction = (layoutId: string): void => {
        const current = props.layouts.find(p => p.id === layoutId);

        if (current && window.confirm(`Delete ${current.name}?`)) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.DeleteLayout,
                data: {
                    layoutId: current.id
                }
            });
        }
    };

    const onRenameLayoutAction = (layoutId: string): void => {
        const current = props.layouts.find(p => p.id === layoutId);
        if (current) {
            setLayoutDialogView(LayoutDialogView.Rename);
            setEditLayoutName(current.name);
            setEditLayoutId(current.id);
        }
    };

    const onDuplicateLayoutAction = (layoutId: string): void => {
        const current = props.layouts.find(p => p.id === layoutId);
        if (current) {
            setLayoutDialogView(LayoutDialogView.Duplicate);
            setEditLayoutName(current.name);
            setEditLayoutId(current.id);
        }
    };

    const onShareLayoutAction = (layoutId: string): void => {
        const current = props.layouts.find(p => p.id === layoutId);
        if (current) {
            setEditLayoutName(current.name);
            setEditLayoutId(current.id);
            displayShareLayoutDialog();
        }
    };

    const getHeaderText = () => {
        const psaps = app.store.state.psaps;
        return IsUndefinedNullOrEmpty(psaps) ? "No PSAPs" : psaps.length > 1 ? "Regional View" : psaps[0].psapName;
    }

    const getModeIcon = () => {
        return app.store.state.isDesignMode ? <FontAwesomeIcon className={style.headerIcon} icon={"eye"} /> : <FontAwesomeIcon className={style.headerIcon} icon={faPencil} />
    }

    const saveAdminSettings = async (adminSettings): Promise<void> => {
        await axios.post(`${app.apiBaseUrl}/api/configuration/adminsettings`, adminSettings);

        app.store.state.adminSettings = adminSettings;
        PubSub.publish(PubSubTopic.Action, {
            id: Message.EmitStateChange
        });
        setShowAdminSettings(false);
    }

    return (
        <header>



            <div className={style.container}>

                <div className={classNames(style.logo, style.section)}>
                    <div>
                        <Logo width={115} style={LogoStyle.Light} />
                        <span id="ng911_label">NG9-1-1 Dashboard</span>
                    </div>
                </div>

                <div className={classNames(style.dashboards, style.section, style.separator)}>
                    {
                        !IsUndefinedOrNull(activeLayout) &&
                        <div className={style.relative}>
                            <HeadingOptions
                                onClick={(e) => onClickMenuItem("dashboards", e)}
                                isOpen={isMenuOpen && menuId === "dashboards"} label={activeLayout.name}
                                showDropDownIcon={true} />
                            {
                                isMenuOpen &&
                                menuId === "dashboards" &&
                                <div className={style.dashboardoptions}>
                                    <LayoutDropdown
                                        label="My layouts"
                                        options={layoutOptions}
                                        onAction={onLayoutDropdownActionClick}
                                    />
                                </div>
                            }
                        </div>
                    }

                </div>
                {
                    isAllowedEdit &&
                    props.hideLayoutControls !== true &&
                    <TooltipControl placement="left" title={app.store.state.isDesignMode ?
                        "Preview Mode" :
                        "Edit Mode"
                    }>
                        <div className={classNames(style.designMode, style.section, style.separator, style.headerIcon)} onClick={toggleDesignView}>
                            {getModeIcon()}
                        </div>
                    </TooltipControl>
                }
                {
                    props.hideLayoutControls !== true &&
                    <div className={classNames(style.region, style.section, style.separator, style.tooltip)}>

                        <div>
                            <FontAwesomeIcon className={style.headerIcon} icon={"location-dot"} />
                            <TooltipControl placement="bottom" title={getHeaderText()}>
                                <span>{getHeaderText()}</span>
                            </TooltipControl>
                        </div>
                    </div>
                }
                <div className={classNames(style.user, style.section, style.relative)}>
                    <div className={style.relative}>
                        <HeadingOptions
                            icon={"user"}
                            onClick={(e) => onClickMenuItem("user", e)}
                            isOpen={isMenuOpen && menuId === "user"}
                        />
                        {
                            isMenuOpen &&
                            menuId === "user" &&
                            <div className={style.useroptions}>
                                <UserDropdownMenu
                                    isAdmin={isAdmin}
                                    displayAdminOption={displayAdminSettings}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>

            {
                layoutDialogView === LayoutDialogView.Create &&
                <LayoutNameHandlingDialog
                    title="Create new layout"
                    maxLayoutTitleChars={50}
                    minLayoutTitleChars={3}
                    onCancel={() => setLayoutDialogView(LayoutDialogView.None)}
                    onAction={(name: string) => {

                        PubSub.publish(PubSubTopic.Action, {
                            id: Message.CreateLayout,
                            data: {
                                layoutName: name.trim(),
                                context: props.layoutContext,
                                activate: true
                            }
                        });

                        setLayoutDialogView(LayoutDialogView.None)

                    }} />
            }

            {
                layoutDialogView === LayoutDialogView.Rename &&
                <LayoutNameHandlingDialog
                    okButtonText="Rename"
                    title={`Rename layout : ${editLayoutName}`}
                    value={editLayoutName}
                    maxLayoutTitleChars={50}
                    minLayoutTitleChars={3}
                    onCancel={() => {
                        setLayoutDialogView(LayoutDialogView.None)
                        setEditLayoutId("");
                    }
                    }
                    onAction={(name: string) => {
                        if (name !== editLayoutName) {
                            PubSub.publish(PubSubTopic.Action, {
                                id: Message.RenameLayout,
                                data: {
                                    layoutId: editLayoutId,
                                    layoutName: name.trim(),
                                    context: props.layoutContext
                                }
                            });
                        }
                        setEditLayoutId("");
                        setEditLayoutName("");
                        setLayoutDialogView(LayoutDialogView.None)
                    }} />
            }

            {
                layoutDialogView === LayoutDialogView.Duplicate &&
                <LayoutNameHandlingDialog
                    inputValidator={(value: string) => value === editLayoutName ? "You have to rename the layout" : null}
                    okButtonText="Duplicate"
                    title={`Duplicate: ${editLayoutName}`}
                    value={`${editLayoutName} - copy`}
                    maxLayoutTitleChars={50}
                    minLayoutTitleChars={3}
                    onCancel={() => {
                        setLayoutDialogView(LayoutDialogView.None)
                        setEditLayoutId("");
                    }
                    }
                    onAction={(name: string) => {
                        if (name !== editLayoutName) {
                            PubSub.publish(PubSubTopic.Action, {
                                id: Message.DuplicateLayout,
                                data: {
                                    layoutId: editLayoutId,
                                    layoutName: name.trim(),
                                    context: props.layoutContext,
                                    select: true
                                }
                            });
                        }
                        setEditLayoutId("");
                        setEditLayoutName("");
                        setLayoutDialogView(LayoutDialogView.None)
                    }} />
            }

            {
                showShareLayoutDialog &&
                <ShareLayoutDialog
                    associatedUser={associatedUser}
                    okButtonText='Select'
                    layoutId={editLayoutId}
                    title={`Share layout : ${editLayoutName}`}
                    onCancel={() => {
                        setShowShareLayoutDialog(false);
                        setEditLayoutId("");
                    }
                    }
                />
            }
            {
                showAdminSettings &&
                <AdminSettingsDialog
                    options={app.store.state.adminSettings}
                    onCancel={() => {
                        setShowAdminSettings(false);
                    }
                    }
                    onSave={saveAdminSettings}
                />
            }

        </header>
    );
}