import style from '../scss/loadingscreen.module.scss';

export interface LoadingScreenProps {
    message: string;
}
export const LoadingScreen = (props: LoadingScreenProps) => {

    return (
        <div className={style.container}>
            <h3>{props.message}</h3>
            <img src={"/loading.gif"} />
        </div>);
}