import { DataAdapterProps } from "./DataAdapter";
import { SortingContainerProps } from "../components/SortingContainer/SortingContainer";
import { SortingContainerDataTabName, SortingContainerDataTabProps } from "../components/WidgetOptions/SortingContainer/SortingContainerDataTab";
import { SortingContainerDesignTabName, SortingContainerDesignTabProps } from "../components/WidgetOptions/SortingContainer/SortingContainerDesignTab";
import { SortingContainerMainTabName, SortingContainerMainTabProps } from "../components/WidgetOptions/SortingContainer/SortingContainerMainTab";
import { SortingContainerSortingTabName, SortingContainerSortingTabProps } from "../components/WidgetOptions/SortingContainer/SortingContainerSortingTab";
import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { defaultTitleFontIndex, defaultTitleFontSize, FontOptions } from "../misc/Constants";
import { IsNotUndefinedOrNull } from "../misc/Helpers";
import { UserPsaps } from "../models/UserPsaps";


export class SortingContainerConfigAdapter extends WidgetConfigAdapter<SortingContainerProps> {

    main: SortingContainerMainTabProps;
    data: SortingContainerDataTabProps;
    sorting: SortingContainerSortingTabProps;
    design: SortingContainerDesignTabProps;    

    psaps: UserPsaps[];


    constructor(widgetId: string, config: object, psaps: UserPsaps[]) {
        super();
        this.main = config[SortingContainerMainTabName];
        this.data = config[SortingContainerDataTabName];
        this.design = config[SortingContainerDesignTabName];
        this.sorting = config[SortingContainerSortingTabName];
        this.psaps = psaps;
    }

    getDataConfiguration = (): DataAdapterProps => {        
        return null;
    };


    getWidgetConfiguration = (): SortingContainerProps => {

        const TitleFont = FontOptions.find(font => font.value === this.design?.titleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label;
        const SubtitleFont = FontOptions.find(font => font.value === this.design?.subtitleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label;

        const widgetStyle = {
            // Sorting container main title
            title : {
                fontSize: this.design?.titleFontSize ?? defaultTitleFontSize,
                color: this.design?.titleFontColor ?? "#000",
                fontFamily: TitleFont
            },
            // Generic Container title, for individual psaps
            containerTitle: {
                fontSize: this.design?.subtitleFontSize ?? defaultTitleFontSize,
                color: this.design?.subtitleFontColor ?? "#000",
                fontFamily: SubtitleFont
            }
        }
        const selectedPsapIds = Array.isArray(this.data?.selectedPsapIds) ? this.data?.selectedPsapIds : [];

        let selectedPsaps = this.psaps.filter((p: UserPsaps) => selectedPsapIds.some(x => x === p.nenaIdentifier)).map(g => g);
        let subtitles = this.main?.subtitleConfig?.map((item) => item.name)
        
        // If an psap order was provided, apply it to selected psap and associated subtitles
        if (IsNotUndefinedOrNull(this.sorting) && IsNotUndefinedOrNull(this.sorting.orderedPsaps) && this.sorting.orderedPsaps.length > 0) {
            selectedPsaps = this.sorting.orderedPsaps.map(i => {return this.psaps.find(p => i.id === p.nenaIdentifier)}).filter(y => IsNotUndefinedOrNull(y))
            subtitles = selectedPsaps.map(i => {
                let val = this.main.subtitleConfig?.find( p=> i.nenaIdentifier === p.id)?.name
                if (IsNotUndefinedOrNull(val))
                    return val;
                else 
                    return i.psapName})
        }
                
        const config : SortingContainerProps = {
            title : this.main?.title,
            subtitles : subtitles,
            style: widgetStyle,
            selectedPsaps: selectedPsaps
        };

        return config;
    }
}