import { useEffect, useRef, useState } from "react";
import { SizeContext } from '../interfaces/SizeContext';

const SizeContextProvider = (props) => {

    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);

    const ref = useRef();

    useEffect(() => {

        function callback(entries: ResizeObserverEntry[]) {
            if (entries.length > 0) {
                const entry = entries[0];
                if (entry.borderBoxSize.length > 0) {
                    const box = entry.borderBoxSize[0];
                    setHeight(box.blockSize);
                    setWidth(box.inlineSize);
                }
            }

            const element = ref?.current as HTMLElement;
            const dashboardRect = element?.getBoundingClientRect();

            if (dashboardRect) {
                if (dashboardRect.left !== left || dashboardRect.top !== top) {
                    if (dashboardRect.width > 0) {
                        setLeft(dashboardRect.left)
                        setTop(dashboardRect.top)
                    }
                }
            }
        }

        const obs = new ResizeObserver(callback);
        obs.observe(ref.current);

        return () => {
            if (ref.current) {
                obs.unobserve(ref.current);
            }
        };

    }, []);

    return <SizeContext.Provider value={{ height: height, width: width, left: left, top: top }}>
        <div className={props.className} style={{ width: "100%", height: "inherit" }} ref={ref}>{props.children}</div>
    </SizeContext.Provider>;
}

export { SizeContextProvider }



