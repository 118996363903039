import style from '../scss/widget.module.scss';

interface IProps {
    description: string,
}

export const EmptyWidget = (props: IProps): JSX.Element => {

    return (
        <div className={style.widget}>
            <div className={style.empty}>
                <label>{'Empty Widget'}</label>
                <span>{String(props.description)}</span>
            </div>
        </div>
    );
}
    