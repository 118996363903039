import { useState } from 'react';
import { Modal } from './Modal';
import shared from "../components/WidgetOptions/sharedstyle.module.scss";
import style from "../scss/adminconfigdialog.module.scss";
import Select from "react-select";
import { OptionType } from '../models/OptionType';
import { ReactSelectStyles } from '../misc/Constants';
import { TooltipControl } from './ToolTipControl';

export interface AdminSettingsDialogProps {
    onCancel: Function,
    onSave: Function,
    options: AdminSettings
}

export interface AdminSettings {
    mapPlottingEnabled: boolean,
    bandwidthCallMultiplicatorInKbps: number
}

export const AdminSettingsDialog = (props: AdminSettingsDialogProps): JSX.Element => {

    const [AdminSettings, setAdminSettings] = useState(props.options);

    const onPlotOptionChange = (options: OptionType): void => {
        setAdminSettings({ ...AdminSettings, mapPlottingEnabled: (options.value === 1) })
    };

    const onBandwidthMultiplicatorChange = (newValue: number): void => {
        setAdminSettings({ ...AdminSettings, bandwidthCallMultiplicatorInKbps: newValue })
    };

    let plotOptions = [{ label: "Map call plotting enabled", value: 1 }, { label: "Map call plotting disabled", value: 0 }] as OptionType[];
    let plotValue = AdminSettings.mapPlottingEnabled === true ? plotOptions[0] : plotOptions[1]

    return (
        <Modal
            handleCancel={() => props.onCancel()}
            handleOk={() => props.onSave(AdminSettings)}
            headerText={"Admin Settings"}
            cancelButtonText={"Cancel"}
            okButtonText={"Save"}>
            {
                <div className={style.adminConfigDialog}>
                    <div className={shared.row}>
                        <div className={shared.heading}>
                            <TooltipControl children={undefined} label="Map call plotting" placement="right" showInfoIcon={true} title={"Active or deactive call plotting on the map widget"} />
                        </div>

                        <Select
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            value={plotValue}
                            options={plotOptions}
                            onChange={onPlotOptionChange}
                            styles={ReactSelectStyles}
                            placeholder={'Select...'}
                        />
                    </div>
                    <div className={shared.row}>
                        <div className={shared.heading}>
                            <TooltipControl children={undefined} label="Bandwidth usage per call (kbps)" placement="right" showInfoIcon={true} title={"Sets the bandwidth usage in kbps for a single call"} />
                        </div>

                        <input
                            type="number"
                            className={style.bandwidthMultiplicatorInput}
                            maxLength={20}
                            onChange={e => onBandwidthMultiplicatorChange(+e.target.value)}
                            value={AdminSettings.bandwidthCallMultiplicatorInKbps}
                        />
                    </div>
                </div>
            }
        </Modal >
    )
}