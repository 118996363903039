import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import style from './style.module.scss';

export interface SingleMetricThresholdConfigProps {
    min: number;
    max: number;
    useMaxThreshold: Boolean;
    maxThreshold: number;
    useMinThreshold: Boolean;
    minThreshold: number;
    metricName: String;
    onCancel: () => void;
    onThresholdMinValueChanged?: (value: number) => void;
    onThresholdMaxValueChanged?: (value: number) => void;
    onSave: (minValue: number, maxValue: number) => void;
}

export const SingleMetricThresholdConfig = (props: SingleMetricThresholdConfigProps): JSX.Element => {

    const [minValue] = useState(typeof props.min !== 'undefined' ? props.min : 0);
    const [maxValue] = useState(typeof props.max !== 'undefined' ? props.max : 100);
    const [minThreshold, setMinThreshold] = useState(typeof props.minThreshold !== 'undefined' ? props.minThreshold : 0);
    const [maxThreshold, setMaxThreshold] = useState(typeof props.maxThreshold !== 'undefined' ? props.maxThreshold : 100);
    const [minThresholdValid, setMinThresholdValid] = useState(true);
    const [maxThresholdValid, setMaxThresholdValid] = useState(true);

    const onChangeMinThreshold = (newValue: number): void => {
        if (newValue < 0) newValue = 0;

        if (props.useMaxThreshold) {
            if (newValue >= maxThreshold) {
                setMinThresholdValid(false);
                setMaxThresholdValid(false);
            }
            else {
                setMinThresholdValid(true);
                setMaxThresholdValid(true);
            }
        }

        setMinThreshold(newValue);

        typeof props.onThresholdMinValueChanged === "function" && props.onThresholdMinValueChanged(newValue);
    };

    const onChangeMaxThreshold = (newValue: number): void => {
        if (newValue < 0) newValue *= -1;

        if (props.useMinThreshold) {
            if (newValue <= minThreshold) {
                setMaxThresholdValid(false);
                setMinThresholdValid(false);
            }
            else {
                setMaxThresholdValid(true);
                setMinThresholdValid(true);
            }
        }

        setMaxThreshold(newValue);

        typeof props.onThresholdMaxValueChanged === "function" && props.onThresholdMaxValueChanged(newValue);
    };

    const onSave = (): void => {
        // Save the data
        typeof props.onSave === "function" && props.onSave(minThreshold, maxThreshold);
    };

    return (
        <div className={style.thresholdConfigContainer}>
            <div className={style.header}>
                <div className={style.title}>
                    Set Threshold Values
                </div>
                <div className={style.buttons}>
                    <button className={classNames(style.button, style.cancel)} onClick={props.onCancel}>Cancel</button>
                    <button disabled={!minThresholdValid || !maxThresholdValid} className={classNames(style.button, style.save)} onClick={onSave}>Save</button>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.widthLong}>Metric</div>
                    {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                    {props.useMinThreshold && <div className={style.widthShort}>Min Threshold</div>}
                    {props.useMaxThreshold && <div className={style.widthShort}>Max Threshold</div>}
                </div>
                <div className={style.data}>
                    <div className={style.widthLong}>{props.metricName}</div>
                    {(!props.useMinThreshold || !props.useMaxThreshold) && <div className={style.widthShort}></div>}
                    {
                        props.useMinThreshold &&
                        <div className={style.widthShort}>
                            <input disabled={!props.useMinThreshold}
                                className={classNames("form-control", !minThresholdValid ? "warn" : "")}
                                min={minValue}
                                onBlur={() => { }}
                                type="number"
                                value={minThreshold}
                                onChange={(e) => onChangeMinThreshold(+e.target.value)} />
                        </div>
                    }
                    {
                        props.useMaxThreshold &&
                        <div className={style.widthShort}>
                            <input disabled={!props.useMaxThreshold}
                                className={classNames("form-control", !maxThresholdValid ? "warn" : "")}
                                min={minValue}
                                onBlur={() => { }}
                                type="number"
                                value={maxThreshold}
                                onChange={(e) => onChangeMaxThreshold(+e.target.value)} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
} 