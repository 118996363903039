
import { ReactElement } from 'react';
import { app } from '..';
import { ErrorPageContent } from './partials/ErrorPageContent/ErrorPageContent';
import { IsNotUndefinedOrNull } from '../misc/Helpers';

export enum ErrorType {
    userNotFound,
    invalidUser
};

const ApiServerFault = (props): JSX.Element => {
    return <div>Api not available</div>
}
export { ApiServerFault }

export interface ErrorPageProps {
    ecatsHost?: string;
    errorType: ErrorType;
    userName?: string;
}

const GetAppropraiteContents = (errorType: ErrorType, redirectTo: string, userName?: string): ReactElement => {
    switch (errorType) {
        case ErrorType.userNotFound:
            const msg = IsNotUndefinedOrNull(userName) ? `User '${userName}' is not returned from ECaTS.` : 'No such user is returned from ECaTS.';
            return (
                <ErrorPageContent 
                    heading='User not found' 
                    message={msg} 
                    backButtonText={redirectTo} 
                    loginInstructions='Re-Login' />
            );
        case ErrorType.invalidUser:
            const invalidUserMsg = IsNotUndefinedOrNull(userName) ? `User '${userName}' is incorrectly configured in ECaTS.` : 'User is incorrectly configured in ECaTS.';
            return (
                <ErrorPageContent 
                    heading='Invalid User' 
                    message={invalidUserMsg} 
                    backButtonText={redirectTo} 
                    loginInstructions='Re-Login' />
            );
    }
}

export const ErrorPage = (props: ErrorPageProps): ReactElement => {
    const redirectTo = window.location.hostname === "localhost" ? `${app.apiBaseUrl}/auth/signout`: props.ecatsHost;
    return (
        GetAppropraiteContents(props.errorType, redirectTo, props.userName)
    );
}