import { FontOptions } from "../misc/Constants"

export const FontPageTest = (): JSX.Element => {


    const common: React.CSSProperties = {
        fontSize: "48px"
    }

    const roboto: React.CSSProperties = {
        fontFamily: FontOptions.find(p => p.value === 0).label
    };


    const openSans: React.CSSProperties = {
        fontFamily: FontOptions.find(p => p.value === 1).label
    };

    const poppins: React.CSSProperties = {
        fontFamily: FontOptions.find(p => p.value === 2).label
    };
    const arial: React.CSSProperties = {
        fontFamily: FontOptions.find(p => p.value === 3).label
    };
 
    return (
        <div>
            <p style={{ ...roboto, ...common }}>This is Roboto font</p>
            <p style={{ ...openSans, ...common }}>This is Open Sans font</p>
            <p style={{ ...poppins, ...common }}>This is Poppins font</p>
            <p style={{ ...arial, ...common }}>This is Arial font</p>
        </div>
    )
}