import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { IsFunction, IsUndefinedOrNull } from "../../misc/Helpers";
import { SortableItem } from "./SortableItem";

export interface SortableAreaProps {
    selectedValues: string[];
    onRemove: (item: string) => void;
    onSaveSort: (sorted: string[]) => void;
}

export const SortableArea = (props: SortableAreaProps) => {

    const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 1 } });
    const sensors = useSensors(mouseSensor);

    const [selectedValues, setSelectedValues] = useState(IsUndefinedOrNull(props.selectedValues) ? [] : props.selectedValues);
    let sortedArray: string[] = [];

    const onHandleDragEnd = (event): void => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setSelectedValues((items) => {
                const activeIndex = items.indexOf(active.id);
                const overIndex = items.indexOf(over.id);

                sortedArray = arrayMove(items, activeIndex, overIndex);
                return sortedArray;
            })
            IsFunction(props.onSaveSort) && props.onSaveSort(sortedArray);
        }
    };

    useEffect(() => {
        setSelectedValues(props.selectedValues);
    }, [props.selectedValues]);

    const onRemove = (item: string): void => {
        setSelectedValues((items) => {
            return items.filter(x => x !== item);
        });

        IsFunction(props.onRemove) && props.onRemove(item);
    }

    return (
        <DndContext
            autoScroll={false}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onHandleDragEnd}>
            <SortableContext
                items={selectedValues}
                strategy={verticalListSortingStrategy}>
                {selectedValues.map((item, index) =>
                    <SortableItem key={item} id={item} index={index} onRemove={onRemove} />)
                }
            </SortableContext>

        </DndContext>
    )
}