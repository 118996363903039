
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from "classnames";
import { IsFunction, IsNotUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../misc/Helpers";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import style from './style.module.scss';

export enum ContentType {
    MenuItem,
    ContentItem
};

export interface HeadingOptionsProps {
    label?: string;
    children?: any;

    onClick?: (event: React.MouseEvent) => void;
    isOpen?: boolean;
    icon?: IconProp;
    showDropDownIcon?: boolean;
}

const HeadingOptions = (props: HeadingOptionsProps) => {

    let css = props.isOpen === true ? classNames("pointer", "fa-rotate-180") : "pointer";

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        IsFunction(props.onClick) && props.onClick(e);
    };


    const label = StringIsNullOrWhiteSpace(props.label) ? "" : props.label;

    return (
        <div className={style.option}>
            {
                !IsNotUndefinedOrNull(props.children) &&
                props.children
            }

            <span onClick={handleClick}>
                {label}
                {
                    props.icon &&
                    <FontAwesomeIcon
                        icon={props.icon}
                        className={classNames("pointer", style.noMargin)} />
                }
                {
                    props?.showDropDownIcon &&
                    < FontAwesomeIcon
                        icon={"angle-down"}
                        className={css} />
                }
            </span>

        </div>
    );

};
export { HeadingOptions };

export interface MenuButton {
    action: string;
    title?: string;
    icon: string;
    style?: React.CSSProperties;
}



export type MenuLink = {
    disabled?: boolean;
    text: string;
    href?: string;
    isNative?: boolean;
    isActive?: boolean;
    buttons?: MenuButton[];
    itemId?: string;
    separator?: boolean;
    typeofcontent?: ContentType;
}