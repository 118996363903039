import { IStore, MessageArg } from "../interfaces/AppCore";
import { EventRouter } from "../services/EventRouter";
import { SignalRHubHandler } from "../handlers/SignalRHubHandler";
import { ActionsHandler } from "../handlers/ActionsHandler";
import { PubSubTopic, WidgetName } from "../misc/Constants";
import { LayoutHandler } from "../handlers/LayoutHandler";
import { IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { Metric } from "../models/Metric";
import PubSub from 'pubsub-js';
import { MainStoreState } from "../interfaces/MainStoreState";
import { WidgetManifest } from "../interfaces/WidgetManifest";
import { WebClientConfig } from "../interfaces/Configuration";
import { Layout } from "../models/DashboardLayout";
import { Message } from "../misc/Messages";

import singleMetricIcon from '../icons/single-metric.svg'
import multiMetricIcon from '../icons/multi-metric.svg'
import containersIcon from '../icons/containers.svg'
import mapIcon from '../icons/map.svg'
import { AdminSettings } from "../components/AdminSettingsDialog";
import { StateHandler } from "../handlers/StateHandler";


export interface AppStoreOptions {

}

class AppStore implements IStore {


    hubConfig: WebClientConfig;

    state: MainStoreState;

    constructor(opt: AppStoreOptions) {

        this.state = {
            user: {
                account: {
                    userId: '',
                    userName: '',
                    emailAddress: ''
                },
                tenantId: '',
                timeZone: {
                    ecatsOffset: '',
                    windows: '',
                    standard: '',
                    abbreviation: '',
                    identifier: ''
                }
            },
            adminSettings: {mapPlottingEnabled : true, bandwidthCallMultiplicatorInKbps : 270},
            psapView: '',
            layouts: [],
            psaps: [],
            draggingWidget: { id: '', name: WidgetName.Empty },
            widgets: [],
            layoutsWidgets: {},
            widgetsMenuExpanded : true,
            widgetTabs: [
                {
                    id: "a",
                    label: "Single Metric",
                    helpText: "Display a single metric",
                    components: [],
                    expanded: false,
                    image: singleMetricIcon
                },
                {
                    id: "b",
                    label: "Multiple Metrics",
                    helpText: "Display multiple metrics",
                    components: [],
                    expanded: false,
                    image: multiMetricIcon
                },
                {
                    id: "c",
                    label: "Containers",
                    helpText: "Display a list of available containers",
                    components: [],
                    expanded: false,
                    image: containersIcon
                },
                {
                    id: "d",
                    label: "Map",
                    helpText: "Display a map with PSAP alerts",
                    components: [],
                    expanded: false,
                    image: mapIcon
                }
            ],
            metrics: [],

            sortOrder: null,
            isDesignMode: false,
            notifications: [],
            psapFilter: null,

            mapWidgetInEdit: false
        };

        this.hubConfig = {
            signalRHubAddress: '',
            signalRToken: ''
        };

        let eventRouter = new EventRouter<MainStoreState>({
            isDebug: false
        });


        let actionsHandler = new ActionsHandler();
        let signalRHandler = new SignalRHubHandler();
        let layoutHandler = new LayoutHandler();
        let stateHandler = new StateHandler();

        eventRouter.addHandler(actionsHandler);
        eventRouter.addHandler(signalRHandler);
        eventRouter.addHandler(layoutHandler);
        eventRouter.addHandler(stateHandler);

        PubSub.subscribe(PubSubTopic.Action, async (msg: PubSubJS.Message, data: MessageArg) => {

            let result = eventRouter.route(data, this.state);

            if (result instanceof Promise) {
                await result && this.emitChanges();
            }

            else if (result === true) {
                this.emitChanges();
            }
        });
    }


    emitChanges(): void {
        PubSub.publish(PubSubTopic.Changes, this.state);
    }

    
    loadGlobalConfig(options: AdminSettings): void {
        this.state.adminSettings = options;
    };

    loadWidgetManifest(manifest: WidgetManifest[]): void {

        this.state.widgets = manifest;

        this.state.widgetTabs.forEach(w => {
            if (w.label === "Single Metric") {
                w.components = manifest.filter(p => p.type === "single-metric");
            }
            else if (w.label === "Multiple Metrics") {
                w.components = manifest.filter(p => p.type === "multi-metric");
            }
            else if (w.label === "Map") {
                w.components = manifest.filter(p => p.type === "map");
            }
            else if (w.label === "Containers") {
                w.components = manifest.filter(p => p.type === "container" || p.type === "sorting-container");
            }
        });
    };

    loadMetricDefinitions(manifest: Metric[]): void {
        if (IsUndefinedOrNull(manifest))
            return;

        this.state.metrics = manifest;
    };

    getLayoutById(layoutId: string): Layout | null {
        if (StringIsNullOrWhiteSpace(layoutId))
            return null;

        const layouts = this.state.layouts.filter(p => p.id === layoutId);
        if (layouts.length === 0)
            return null;

        return layouts[0];
    };

    run(command: string): void {
        switch (command) {
            case "connect":
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.ConnectToHub
                })
                break;

            case "disconnect":
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.DisconnectFromHub
                })
                break;
        }
    }
    
    logStreams(activate : boolean = true) : string {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.StreamLogging,
            data: activate
        });
        return "Command accepted";
    }
};

export { AppStore }