import { ReactElement, useRef, useState } from "react"
import style from './style.module.scss';
import styleLayout from '../../scss/layout.module.scss';
import { ContainerOverride, GenericContainer } from "../GenericContainer";
import { WidgetFactory } from "../../services/WidgetFactory";
import { WidgetConfiguration } from "../../models/WidgetConfiguration";
import { Layout } from "../../models/DashboardLayout";
import { ResizableBox, ResizeCallbackData } from "react-resizable";
import { IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, StringIsNullOrWhiteSpace } from "../../misc/Helpers";
import { ContainerTitle } from "../ContainerTitle";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/Messages";
import { UserPsaps } from "../../models/UserPsaps";

export interface SortingContainerProps {
    widgetId?: string;
    parentLayoutId?: string;
    layoutWidgets?: WidgetConfiguration[];
    isDesignMode?: boolean;
    factory?: WidgetFactory;
    layout?: Layout;
    title: string;
    style?: any;
    selectedPsaps?: UserPsaps[];
    subtitles: string[];
    masterContainerHeight?: number;
}

export const SortingContainer = (props: SortingContainerProps): ReactElement => {

    const [height, setHeight] = useState(IsNotUndefinedOrNull(props.masterContainerHeight) ? props.masterContainerHeight : 250);
    const updateTimeoutRef = useRef(0);

    function saveHeight(height: number) {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.UpdateMasterContainerHeight,
            data: {
                configurationId: props.widgetId,
                masterContainerHeight: height,
                layoutId: props.parentLayoutId
            }
        });
    }

    const onResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
        setHeight(data.size.height);

        // Debounce 1 second to avoid saving height every single time it is changed while resizing.
        clearTimeout(updateTimeoutRef.current);
        updateTimeoutRef.current = (window.setTimeout(() => {
            return saveHeight(data.size.height);
        }, 1000));
    };

    function onMasterContainerLayoutChange(layout: any) {
        // On master container layout change, update state because layout from props is now out of date.
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RefreshLayoutState,
            data: {
                layoutId: props.layout.id,
                layoutData: layout
            }
        });
    }

    return (
        <div className={style.container}>
            <div className={styleLayout.sortingContainer}>
                {
                    !StringIsNullOrWhiteSpace(props.title) && <ContainerTitle className={styleLayout.sortingContainerTitle} title={props.title} style={props.style?.title} />
                }

                {
                    props.isDesignMode &&
                    <ResizableBox
                        className={style.masterContainer}
                        height={height}
                        width={Infinity}    // This allows to specify the mandatory prop and override it with 100% in the CSS
                        axis={"y"}
                        onResize={onResize}
                        handleSize={[500, 500]} // This is not working
                        resizeHandles={['s']}
                        minConstraints={[10, 50]}
                    >
                        <GenericContainer
                            isSortingContainer={true}
                            layoutWidgets={props.layoutWidgets}
                            layout={props.layout}
                            parentLayoutId={props.parentLayoutId}
                            isDesignMode={props.isDesignMode}
                            factory={props.factory}
                            title={""} // Removed the Master Container title until we come up with a better name
                            style={props.style}
                            onLayoutChange={onMasterContainerLayoutChange}
                        />
                    </ResizableBox>
                }
                {
                    !props.isDesignMode &&
                    <div className={style.replicas}>
                        {
                            props.selectedPsaps.map((psap, i) => {
                                let containerOverride: ContainerOverride = {
                                    psap: psap
                                }
                                return <div className={style.replica} key={psap.nenaIdentifier} style={{ height: height }}>
                                    <GenericContainer
                                        containerOverride={containerOverride}
                                        layoutWidgets={props.layoutWidgets}
                                        layout={props.layout}
                                        parentLayoutId={props.parentLayoutId}
                                        isDesignMode={props.isDesignMode}
                                        factory={props.factory}
                                        title={props.subtitles?.[i]?.length > 0 ? props.subtitles?.[i] : psap.psapName}
                                        style={props.style}
                                    />
                                </div>
                            })
                        }
                    </div>
                }
                {
                    props.isDesignMode &&
                    <div className={style.replicaWarning}><strong>Container replicas not rendered in design mode.</strong></div>
                }
                {
                    !props.isDesignMode && IsUndefinedNullOrEmpty(props.selectedPsaps) &&
                    <div className={style.replicaWarning}><strong>No PSAPs selected</strong></div>
                }
            </div>
        </div>
    );
}
