import React, { useState } from "react";
import shared from '../sharedstyle.module.scss';
import { ReactMultiSelectStyles, ReactMultiSelectStylesWarn } from '../../../misc/Constants';

import style from './style.module.scss';
import { UserPsaps } from "../../../models/UserPsaps";
import { OptionType } from "../../../models/OptionType";
import { Metric } from "../../../models/Metric";
import { TabComponent } from "../Tab";
import { TooltipControl } from "../../ToolTipControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapThresholdConfig, MapPsapThresholdValues, MapThresholdValues } from "./MapThresholdConfig";
import { SortableSelect, SortableType } from "../../SortableComponents/SortableSelect";
import { GetSortOrder, SortAscending, SortDescending, SortOrderEmpty } from "../../SortableComponents/SortFunctions";
import { SortConstants } from "../../SortableComponents/SortConstants";
import { LabelControlWithToolTip } from "../../Labels/LabelControlWithToolTip";

export interface MapDataTabProps extends TabComponent {
    selectedPsapIds?: string[];
    selectedMetrics?: string[];

    thresholdValues?: MapPsapThresholdValues[];

    psaps: UserPsaps[];
    metrics: Metric[];

    sortOrder: { [key: string]: string };
}

export const MapDataTabName = "MapDataTab";

export const MapDataTab = (props: MapDataTabProps): JSX.Element => {
    const [showMapThresholdConfig, setShowThresholdConfig] = useState(false);

    const psapOptions = props.psaps.map(p => {
        return { label: p.psapName, value: p.nenaIdentifier } as OptionType
    });

    const p = props.selectedPsapIds;

    let selectedPSAPs: OptionType[] = [];

    if (Array.isArray(p) && p.length > 0) {
        p.forEach((psap) => {
            const option = psapOptions.find(x => x.value === psap);
            if (typeof option !== 'undefined') selectedPSAPs.push(option);
        })
    }

    const [psaps, setPSAPs] = useState(selectedPSAPs);

    const metricOptions = props.metrics.filter(m => m.metricGroup?.toLocaleLowerCase() === "map").map(m => {
        return { label: m.displayNameKey, value: m.id } as OptionType
    });

    const m = props.selectedMetrics;

    let selectedMetrics: OptionType[] = [];

    if (Array.isArray(m) && m.length > 0) {
        m.forEach((metric) => {
            const option = metricOptions.find(x => x.value === metric);
            if (typeof option !== 'undefined') selectedMetrics.push(option);
        })
    }

    const [metrics, setMetrics] = useState(selectedMetrics);

    const t = props.thresholdValues;
    const configuredThreshold = Array.isArray(t) && t.length > 0 ?
        t : new Array<MapPsapThresholdValues>();
    const [thresholdValues, setThresholdValues] = useState(configuredThreshold);

    selectedPSAPs.forEach((psap) => {
        if (typeof configuredThreshold.find(p => p.psapId === psap.value) !== 'undefined') {

        }
        else {
            const psapThreshold: MapPsapThresholdValues = {
                psapId: psap.value,
                minThresholdEnabled: false,
                maxThresholdEnabled: false,
                thresholdValues: new Array<MapThresholdValues>()
            }

            selectedMetrics.forEach((metric) => {
                const metricThreshold: MapThresholdValues = {
                    metric: metric.value,
                    min: 0,
                    max: 100
                }
                psapThreshold.thresholdValues.push(metricThreshold);
            })
            configuredThreshold.push(psapThreshold);
        }
    });

    let sortOrder: { [key: string]: string } = {};
    if (!SortOrderEmpty(props.sortOrder)) {
        Object.keys(props.sortOrder).forEach(key => {
            if (key.length > 1) sortOrder[key] = props.sortOrder[key];
        });
    }

    const onPSAPSelectionChange = (options: any): void => {
        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.PSAP]] !== 'undefined' && options.length > 1) {
            if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.ascending) {
                options = SortAscending(options);
            }
            else if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.descending) {
                options = SortDescending(options);
            }
        }

        setPSAPs(options);
        typeof props.onChange === "function" && props.onChange("selectedPsapIds", options.map(p => p.value));

        UpdateMapThresholdPsap(options);
    };

    const onMetricChange = (options: any): void => {
        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.Metric]] !== 'undefined' && options.length > 1) {
            if (sortOrder[SortableType[SortableType.Metric]] === SortConstants.ascending) {
                options = SortAscending(options);
            }
            else if (sortOrder[SortableType[SortableType.Metric]] === SortConstants.descending) {
                options = SortDescending(options);
            }
        }

        setMetrics(options);
        typeof props.onChange === "function" && props.onChange("selectedMetrics", options.map(m => m.value));

        UpdateMapThresholdMetric(options);
    };

    const onShowThresholdConfigPopup = (): void => {
        setShowThresholdConfig(!showMapThresholdConfig);
    }

    const onHideThresholdConfigPopup = (): void => {
        const localStorageData = JSON.parse(localStorage.getItem("MapThresholdData"));
        if (localStorageData !== null) {
            setThresholdValues(JSON.parse(localStorage.getItem("MapThresholdData")));
        }
        setShowThresholdConfig(!showMapThresholdConfig);
    };

    const onThresholdValuesChanged = (thresholdData: any): void => {
        setThresholdValues(thresholdData);
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdData);
    }

    const onSaveThresholdValues = (thresholdData: any): void => {
        setThresholdValues(thresholdData);
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdData);
        setShowThresholdConfig(!showMapThresholdConfig);
        localStorage.setItem("MapThresholdData", JSON.stringify(thresholdData));
    };

    const onSaveSort = (order: string, sorted: any[], type: SortableType): void => {
        const optionsArr = Array.isArray(sorted) ? sorted : [sorted];
        
        if (sortOrder[SortableType[type]] !== order) {
            sortOrder[SortableType[type]] = order;
            typeof props.onChange === "function" && props.onChange("sortOrder", sortOrder);
        }

        if (type === SortableType.PSAP) {
            setPSAPs(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(m => m.value));
        }
        else if (type === SortableType.Metric) {
            setMetrics(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedMetrics", optionsArr.map(m => m.value));
        }
    };

    const UpdateMapThresholdPsap = (options: any) => {
        let thresholdData = thresholdValues;

        const localStorageData = JSON.parse(localStorage.getItem("MapThresholdData"));

        // Add
        options.forEach((psap) => {
            if (typeof thresholdData.find(t => t.psapId === psap.value) === 'undefined') {
                if (localStorageData !== null && typeof localStorageData.find(l => l.psapId === psap.value) !== 'undefined') {
                    thresholdData.push(localStorageData.find(l => l.psapId === psap.value));
                }
                else {
                    thresholdData.push(CreateNewMapPsapThresholdValues(psap.value));
                }
            }
        });

        // Remove 
        thresholdData.forEach((item) => {
            if (typeof options.find(x => x.value === item.psapId) === 'undefined') {
                thresholdData.splice(thresholdData.indexOf(item), 1);
            }
        });

        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdData);
        setThresholdValues(thresholdData);
    }

    const UpdateMapThresholdMetric = (options: any) => {
        let thresholdData = thresholdValues;

        const localStorageData = JSON.parse(localStorage.getItem("MapThresholdData"));

        thresholdData.forEach((item) => {
            options.forEach((metric) => {
                // Add 
                if (typeof item.thresholdValues.find(m => m.metric === metric.value) === 'undefined') {
                    if (localStorageData !== null && typeof localStorageData.find(l => l.psapId === item.psapId) !== 'undefined' &&
                        typeof localStorageData.find(l => l.psapId === item.psapId).thresholdValues.find(m => m.metric === metric.value) !== 'undefined') {
                        thresholdData.find(p => p === item).thresholdValues.push(localStorageData.find(l => l.psapId === item.psapId).thresholdValues.find(m => m.metric === metric.value));

                    }
                    else {
                        thresholdData.find(p => p.psapId == item.psapId).thresholdValues.push(CreateNewMapThresholdValue(metric.value));
                    }
                }
            });

            // Remove
            const idToRemove = GetIdToRemove(options, item.thresholdValues);
            if (idToRemove !== null) {
                thresholdData.find(p => p.psapId === item.psapId).thresholdValues =
                    thresholdData.find(p => p.psapId === item.psapId).thresholdValues.filter(m => m.metric !== idToRemove);
            }
        });

        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdData);
        setThresholdValues(thresholdData);
    }

    const CreateNewMapPsapThresholdValues = (psapId: string | number): MapPsapThresholdValues => {
        let newObject: MapPsapThresholdValues = {
            psapId: psapId,
            minThresholdEnabled: false,
            maxThresholdEnabled: false,
            thresholdValues: []
        };

        selectedMetrics.forEach((metric) => {
            newObject.thresholdValues.push(CreateNewMapThresholdValue(metric.value));
        });

        return newObject;
    }

    const CreateNewMapThresholdValue = (metricId: string | number): MapThresholdValues => {
        const newObject: MapThresholdValues = {
            metric: metricId,
            min: 0,
            max: 100
        };

        return newObject;
    }

    const GetIdToRemove = (options: any, thresholds: MapThresholdValues[]): string | number => {
        for (let i = 0; i < thresholds.length; i++) {
            if (typeof options.find(x => x.value === thresholds[i].metric) === 'undefined') {
                return thresholds[i].metric;
            }
        }
    };

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Select Metric(s)"} required placement="right" title={"Select metrics to display on the map"} />
                </div>

                <SortableSelect
                    sortOrder={GetSortOrder(sortOrder, SortableType.Metric)}
                    sortableType={SortableType.Metric}
                    onSaveSort={onSaveSort}
                    hideSelectedOptions={false}
                    menuPortalTarget={document.body}
                    isMulti={true}
                    value={metrics}
                    options={metricOptions}
                    onChange={onMetricChange}
                    styles={metrics?.length === 0 ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles} />

                {
                    metrics?.length === 0 &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>
            
            <div className={shared.row}>
                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"Select PSAP(s)"} required placement="right" title={"Select PSAP(s)"} />
                </div>

                <SortableSelect
                    sortOrder={GetSortOrder(sortOrder, SortableType.PSAP)}
                    sortableType={SortableType.PSAP}
                    onSaveSort={onSaveSort}
                    hideSelectedOptions={false}
                    menuPortalTarget={document.body}
                    isMulti={true}
                    value={psaps}
                    options={psapOptions}
                    onChange={onPSAPSelectionChange}
                    styles={psaps?.length === 0 ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles} />

                {
                    psaps?.length === 0 &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>            

            <div className={shared.row}>
                {
                    psaps.length > 0 && metrics.length > 0 &&
                    <div className={style.threshold} onClick={onShowThresholdConfigPopup}>
                        <span title="Set Threshold Values">
                            <FontAwesomeIcon icon="gear" />
                            <span>Set Threshold Values</span>
                        </span>
                    </div>
                }
            </div>
            {
                showMapThresholdConfig &&
                <div>
                    <MapThresholdConfig
                        selectedPsaps={selectedPSAPs}
                        selectedMetrics={selectedMetrics}
                        tresholdValues={typeof thresholdValues === 'undefined' ? null : thresholdValues}
                        onCancel={onHideThresholdConfigPopup}
                        onThresholdValuesChanged={onThresholdValuesChanged}
                        onSave={onSaveThresholdValues} />
                </div>
            }
        </React.Fragment>
    );
}