import { SortableType } from "./SortableSelect";

export const SortOrderEmpty = (keyValuePair: { [key: string]: string }): boolean => {
    if (keyValuePair === null || typeof keyValuePair === 'undefined') {
        return true;
    }
    else return Object.values(keyValuePair).every(x => x === null);
}

export const GetSortOrder = (sortOrder: any, type: SortableType): string => {
    let order: string;

    if (!SortOrderEmpty(sortOrder)) {
        if (typeof sortOrder[SortableType[type]] !== 'undefined') order = sortOrder[SortableType[type]];
        else order = null;
    }
    else order = null;

    return order;
}

export const SortAscending = (selectedOptions: any): any => {
    return selectedOptions.sort((a, b) => a.label > b.label ? 1 : -1);
}

export const SortDescending = (selectedOptions: any): any => {
    return selectedOptions.sort((a, b) => a.label > b.label ? -1 : 1);
}