import { useContext, useEffect, useState } from "react";
import { SizeContext } from "../interfaces/SizeContext";
import { DefaultWidgetDimensions, PubSubTopic, WidgetName } from "../misc/Constants";
import { Message } from "../misc/Messages";
import { Responsive } from "@ecats911/react-grid-layout";
import { IsFunction, IsUndefinedOrNull } from "../misc/Helpers";
import { EmptyDashboard } from "./EmptyDashboard";
import { LayoutItem } from "../models/LayoutItem";
import style from '../scss/layout.module.scss';

export interface DroppingItem {
    i: string;
    h?: number;
    w?: number;
    name: WidgetName;
}

export type GridItemWrapper = {
    jsx: JSX.Element;
    key: string;
}

export interface DashboardGridLayoutProps {
    layout?: any;
    items: GridItemWrapper[];
    reportResizeEvents?: boolean;
    layoutId: string;
    isDroppable?: boolean;
    droppingItem?: DroppingItem;
    handleLayoutChange?: (layout) => void;
    onDrop?: (layout: LayoutItem[], layoutItem: LayoutItem, widgetName: WidgetName) => void;
    isDesignMode: boolean;
    isAllowedEdit: boolean;
}

const DashboardGridLayout = (props: DashboardGridLayoutProps) => {

    //const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };

    const breakpoints = { lg: 1200 };

    const [breakPoint, setBreakPoint] = useState("lg");
    const [preventCollision, setPreventCollision] = useState(true);

    const [lockDrag, setLockDrag] = useState(false);
    const [lockAnimation, setLockAnimation] = useState(false);

    const sizeContext = useContext(SizeContext);

    const [itemJustDropped, setItemJustDropped] = useState(false);

    function HandleBreakPointChange(breakPoint) : void {
        setBreakPoint(breakPoint);
    }

    function HandleLayoutChange(currentLayout: any, allLayouts: any): void {

        if (IsFunction(props.handleLayoutChange)) {
            props.handleLayoutChange(currentLayout);
            return;
        }

        if (Array.isArray(currentLayout) && currentLayout.length > 0) {

            // Individual items must not have the isDraggable property, otherwise this overrides the parent setting. 
            // This seems automatically set by RGL if the grid is set to draggable.
            currentLayout.forEach((element, index) => {
                currentLayout[index].isDraggable = undefined;
            });

            if (props.reportResizeEvents === true) {
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.UpdateLayout,
                    data: { layoutId: props.layoutId, layout: currentLayout, bp: breakPoint }
                });
            }
        }
    }

    function OnSwitchToEditModeButtonClick(): void {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.ToggleDashboardViewMode
        });
    }

    useEffect(() => {
        const gridRef = PubSub.subscribe(PubSubTopic.Grid, (a, b) => {

            if (b.message === "drag-start") {
                setLockDrag(true);
            }
            else if (b.message === "drag-stop") {
                setLockDrag(false);
            }
        });

        const refkeyDown = PubSub.subscribe(PubSubTopic.EventsKeyDown, (event, key) => {
            if (key === "Control") {
                setPreventCollision(false);
            }
        });
        const refkeyUp = PubSub.subscribe(PubSubTopic.EventsKeyUp, (event, key) => {
            if (key === "Control") {
                setPreventCollision(true);
            }
        });

        return () => {
            PubSub.unsubscribe(refkeyDown);
            PubSub.unsubscribe(refkeyUp);
            PubSub.unsubscribe(gridRef);
        };

    }, []);

    const onDropDragOver = (): void => {
        if (lockDrag) {
            setLockDrag(false);
        }
    }

    let droppingItem = null as DroppingItem;

    if (!IsUndefinedOrNull(props.droppingItem)) {
        droppingItem = { ...DefaultWidgetDimensions, ...props.droppingItem };
    }

    // If dashboard has no items and no drop is in progress, we must show an empty state image instead of grid.
    let showEmptyStateDashboard = false;
    if (props.items.length === 0 && IsUndefinedOrNull(props.droppingItem) && !itemJustDropped) {
        showEmptyStateDashboard = true;
    }

    const layout = Array.isArray(props.layout) ? props.layout : [];

    useEffect(() => {
        var r = document.querySelector(':root') as HTMLElement;
        const col = props.isDesignMode ? '#1E5F8A' : 'transparent';
        r.style.setProperty('--borderColor', col);
    }, [props.isDesignMode]);

    return (

        <div className={style.container}>
            {
                sizeContext.width !== null && !showEmptyStateDashboard &&
                <Responsive
                    style={{ height: "inherit" }}
                    onDropDragOver={onDropDragOver}
                    onDragStop={() => { }}
                    className={lockAnimation ? "" : "animated"}
                    rowHeight={10}
                    measureBeforeMount={true}
                    useCSSTransforms={true}
                    layouts={{ lg: layout }}
                    layout={layout}
                    breakpoints={breakpoints}
                    cols={{ lg: 96, md: 24, sm: 6, xs: 4, xxs: 2 }}
                    width={sizeContext.width}
                    compactType={null}
                    autoSize={true}
                    droppingItem={droppingItem}
                    resizeHandles={['se']}
                    isDraggable={props.isDesignMode && !lockDrag}
                    isResizable={props.isDesignMode}
                    preventCollision={preventCollision}
                    // isBounded={true}
                    onBreakpointChange={HandleBreakPointChange}
                    onLayoutChange={HandleLayoutChange}
                    isDroppable={!IsUndefinedOrNull(droppingItem) && props.isDroppable !== false}
                    onDrop={(newLayout: LayoutItem[], layoutItem: LayoutItem, e: any) => {
                        // An item was dropped in the dashboard. Set itemJustDropped to true for 5 seconds to block empty state dashboard while the widget is getting added to the layout.
                        setItemJustDropped(true);
                        setTimeout(() => setItemJustDropped(false), 4000)

                        props.onDrop(newLayout, layoutItem, props.droppingItem?.name);

                    }}>
                    {
                        props.items.map(i => {
                            return <div className={style.widgetcontainer} key={i.key}>{i.jsx}</div>
                        })
                    }
                </Responsive>
            }
            {
                showEmptyStateDashboard &&
                <EmptyDashboard isEditMode={props.isDesignMode} hasEditRights={props.isAllowedEdit} switchToEditModeHandleClick={OnSwitchToEditModeButtonClick} ></EmptyDashboard>
            }
        </div>
    );
}

export { DashboardGridLayout }