import React from 'react';
import style from '../scss/loginpage.module.scss';
import * as axios from 'axios';
import Select from 'react-select';
import { OptionType } from '../models/OptionType';
import { LoginTokens } from '../misc/TemporaryCode';
import { app } from '..';

class LoginPageState {
    loading: boolean;
    errorMessage: string;
    selectedUser: OptionType;
}

export class LoginPage extends React.Component<any, LoginPageState>{

    userOptions: OptionType[] = [
        {
            label: "User 1",
            value: "user1"
        },
        {
            label: "User 2",
            value: "user2"
        },
        {
            label: "User 3",
            value: "user3"
        },
        {
            label: "User 4",
            value: "user4"
        },
        {
            label: "User 5",
            value: "user5"
        },
        {
            label: "User 6",
            value: "user6"
        },
        {
            label: "User 1 Psap",
            value: "user10"
        },
        {
            label: "User 0 Psaps",
            value: "user11"
        },
        {
            label: "admin",
            value: "admin"
        }

    ];

    state: LoginPageState = {
        loading: false,
        errorMessage: "",
        selectedUser: this.userOptions[0]
    };

    userOptionsDiv = () => {
        const divOutput = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 
                            <div className={style.authentication}>

                                <div className={style.signin}>
                                
                                    <Select
                                        onChange={user => { this.setState({ selectedUser: user }) }}
                                        options={this.userOptions}
                                        value={this.state.selectedUser} />
                                    <button id='open_session' disabled={this.state.loading} onClick={this.openSession.bind(this)}>
                                        {
                                            !this.state.loading &&
                                            <span>Open session</span>
                                        }
                                        {
                                            this.state.loading &&
                                            <span>Please wait...</span>
                                        }
                                    </button>
                                </div>
                            </div> :
                            <div className={style.authentication}>

                                <div className={style.signin}>
                                    {
                                        !this.state.loading &&
                                        <span>Please login from ECaTS...</span>
                                    }
                                    {
                                        this.state.loading &&
                                        <span>Please wait...</span>
                                    }
                                </div>
                             </div> 
        return divOutput;
    }
    
    render(): JSX.Element {

        return (
            <div className={style.login}>
                <h3>Authentication required</h3>
                {
                    this.userOptionsDiv()                        
                }
                {
                    this.state.errorMessage !== '' &&
                    <div className={style.error}>
                        <p>{this.state.errorMessage}</p>
                    </div>
                }

            </div>

        );
    }

    openSession(): void {
        this.setState({
            loading: true
        }, () => {
            const token = LoginTokens[this.state.selectedUser.value] as string;
            window.location.href = `${app.apiBaseUrl}/auth?jwt=${token}&target=${encodeURIComponent("http://localhost:3000")}`;
        });
    }
}
