import { IsUndefinedNullOrEmpty, StringIsNullOrWhiteSpace } from '../../../misc/Helpers';
import { NumberWidgetMainTabProps } from "./NumberWidgetMainTab"
import { NumberWidgetDataTabProps } from "./NumberWidgetDataTab"
import { NumberWidgetDesignTabProps } from "./NumberWidgetDesignTab"
import { ValidationResult } from "../../WidgetOptions"

/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const NumberWidgetConfigValidator = [

	// Main tab validator
	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {
		if (data.useMinThreshold === true && Number.isNaN(data.thresholdMinValue))
			return { isValid: false, message: "Threshold Min value is not valid", tabName: tabLabel };
		return { isValid: true, message: "" }
	},

	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {
		if (data.useMaxThreshold === true && Number.isNaN(data.thresholdMaxValue))
			return { isValid: false, message: "Threshold Max value is not valid", tabName: tabLabel };
		return { isValid: true, message: "" }
	},

	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {
		if (data.useMinThreshold === true && data.useMaxThreshold === true && !Number.isNaN(data.thresholdMinValue) && data.thresholdMinValue >= data.thresholdMaxValue)
			return { isValid: false, message: "Threshold Min value cannot be higher than Threshold Max value", tabName: tabLabel };
		return { isValid: true, message: "" }
	},

	// Data tab validator
	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {
		if (IsUndefinedNullOrEmpty(data.selectedMetric))
			return { isValid: false, message: "A metric (column) must be selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {

		if (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0)
			return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(data: NumberWidgetDataTabProps, main: NumberWidgetMainTabProps, design: NumberWidgetDesignTabProps, tabLabel: string): ValidationResult => {

		if (data.selectedPsapIds?.length > 1 && IsUndefinedNullOrEmpty(data?.selectedEquation))
			return { isValid: false, message: "An equation must be selected when multiple rows (PSAPs) are selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	}
];
