import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from 'react';
import style from './style.module.scss';
import Select, { ActionMeta, GroupBase, StylesConfig } from 'react-select';
import { IsFunction } from '../../misc/Helpers';
import { SortableContainer } from './SortableContainer';
import { OptionType } from "../../models/OptionType";

export enum SortableType {
    Metric,
    PSAP
}

export interface SelectProps {
    isDisabled?: boolean;
    hideSelectedOptions?: boolean;
    menuPortalTarget?: HTMLElement;
    isMulti?: boolean;
    value: any[];
    options: OptionType[];
    onChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
    styles?: StylesConfig<any, any, GroupBase<any>>;
    placeholder?: any;
}

export interface SortableSelectProps extends SelectProps {
    onSaveSort: (sortOrder: string, sorted: any[], type: SortableType) => void;
    sortableType: SortableType;
    sortOrder: any;
}

export const SortableSelect = (props: SortableSelectProps) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [showSortingContainer, setShowSortingContainer] = useState(false);
    const divToScroll = useRef(null);

    let initValues: string[] = [];
    if (props.value?.length > 0) {
        initValues = props.value.filter(q => props.options.find(x => x.value === q.value)).map(p => p.label);
    };

    const [selectedValues, setSelectedValues] = useState(initValues);

    const onShowHideSortingContainer = (): void => {
        setShowSortingContainer(!showSortingContainer);
    }

    const onSaveSort = (sortOrder: string, sorted: any[]): void => {
        setShowSortingContainer(!showSortingContainer);
        IsFunction(props.onSaveSort) && props.onSaveSort(sortOrder, sorted, props.sortableType);
    }

    const onChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        setSelectedValues(newValue?.map(i => i.label));
        IsFunction(props.onChange) && props.onChange(newValue, actionMeta);
    }

    useEffect(() => {
        divToScroll.current.scrollIntoView({ behavior: "smooth" });
    }, [selectedValues]);

    const onClickMenu = () => {
        divToScroll.current.scrollIntoView();
    };

    return (
        <div>
            {
                !isEditMode && props.sortableType === SortableType.PSAP &&
                <Select
                    isDisabled={props.isDisabled}
                    hideSelectedOptions={props.hideSelectedOptions}
                    menuPortalTarget={props.menuPortalTarget}
                    isMulti={props.isMulti}
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    styles={props.styles}
                    placeholder={props.placeholder}
                    onMenuOpen={onClickMenu} />
            }
            {
                !isEditMode && props.sortableType === SortableType.Metric &&
                <Select
                    isDisabled={props.isDisabled}
                    menuPortalTarget={props.menuPortalTarget}
                    isMulti={props.isMulti}
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    styles={props.styles}
                    placeholder={props.placeholder}
                    onMenuOpen={onClickMenu} />
            }
            <div ref={divToScroll}></div>
            <div className={classNames(initValues?.length < 2 ? style.hidden : style.sortingOptions)}>
                <div>
                    {
                        props.sortableType === SortableType.PSAP &&
                        <span onClick={onShowHideSortingContainer}><FontAwesomeIcon icon="arrow-down-a-z" />Sort Selected PSAPs</span>
                    }
                    {
                        props.sortableType === SortableType.Metric &&
                        <span onClick={onShowHideSortingContainer}><FontAwesomeIcon icon="arrow-down-a-z" />Sort Selected Metrics</span>
                    }
                </div>
            </div>
            {
                showSortingContainer && props.sortableType === SortableType.PSAP &&
                <SortableContainer
                    type={SortableType.PSAP}
                    options={props.options}
                    selectedOptions={props.value}
                    onCancel={onShowHideSortingContainer}
                    onSaveSort={onSaveSort}
                    sortOrder={props.sortOrder}
                />
            }
            {
                showSortingContainer && props.sortableType === SortableType.Metric &&
                <SortableContainer
                    type={SortableType.Metric}
                    options={props.options}
                    selectedOptions={props.value}
                    onCancel={onShowHideSortingContainer}
                    onSaveSort={onSaveSort}
                    sortOrder={props.sortOrder}
                />
            }
        </div>
    )
} 