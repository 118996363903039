import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import { AppContext, DashboardContext } from './interfaces/AppContext';
import { RegionalView } from './pages/RegionalView';
import { RegionalMaster } from './pages/RegionalMaster';
import { ConfigurationMaster } from "./pages/ConfigurationMaster";
import { app } from '.';
import { PubSubTopic } from "./misc/Constants";
import { Dictionary } from "./interfaces/Dictionary";
import { MapConfigDevView } from "./pages/dev/MapConfigDevView";
import { MultiMetricConfigDevView } from "./pages/dev/MultiMetricConfigDevView";
import { SingleMetricConfigDevView } from "./pages/dev/SingleMetricConfigDevView";
import { useEffect, useState } from "react";
import { MainStoreState } from "./interfaces/MainStoreState";
import { RegionalViewLoader } from "./services/RegionalViewLoader";
import { NumberWidgetConfigDevView } from "./pages/dev/NumberWidgetConfigDevView";
import { FontPageTest } from "./pages/FontPageTest";
import { MultiRingConfigDevView } from "./pages/dev/MultiRingConfigDevView";
import { DevMaster } from "./pages/dev/DevMaster";
import { ContainerConfigDevView } from "./pages/dev/ContainerConfigDevView";
import { BarChartConfigDevView } from "./pages/dev/BarChartConfigDevView";
import { SortingContainerDevView } from "./pages/dev/SortingContainerDevView";
import { Page404 } from "./pages/Page404";
import { NotificationPage } from "./pages/Notifications";

export interface AppProps {
    localization: Dictionary<string>;
}

const App = (props: AppProps): JSX.Element => {

    const [psaps, setPSAPs] = useState(app.store.state.psaps);
    const [psapFilter, setPSAPFilter] = useState<string>(app.store.state.psapFilter);
    const [metric, setMetrics] = useState(app.store.state.metrics);
    const [layouts, setLayouts] = useState(app.store.state.layouts);
    const [layoutsWidgets, setLayoutsWidgets] = useState(app.store.state.layoutsWidgets);
    const [isDesignMode, setIsDesignMode] = useState(app.store.state.isDesignMode);
    const [dashboardNotifications, setDashboardNotifications] = useState(app.store.state.notifications);
    const [adminSettings, setAdminSettings] = useState(app.store.state.adminSettings);
    const [mapWidgetInEidit, setMapWidgetInEdit] = useState(app.store.state.mapWidgetInEdit);
    const [userTimeZone, setUserTimeZone] = useState(app.store.state.user.timeZone);

    const initialContext: DashboardContext = {
        user: app.store.state.user,
        psapId: app.store.state.psapView,
        localization: props.localization,
        psapFilter: psapFilter
    };

    const [context, setContext] = useState(initialContext);

    const onStoreStateChange = (event: any, state: MainStoreState): void => {

        setPSAPs(app.store.state.psaps);
        setMetrics(app.store.state.metrics);
        setLayouts(app.store.state.layouts);
        setLayoutsWidgets(app.store.state.layoutsWidgets);
        setIsDesignMode(app.store.state.isDesignMode);
        setDashboardNotifications(app.store.state.notifications);
        setAdminSettings(app.store.state.adminSettings);
        setPSAPFilter(app.store.state.psapFilter);
        setMapWidgetInEdit(app.store.state.mapWidgetInEdit);
        setUserTimeZone(app.store.state.user.timeZone);
        setContext(old => {
            old.user = app.store.state.user;
            old.psapId = app.store.state.psapView;
            old.psapFilter = app.store.state.psapFilter;
            return { ...old };
        });
    };

    useEffect(() => {

        const notificationReference = PubSub.subscribe(PubSubTopic.Changes, onStoreStateChange);

        return (): void => {
            PubSub.unsubscribe(notificationReference);
        }
    }, []);


    return (
        <Router>
            <AppContext.Provider value={context}>
                <Routes>
                    <Route path={"/"} element={<RegionalMaster dashboardNotifications={dashboardNotifications} />}>
                        <Route index element={
                            <RegionalViewLoader
                                psaps={psaps}
                                metrics={metric}
                                layoutsWidgets={layoutsWidgets}
                                layouts={layouts}
                                isDesignMode={isDesignMode}
                                adminSettings={adminSettings}
                                mapWidgetInEdit={mapWidgetInEidit}>
                                <RegionalView
                                    isDesignMode={true}
                                    isAllowedEdit={context.user.isAllowedEdit}
                                />
                            </RegionalViewLoader>
                        }></Route>
                    </Route>
                    <Route path={"/"} element={<ConfigurationMaster layouts={layouts} dashboardNotifications={dashboardNotifications} />}>
                        <Route path={"notifications"} element={
                            <NotificationPage userTimeZone={userTimeZone} />
                        }>
                        </Route>
                    </Route>
                    <Route path={"dev"} element={<DevMaster />}>
                        <Route path="fonts" element={<FontPageTest />} />
                        <Route path={"config"}>
                            <Route path={"mapconfig"}>
                                <Route index element={<MapConfigDevView />} />
                            </Route>
                            <Route path={"multimetric"}>
                                <Route index element={<MultiMetricConfigDevView />} />
                            </Route>
                            <Route path={"singlemetric"}>
                                <Route index element={<SingleMetricConfigDevView />} />
                            </Route>
                            <Route path={"container"}>
                                <Route index element={<ContainerConfigDevView />} />
                            </Route>
                            <Route path={"number"}>
                                <Route index element={<NumberWidgetConfigDevView />} />
                            </Route>
                            <Route path={"multiring"}>
                                <Route index element={<MultiRingConfigDevView />} />
                            </Route>
                            <Route path={"barchart"}>
                                <Route index element={<BarChartConfigDevView />} />
                            </Route>
                            <Route path={"sorting"}>
                                <Route index element={<SortingContainerDevView psaps={app.store.state.psaps} metrics={app.store.state.metrics} layouts={layouts} layoutsWidgets={layoutsWidgets} isDesignMode={app.store.state.isDesignMode} />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path={"*"} element={<Page404 />} />
                </Routes>
            </AppContext.Provider>
        </Router>
    );
}

export { App };