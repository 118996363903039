
import { HubConnectionState } from "@microsoft/signalr";
import { app } from "..";
import { Handler, HandleType, HandleTypeAsync, MessageArg } from "../interfaces/AppCore";
import { MainStoreState } from "../interfaces/MainStoreState";
import { Message } from "../misc/Messages";

export class SignalRHubHandler implements Handler {


    constructor() {
        this.ConnectToHub.message = Message.ConnectToHub;
        this.DisconnectFromHub.message = Message.DisconnectFromHub;

        this.ConnectionStateChange.message = Message.ConnectionStateChange;

        this.HubDisconnected.message = Message.HubDisconnected;
    }


    InitializeHubConnection = <HandleType>((arg: MessageArg, state: MainStoreState): boolean => {
        app.initializeHubConnection(app.store.hubConfig.signalRHubAddress, app.store.hubConfig.signalRToken);
        return true;
    });


    ConnectToHub = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        let promise = new Promise<boolean>((resolve, reject) => {

            if (app.hubConnection?.state === HubConnectionState.Disconnected) {

                let innerPromise = app.hubConnection.start();
                innerPromise.then(() => {
                    console.log("connected to hub");
                    resolve(true);
                });

                innerPromise.catch(e => {
                    reject(e);
                });
            }
            else {
                resolve(true);
            }
        });

        return promise;
    });

    DisconnectFromHub = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        let promise = new Promise<boolean>((resolve, reject) => {

            let innerPromise = app.hubConnection.stop();
            console.log("disconnected from hub");
            innerPromise.then(() => {
                resolve(true);
            });

            innerPromise.catch(e => {
                reject(e);
            });
        });

        return promise;
    });

    ConnectionStateChange = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {
        const online = arg.data.online === true;
 
        if (online) {
            console.log("reconnect hub");
            return this.ConnectToHub(arg, state);
        }
        else {
            console.log("loss of connection");
            return Promise.resolve(true)
        };
    });

    HubDisconnected = <HandleTypeAsync>((arg: MessageArg, state: MainStoreState): Promise<boolean> => {

        // try reconnect in 5 seconds
        return new Promise<boolean>((resolve, reject) => {
            window.setTimeout(() => {
                console.log("attempt to reconnect to hub");
                this.ConnectToHub(arg, state).then((result) => {
                    resolve(result);
                });
            }, 5000);
        });
    });
}