import React, { ReactElement } from "react";
import { IsUndefinedOrNull } from "../../misc/Helpers";
import { ConfigBinder, WidgetConfig } from "../../services/WidgetConfigBinder";

export interface TabProps {
    name: string;
    label: string;
    component: JSX.Element;
}

export interface TabComponent {
    onChange?: (key: string, value: any) => void;
}

export interface OptionsTab {
    enabled?: boolean;
    name: string;
    label: string;
    render(props?: any): JSX.Element;
    withConfiguration(binder: ConfigBinder): OptionsTab;
    readonly component: JSX.Element;
}


export class Tab implements OptionsTab {

    _component: JSX.Element;
    _config: ConfigBinder = null;

    constructor(props: TabProps) {
        this.name = props.name;
        this.label = props.label;
        this._component = props.component;
    }

    label = "";
    name = "";

    withConfiguration = (config: ConfigBinder): Tab => {
        this._config = config;
        return this;
    }

    render = (): ReactElement => {
        if (IsUndefinedOrNull(this._config)) {
            return this._component;
        }
        else {
            const props = this._config.getProps(this.name, this._component);
            return React.cloneElement(this._component, { ...props });
        }
    };

    get component(): ReactElement {
        return this._component;
    };

}
