import { IsUndefinedNullOrEmpty } from '../../../misc/Helpers';
import { MultiMetricMainTabProps } from "./MultiMetricMainTab"
import { MultiMetricDataTabProps } from "./MultiMetricDataTab"
import { MultiMetricDesignTabProps } from "./MultiMetricDesignTab"
import { ValidationResult } from "../../WidgetOptions"
import { RowDefinition } from '../../../models/Metric';

/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const MultiMetricConfigValidator = [

	// Main tab validator
	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {

		if (Number.isNaN(main.minValue))
			return { isValid: false, message: "A valid UserMin value must be provided", tabName: tabLabel };
		return { isValid: true, message: "" }
	},

	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (Number.isNaN(main.maxValue))
			return { isValid: false, message: "A valid UserMax value must be provided", tabName: tabLabel };

		return { isValid: true, message: "" }
	},

	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (main.maxValue <= main.minValue)
			return { isValid: false, message: "Max value must be greater than min value", tabName: tabLabel };

		return { isValid: true, message: "" }
	},

	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {

		if (main.useThreshold === true && !Number.isNaN(main.thresholdValue) && main.thresholdValue > main.maxValue)
			return { isValid: false, message: "Threshold value cannot be higher than max value", tabName: tabLabel };

		return { isValid: true, message: "" }
	},

	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {

		if (main.useThreshold === true && !Number.isNaN(main.thresholdValue) && main.thresholdValue < main.minValue)
			return { isValid: false, message: "Threshold value cannot be lower than min value", tabName: tabLabel };

		return { isValid: true, message: "" }
	},


	// Data tab validator
	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {
		if (IsUndefinedNullOrEmpty(data.selectedMetrics) || data.selectedMetrics.length === 0)
			return { isValid: false, message: "At least one metric (column) must be selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	},
	(data: MultiMetricDataTabProps, main: MultiMetricMainTabProps, design: MultiMetricDesignTabProps, tabLabel: string): ValidationResult => {

		// Allow not having any selected psap if the selected metric group contains metrics with RowDefinition.None
		let metricGroupWithNoRow = false;
		if (data.metrics.filter(m => m.metricGroup === data.selectedMetricGroup).every(m2 => m2.rowDefinition === RowDefinition.None))
			metricGroupWithNoRow = true;

		if (!data.psapOverride && !metricGroupWithNoRow && (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0))
			return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };
		return { isValid: true, message: "" }
	}
]
