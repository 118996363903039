import React, { ReactElement, useContext, useEffect, useState } from "react";
import { TabComponent } from "../Tab";
import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import { IsNotUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { SortingContainerDataTabName, SortingContainerDataTabProps } from "./SortingContainerDataTab";
import { TabContext } from "../../WidgetOptions";
import { UserPsaps } from "../../../models/UserPsaps";
import { TooltipControl } from "../../ToolTipControl";

interface SubtitleConfigurationProps {
    id: string;
    defaultName: string;
    name: string;
    onChange: (name: string, id: string) => void;
}

const SubtitleConfiguration = (props: SubtitleConfigurationProps): ReactElement => {

    const [name, setName] = useState(props.name);

    const onNameChange = (name: string) => {
        setName(name);
        props.onChange(name, props.id);
    };

    return (
        <div className={style.subtitleConfig}>
            <div className={style.row}>
                <div className={style.name}>
                    <div>
                        <div>
                            <label>Container subtitle</label>
                            <label className={style.defaultName}>{props.defaultName}</label>
                        </div>

                        {
                            name !== props.defaultName &&
                            <span onClick={() => onNameChange(props.defaultName)}>reset</span>
                        }
                    </div>
                    <input
                        type="text"
                        value={name}
                        className="form-control"
                        onChange={e => onNameChange(e.target.value)} />
                </div>
            </div>
        </div>
    );
}

export interface SubtitleConfig {
    id: string;
    name: string;
}

export interface SortingContainerMainTabProps extends TabComponent {
    title?: string;
    subtitleConfig?: SubtitleConfig[];
    psaps: UserPsaps[];
}

export const SortingContainerMainTabName = "SortingContainerMainTab";

export const SortingContainerMainTab = (props: SortingContainerMainTabProps) => {

    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;
    const context = useContext(TabContext);
    const dataTab = context.data[SortingContainerDataTabName] as SortingContainerDataTabProps;
    const [title, setTitle] = useState(initialTitle);

    const selectedPsapsIds: string[] = Array.isArray(dataTab.selectedPsapIds) ? dataTab.selectedPsapIds : [];
    const selectedPsaps: UserPsaps[] = props.psaps.filter(psap => selectedPsapsIds.some(p => p === psap.nenaIdentifier));

    let initialSubtitlesConfig = props.subtitleConfig ?? [];

    // Flush any bar config that is not selected in the selectedPsaps
    initialSubtitlesConfig = initialSubtitlesConfig.filter(i => selectedPsaps.some(m => m.nenaIdentifier === i.id))

    const propsBarConfigPsapUpdate = (psap: UserPsaps, arr: SubtitleConfig[]): void => {
        // Item already present, nothing to do
        const hasConfig = arr.some(g => g.id === psap.nenaIdentifier);
        if (hasConfig)
            return;

        // New item to add
        const newItem: SubtitleConfig = {
            id: psap.nenaIdentifier,
            name: psap.psapName,
        }

        // Insert new item at correct location. The order of subtitles must match order in selected psaps, which are based upon the order of the full list of psap
        let insertIndex = selectedPsaps.indexOf(psap);
        if (insertIndex > -1)
            arr.splice(insertIndex, 0, newItem);
    };

    selectedPsaps.forEach((p, i) => propsBarConfigPsapUpdate(p, initialSubtitlesConfig));

    const [subtitleConfiguration, setSubtitleConfiguration] = useState(initialSubtitlesConfig);

    const onSubtitleConfigChange = (name: string, id: string): void => {

        let chg: SubtitleConfig[];
        setSubtitleConfiguration(old => {

            chg = old.map(e => {
                if (e.id === id) {
                    e.name = name;
                }
                return e;
            });

            return [...chg];
        });
    };

    const changeRequired = (): boolean => {
        if (subtitleConfiguration.length !== props.subtitleConfig?.length)
            return true;

        return !subtitleConfiguration.every((g) => {
            return props.subtitleConfig?.some(p => p.id === g.id && p.name === g.name)
        })
    }

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };

    useEffect(() => {

        // Loop barConfiguration, compare with props and detect and report changes to parent to update configuration. 
        if (changeRequired())
            props.onChange("subtitleConfig", subtitleConfiguration);

    }, []);
    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>

                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="title"
                        maxLength={100}
                        onChange={e => TitleChange(e.target.value)}
                        value={title}
                    />
                </div>
            </div>
            <div className={shared.row}>
                {
                    subtitleConfiguration.filter(c => c.id !== "").map((option, idx) => {
                        const m = props.psaps.find(p => p.nenaIdentifier === option.id);
                        if (IsNotUndefinedOrNull(m)) {
                            return (
                                <SubtitleConfiguration
                                    key={idx}
                                    id={m.nenaIdentifier}
                                    defaultName={m.psapName}
                                    onChange={onSubtitleConfigChange}
                                    name={option.name}
                                />
                            );
                        }
                        return null;
                    })
                }
            </div>
        </React.Fragment>
    );
}