
import { SketchPicker } from 'react-color';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StringIsNullOrWhiteSpace } from '../misc/Helpers';

import style from '../scss/colorpicker.module.scss';

export interface ColorPickerProps {
    color: string;
    label?: string;
    onValueChange: (color: string) => void;
}

export const ColorPicker = (props: ColorPickerProps) => {

    const [showColorSelector, setShowColorSelector] = useState(false);
    const [activeColor, setActiveColor] = useState(props.color);

    const OnEditColor = () => {
        setActiveColor(props.color);
        setShowColorSelector(true);  
    };

    const ApplyColorChange = (e: any): void => {

        setShowColorSelector(false);
        props.onValueChange(activeColor);
    };

    return (
        <div>
            {
                showColorSelector &&
                <React.Fragment>
                    <div className={style.overlay}></div>
                    <div className={style.pickercontainer}>
                        <div className={style.header}>
                            {
                                !StringIsNullOrWhiteSpace(props.label) &&
                                <div className={style.l}>
                                    <label>Pick color for:</label>
                                    <u title={props.label}>{props.label}</u>
                                </div>
                            }
                            <button onClick={() => setShowColorSelector(false)}>
                                <FontAwesomeIcon icon={"x"} />
                            </button>
                        </div>

                        <SketchPicker color={activeColor} onChange={value => setActiveColor(value.hex)} disableAlpha={true} />

                        <div className={style.demoarea} style={{ backgroundColor: activeColor }}>
                            <div>
                                <button onClick={ApplyColorChange}>OK</button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }

            <div className={style.colorscontainer}>
                <div className={style.colorvalue} onClick={() => OnEditColor()}>
                    <section style={{ backgroundColor: props.color }}></section>
                    {
                        !StringIsNullOrWhiteSpace(props.label) &&
                        <label title={props.label}>{props.label}</label>
                    }
                </div>
            </div>
        </div>
    );
};