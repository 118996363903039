import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import style from './style.module.scss';
import { ReactElement } from "react";
import { DashboardNotification } from "../../models/DashboardNotification";
import classNames from 'classnames';

export const DashboardConfirmationAlert = ({ dashboardNotification }: { dashboardNotification: DashboardNotification }): ReactElement => {
    return <div>
        <div className={classNames(style.notificationcontainer, style.backgroundConfirmation)}>
            <div className={classNames(style.circle, style.circleConfirmation)}>
                <FontAwesomeIcon className={style.confirmation} icon={faCircleCheck} />
            </div>
            <div className={style.message}>{dashboardNotification.confirmationMessage}</div>
        </div>
    </div>
};