import { Dictionary } from "../interfaces/Dictionary";
import { LayoutItem } from "../models/LayoutItem";
import { defaultMetricColors } from "./Constants";
import { UserPsaps } from "../models/UserPsaps"

const StringIsNullOrWhiteSpace = (str: string): boolean => {

    if (typeof str === "undefined" || str === null) return true;

    if (Object.prototype.toString.call(str) !== "[object String]") {
        throw new TypeError("expected string in StringIsNullOrWhiteSpace")
    }

    return str.trim().length === 0;
}

export { StringIsNullOrWhiteSpace }

export const IsNotUndefinedOrNull = (obj: any): boolean => typeof (obj) !== "undefined" && obj !== null;

export const IsUndefinedOrNull = (obj: any): boolean => !IsNotUndefinedOrNull(obj);

export const IsUndefinedNullOrEmpty = (obj: any): boolean => IsUndefinedOrNull(obj) || (Array.isArray(obj) && obj.length === 0)

export const DefaultIfUndefinedOrNull = (obj: any, def: any): any => IsUndefinedOrNull(obj) ? def : obj;

export const IsFunction = (obj: any): boolean => typeof (obj) === "function";

export const GetRandomNumber = (min, max): number => {
    return Math.floor(Math.random() * (max - min) + min);
};

export const CloneJSON = <T>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj)) as T;
};

export const GetValueFromDictionary = <T>(key: string, dictionary: Dictionary<T>): T | null => {
    if (StringIsNullOrWhiteSpace(key))
        return null;

    if (IsUndefinedOrNull(dictionary))
        return null;

    if (key in dictionary) {
        return dictionary[key] as T;
    }
    else return null;
};

export const getNextUnusedColor = (arr: string[]): string => {
    let color = defaultMetricColors.find(c => !arr.some(b => b === c))
    return (IsNotUndefinedOrNull(color) ? color : "black")
}

export const IsLayoutDataValid = (data: LayoutItem[]): boolean => {

    if (IsUndefinedOrNull(data) || !Array.isArray(data)) {
        return false;
    }

    let pass = true;

    for (let i = 0; i < data.length; i++) {

        const current = data[i];

        if (IsUndefinedOrNull(current)) {
            pass = false;
            break;
        }

        if (current.w < 3 || current.h < 3) {
            pass = false;
            break;
        }
    }

    return pass;
};

export const SetToZeroIfNegativeValue = (value): number => {
    return value < 0 ? 0 : value;
}


export type SortDirection = "asc" | "desc";

export const PSAPCollectionSorter = (collection: UserPsaps[], direction: SortDirection = "asc"): UserPsaps[] => {
    if (IsUndefinedOrNull(collection) || !Array.isArray(collection)) {
        return [];
    }
    return collection.sort((a: UserPsaps, b: UserPsaps) => {
        if (direction === "asc") {
            return a.psapName.localeCompare(b.psapName);
        }
        else {
            return b.psapName.localeCompare(a.psapName);
        }
    });
};