import style from './style.module.scss';
import vars from "../../scss/colors.module.scss"
import classNames from 'classnames';
import { LabelControlWithToolTipProps } from './LabelControlProps';
import { ThemeProvider, Tooltip, createTheme } from "@mui/material";
import { StringIsNullOrWhiteSpace } from "../../misc/Helpers";

export const LabelControlWithToolTip = (props: LabelControlWithToolTipProps) => {
    let bgColor = vars.tooltipbgcolor;
    let fontColor = vars.tooltipcolor;

    if (props.theme === 'Light') {
        bgColor = vars.tooltipbglight;
        fontColor = vars.tooltipcolorlight;
    }

    const placement = typeof props.placement === "undefined" ? "right" : props.placement;

    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "medium",
                        fontWeight: "normal",
                        color: fontColor,
                        backgroundColor: bgColor,
                        maxWidth: StringIsNullOrWhiteSpace(props.maxWidth) ? "none" : props.maxWidth,
                        padding: "8px"
                    },
                    arrow: {
                        color: bgColor
                    }
                }
            }
        }
    });

    const toolTip = <span className={style.info}>i</span>;

    return (
        <div className={style.labelControl}>
            <span>
                <label className={classNames(style.label, props.required ? style.required : "")}>{props.label}</label>
                <ThemeProvider theme={theme}>
                    <Tooltip arrow enterDelay={400} leaveDelay={200} placement={placement} title={props.title}>
                        {toolTip}
                    </Tooltip>
                </ThemeProvider>
            </span>
        </div>
    )
}