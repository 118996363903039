import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/Messages";
import style from './style.module.scss';
import { ReactElement } from "react";
import { DashboardNotification } from "../../models/DashboardNotification";
import classNames from 'classnames';

export const DashboardNotificationAlert = ({ dashboardNotification }: { dashboardNotification: DashboardNotification }): ReactElement => {

    function onCancel(): void {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.AcknowledgeNotification,
            data: {
                notificationId: dashboardNotification.id
            }
        });
    }

    return <div>
        <div className={classNames(style.notificationcontainer, dashboardNotification.level === 0 ? style.backgroundError : dashboardNotification.level === 1 ? style.backgroundWarning : style.backgroundInformation)}>
            <div className={classNames(style.circle, dashboardNotification.level === 0 ? style.circleError : dashboardNotification.level === 1 ? style.circleWarning : style.circleInformation)}>
                {   (dashboardNotification.level === 0 || dashboardNotification.level === 1) &&
                    <FontAwesomeIcon className={style.triangle} icon={faTriangleExclamation} />
                }
                {
                    dashboardNotification.level === 2 &&
                    <FontAwesomeIcon className={style.info} icon={faInfo} />
                }
            </div>
            <div className={style.close}
                onClick={onCancel}>
                <span><FontAwesomeIcon className={style.closeIcon} icon={faX} /></span>
            </div>
            <div className={style.message}>{dashboardNotification.message}</div>
        </div>
    </div>
};