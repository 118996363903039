import { useContext, useEffect, useState } from "react";
import { Message } from "../misc/Messages";
import { Responsive } from "@ecats911/react-grid-layout";
import { DefaultWidgetDimensions, PubSubTopic, WidgetName } from "../misc/Constants";
import { GridItem } from "./GridItem";
import { WidgetFactory } from "../services/WidgetFactory";
import { app } from '..';
import { DroppingItem, GridItemWrapper } from "./DashboardGridLayout";
import { IsFunction, IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { MainStoreState } from "../interfaces/MainStoreState";
import { ContainerTitle } from "./ContainerTitle";
import { Layout } from "../models/DashboardLayout";
import { WidgetConfiguration } from "../models/WidgetConfiguration";
import classNames from "classnames";
import { SizeContext } from "../interfaces/SizeContext";
import { UserPsaps } from "../models/UserPsaps";
import { EmptyWidget } from "./EmptyWidget";
import style from '../scss/layout.module.scss';
import { LayoutItem } from "../models/LayoutItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ContainerOverride is that passed by the sorting container to provide override data to use in the widgets within this container
export interface ContainerOverride {
    psap: UserPsaps;
}

export interface GenericContainerProps {

    layout?: Layout;
    parentLayoutId?: string;
    factory?: WidgetFactory;
    droppingItem?: DroppingItem;
    title: string;
    style?: any;
    layoutWidgets?: WidgetConfiguration[];
    isDesignMode?: boolean
    onLayoutChange?: Function;
    isSortingContainer?: boolean;
    containerOverride?: ContainerOverride;
}

export const GenericContainer = (props: GenericContainerProps): JSX.Element => {

    const breakpoints = { lg: 1200 };
    const [breakPoint, setBreakPoint] = useState("lg");

    const [containerLayoutWidgets, setContainerLayoutWidgets] = useState(props.layoutWidgets);

    const [nextDroppingItem, setNextDroppingItem] = useState(null as DroppingItem);

    const [isDragOver, setIsDragOver] = useState(false);

    const sizeContext = useContext(SizeContext);

    function onMouseOver(): void {
        PubSub.publish(PubSubTopic.Grid, {
            message: "drag-start"
        });
    }

    function onMouseOut(): void {
        PubSub.publish(PubSubTopic.Grid, {
            message: "drag-stop"
        });
    }

    let ref = 0;

    function onDropDragOver() {
        // We set a flag during drag over, because we must not update the state of the layout while this occurs because updating layout cancel drags.
        clearTimeout(ref);
        setIsDragOver(true)
        ref = window.setTimeout(() => {
            setIsDragOver(false)
        }, 200);
    }

    function HandleBreakPointChange(breakPoint: any) {
        setBreakPoint(breakPoint);
    }

    function HandleLayoutChange(layout: any) {
        // Do not report layout changes during drag over. The resulting state update could interrupt the drag operation.
        if (isDragOver) {
            return;
        }

        if (IsUndefinedOrNull(props.layout) || IsUndefinedOrNull(props.layout.id)) {
            console.log("GenericContainer: HandleLayoutChange received while layout was not set. Skipping")
            return;
        }

        // Call parent onLayoutChange. This is meant for SortingContainer so that it refresh the layout state.
        if (IsFunction(props.onLayoutChange) && !isDragOver)
            if (!isDragOver)
                props.onLayoutChange(layout);

        if (Array.isArray(layout)) {

            // Individual items must not have the isDraggable property, otherwise this overrides the parent setting. 
            // This seems automatically set by RGL if the grid is set to draggable.
            layout.forEach((element) => {
                element.isDraggable = undefined;
            });

            // If we have a layout update going to size 0, we need to update the layout immediately, otherwise we could end up with non-empty layout in DB without any widgets, causing generic container not to render
            let immediateUpdate = layout.length > 0 ? false : true;

            PubSub.publish(PubSubTopic.Action, {
                id: Message.UpdateLayout,
                data: { layoutId: props.layout.id, layout: layout, bp: breakPoint, immediateUpdate: immediateUpdate },
            });
        }
    }

    useEffect(() => {

        // Update from the widget store. Update widgets of this container's layout and wet next dropping item if any
        const OnStoreDataChange = (message, state: MainStoreState): void => {
            if (typeof props.layout === "undefined") {
                return;
            }

            let updatedLayoutWidgets = app.store.state.layoutsWidgets[props.layout.id];

            if (IsNotUndefinedOrNull(updatedLayoutWidgets)) {

                if (IsNotUndefinedOrNull(containerLayoutWidgets)) {
                    if (containerLayoutWidgets.length > updatedLayoutWidgets.length) {
                        onMouseOut();
                    }
                }
                setContainerLayoutWidgets(updatedLayoutWidgets);
            }

            // Set next dropping widget if any
            let draggingWidget: DroppingItem = null;
            if (!StringIsNullOrWhiteSpace(state.draggingWidget.id)) {
                draggingWidget = {
                    i: state.draggingWidget.id,
                    name: state.draggingWidget.name as WidgetName,
                    h: 14,
                    w: 8
                };
            }
            setNextDroppingItem(draggingWidget);
        }

        const dispatcherRef = PubSub.subscribe(PubSubTopic.Changes, OnStoreDataChange);

        return () => {
            PubSub.unsubscribe(dispatcherRef);
        }
    }, [containerLayoutWidgets]);


    const onDrop = (layout: LayoutItem[], layoutItem: LayoutItem, e: any) => {

        if (droppingItem?.name === WidgetName.GenericContainer) {
            console.log("Dropping a container within a container is not supported")
        }
        else if (droppingItem?.name === WidgetName.SortingContainer) {
            console.log("Dropping a sorting container within a container is not supported")
        }
        else if (droppingItem?.name === WidgetName.Map) {
            console.log("Dropping a Map widget within a container is not supported")
        }
        else {
            onWidgetDrop(layout, layoutItem, droppingItem?.name);
        }

        PubSub.publish(PubSubTopic.Grid, {
            message: "dropped"
        });
    }

    const onWidgetDrop = (layout: LayoutItem[], widgetShadow: LayoutItem, widgetName: WidgetName): void => {

        PubSub.publish(PubSubTopic.Action, {
            id: Message.AddWidget,
            data: {
                id: widgetShadow.i,
                name: widgetName,
                layoutId: props.layout.id,
                parentLayoutId: props.parentLayoutId,
                layoutData: layout
            }
        });
    };

    let droppingItem = null as DroppingItem;

    if (!IsUndefinedOrNull(nextDroppingItem)) {
        droppingItem = { ...DefaultWidgetDimensions, ...nextDroppingItem };
    }

    const onRemoveWidget = (widget: WidgetConfiguration) => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RemoveWidget,
            data: {
                widget: widget,
                layoutId: props.layout.id,
            }
        });
    };

    const onEditWidget = (widget: WidgetConfiguration): void => {
        // If we have a PSAP override, we send the edit container widget event with a special flag to disable PSAP selection
        let psapOverride = false;
        if (IsNotUndefinedOrNull(props.isSortingContainer))
            psapOverride = true;

        PubSub.publish(PubSubTopic.Action, {
            id: Message.EditContainerWidget,
            data: { widget: widget, containerLayoutId: props.layout.id, psapOverride: psapOverride }
        });
    }

    let items: GridItemWrapper[] = [];

    if (!IsUndefinedNullOrEmpty(containerLayoutWidgets)) {
        items = containerLayoutWidgets?.map((w: WidgetConfiguration) => {

            // If this is a sorting container, append the psapId to the widget IDs. This will ensure that widget within replicas are using different IDs internally and don't conflict. Cannot use nena identifier because of @ and .
            let widgetId = w.widgetId;
            if (!IsUndefinedNullOrEmpty(props?.containerOverride?.psap?.nenaIdentifier)) {
                widgetId = w.id + "_psap_" + props?.containerOverride?.psap?.psapId;
            }

            const widgetObject = props.factory.render({ ...w, id: widgetId }, props.containerOverride);
            const showEditButton = widgetObject.type.name !== EmptyWidget.name;

            return {
                jsx:
                    <GridItem
                        reportSizes={w.name !== WidgetName.Map}
                        onDelete={() => { onRemoveWidget(w) }}
                        onEditSettings={(event: any, sender: any) => onEditWidget(w)}
                        isDesignMode={props.isDesignMode}
                        isVisbileEdit={showEditButton}
                        onLockToggle={() => { }}>
                        {
                            widgetObject
                        }
                    </GridItem>,
                key: w.widgetId
            } as GridItemWrapper;
        });
    }

    let gridLayoutData = { lg: props.layout?.data ?? [] };

    // Fix for container grid reset. Do not try to render if we have no widgets and layout is not empty.
    let renderGrid = true;
    if (IsUndefinedOrNull(containerLayoutWidgets) && gridLayoutData.lg.length > 0) {
        console.log("No widgets, with non empty-layout. Skipping grid rendering.")
        renderGrid = false;
    }



    const compatibleWidget = droppingItem?.name !== "mapWidget" && droppingItem?.name !== "genericContainerWidget" && droppingItem?.name !== "sortingContainerWidget";

    return (

        <div className={style.genericContainer}>
            {
                !compatibleWidget &&
                <div className={style.warning}>
                    <div>
                        <span className={style.x}></span>
                        <p>This widget cannot be added</p>
                    </div>
                </div>
            }
            {
                gridLayoutData === null &&
                <div></div>
            }

            {
                !StringIsNullOrWhiteSpace(props.title) && <ContainerTitle title={props.title} style={props.style?.containerTitle} />
            }

            {
                renderGrid &&
                <Responsive
                    key={100}
                    className={classNames(style.layoutGenericContainer, "animated")}
                    rowHeight={3}
                    measureBeforeMoun={true}
                    useCSSTransforms={true}
                    layouts={gridLayoutData}
                    layout={gridLayoutData}
                    data-grid={gridLayoutData}
                    breakpoints={breakpoints}
                    cols={{ lg: 96, md: 24, sm: 6, xs: 4, xxs: 2 }}
                    width={2000}
                    compactType={null}
                    autoSize={false}
                    droppingItem={droppingItem}
                    resizeHandles={['se']}
                    isDraggable={props.isDesignMode}
                    isResizable={props.isDesignMode}
                    onBreakpointChange={HandleBreakPointChange}
                    onLayoutChange={HandleLayoutChange}
                    isDroppable={compatibleWidget}
                    onDropDragOver={onDropDragOver}
                    onDrop={onDrop}
                    preventCollision={true}
                    allowOverlap={false}
                    parentXPos={sizeContext.left}
                    parentYPos={sizeContext.top}
                >
                    {
                        items.map(i => {
                            return <div onMouseEnter={onMouseOver} onMouseLeave={onMouseOut} className={style.widgetcontainer} key={i.key}>{i.jsx}</div>
                        })
                    }
                </Responsive>
            }
        </div>
    )
}
