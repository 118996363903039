import { ReactElement, useContext, useEffect, useState } from "react";
import { SortingContainer } from "../../components/SortingContainer/SortingContainer";
import { WidgetOptions } from "../../components/WidgetOptions";
import { SortingContainerConfigValidator } from "../../components/WidgetOptions/SortingContainer/SortingContainerConfigValidator";
import { SortingContainerDataTab, SortingContainerDataTabName } from "../../components/WidgetOptions/SortingContainer/SortingContainerDataTab";
import { SortingContainerDesignTab, SortingContainerDesignTabName } from "../../components/WidgetOptions/SortingContainer/SortingContainerDesignTab";
import { SortingContainerMainTab, SortingContainerMainTabName } from "../../components/WidgetOptions/SortingContainer/SortingContainerMainTab";
import { Tab } from "../../components/WidgetOptions/Tab";
import { ResizableBox, ResizeCallbackData } from "react-resizable";

import style from '../../scss/devview.module.scss';
import { WidgetConfigBinder } from "../../services/WidgetConfigBinder";
import { Layout } from "../../models/DashboardLayout";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/Messages";
import React from "react";
import { WidgetConfiguration } from "../../models/WidgetConfiguration";
import { Dictionary } from "../../interfaces/Dictionary";
import { UserPsaps } from "../../models/UserPsaps";
import { WidgetFactory } from "../../services/WidgetFactory";
import { Metric } from "../../models/Metric";
import { SortingContainerSortingTab, SortingContainerSortingTabName } from "../../components/WidgetOptions/SortingContainer/SortingContainerSortingTab";
import { AppContext } from "../../interfaces/AppContext";

export interface SortingContainerDevViewProps {
    layouts: Layout[];
    layoutsWidgets: Dictionary<WidgetConfiguration[]>;
    psaps: UserPsaps[];
    metrics: Metric[];
    isDesignMode: boolean;
}
export const SortingContainerDevView = (props: SortingContainerDevViewProps): ReactElement => {

    const containerLayout = props.layouts[1];
    let { localization } = useContext(AppContext);
    let widgetFactory = new WidgetFactory("", props.psaps, props.metrics, props.layouts, props.layoutsWidgets, true, null, localization, null, false);


    const tabs = [
       

        new Tab({
            name: SortingContainerDataTabName,
            label: localization["configTab1"],
            component: <SortingContainerDataTab psaps={props.psaps} />
        }),

        new Tab({
            name: SortingContainerMainTabName,
            label:localization["configTab2"],
            component: <SortingContainerMainTab psaps={props.psaps} />
        }),

        new Tab({
            name: SortingContainerSortingTabName,
            label: localization["configTab4"],
            component: <SortingContainerSortingTab psaps={props.psaps} />
        }),

        new Tab({
            name: SortingContainerDesignTabName,
            label:localization["configTab3"],
            component: <SortingContainerDesignTab />
        })
    ];

    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(null);
    binder.initialize(tabs);


    const [height, setHeight] = useState(1000);
    const [width, setWidth] = useState(1000);

    const onResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
        setHeight(data.size.height);
        setWidth(data.size.width);
    };

    useEffect(() => {
        if (props.layouts.length > 0) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.LoadLayoutData,
                data: {
                    layoutId: props.layouts[0]?.id
                }
            });
        }
    }, []);


    return (<div className={style.container}>
        <h3>Component development</h3>

        <div className={style.multiringcontainer}>
            <div>
                <WidgetOptions
                    activeTab={"Data"}
                    title="Edit Sorting Container Widget"
                    configBinder={binder}
                    tabs={tabs}
                    onBeforeTabChange={(tab) => { return true; }}
                    onTabChange={tab => { }}

                    validators={SortingContainerConfigValidator}

                    onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                        if (allTabsValid) {
                            console.log("Configuration valid: ", config);
                        }
                        else {
                            console.log("Configuration invalid. Errors: ", errors);
                            console.log(config);
                        }
                    }}
                />
            </div>

            <ResizableBox height={height} width={width} onResize={onResize}>
                <div className={style.resizewidget}>
                    {
                        Array.isArray(containerLayout?.data) &&
                        <SortingContainer
                            widgetId={"TestSortingContainer"}
                            parentLayoutId={null}
                            layoutWidgets={props.layoutsWidgets?.[props.layouts[1].id]}
                            title={"Test container"}
                            layout={containerLayout}
                            isDesignMode={props.isDesignMode}
                            factory={widgetFactory}
                            subtitles={["Title 1", "Title 2"]}
                            selectedPsaps={props.psaps}
                            masterContainerHeight={250}
                        />
                    }
                    {
                        !Array.isArray(containerLayout?.data) &&
                        <strong>no layout data</strong>
                    }
                </div>
            </ResizableBox>
        </div>

    </div>);
}