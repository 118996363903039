export enum Message {
    Test = 100,
    TestAsync = 101,

    HubDisconnected = 199,
    ConnectToHub = 200,
    DisconnectFromHub = 201,

    ChangeUser,
    ChangePSAPView,

    CreateLayout,
    RenameLayout,
    DuplicateLayout,
    ShareLayout,
    DeleteLayout,
    UpdateLayout,
    SelectLayout,
    ToggleWidgetStoreTab,
    EditContainerWidget,
    StartDragWidget,
    EndDragWidget,

    LayoutDataChanged,
    RefreshLayoutState,
    LoadLayoutData,
    AddWidget,
    RemoveWidget,
    UpdateWidgetConfiguration,
    UpdateMasterContainerHeight,
    DuplicateWidget,
    ConnectionStateChange,
    ToggleDashboardViewMode,
    ToggleWidgetsMenu,
    OpenWidgetsMenu,
    CloseWidgetsMenu,
    PushNotification,
    AcknowledgeNotification,// you can either remove or updat its flag
    EmitStateChange,
    StreamLogging,
    UpdatePSAPShape,
    ChangePSAPFilter,
    ToggleMapCallPlotting
}