import classNames from "classnames";
import { OptionType } from "../../../models/OptionType";
import style from './style.module.scss';
import Switch from "react-switch";
import { useEffect, useState } from "react";

export type MapPsapThresholdValues = {
    psapId: string | number;
    minThresholdEnabled: boolean;
    maxThresholdEnabled: boolean;
    thresholdValues?: MapThresholdValues[];
}

export type MapThresholdValues = {
    metric: string | number;
    min: number;
    max: number;
}

export interface MapThresholdConfigProps {
    selectedPsaps: OptionType[];
    selectedMetrics: OptionType[];
    tresholdValues: MapPsapThresholdValues[];
    onCancel: () => void;
    onThresholdValuesChanged?: (data: MapPsapThresholdValues[]) => void;
    onSave: (data: MapPsapThresholdValues[]) => void;
}

export const MapThresholdConfig = (props: MapThresholdConfigProps): JSX.Element => {
    // Data to save
    let thresholdData = props.tresholdValues;

    // Values to track changes
    let thresholdEnabledData: { [key: string]: boolean } = {};
    let thresholdValuesData: { [key: string]: number } = {};
    let thresholdValuesValidData: { [key: string]: boolean } = {};

    // Use State Constants
    const [thresholdEnabled, setThresholdEnabled] = useState(thresholdEnabledData);
    const [thresholdValues, setThresholdValues] = useState(thresholdValuesData);
    const [thresholdValuesValid, setThresholdValuesValid] = useState(thresholdValuesValidData);
    const [popupValid, setPopupValid] = useState(true);

    thresholdData.forEach((psap) => {
        thresholdEnabledData[psap.psapId + "_min"] = psap.minThresholdEnabled;
        thresholdEnabledData[psap.psapId + "_max"] = psap.maxThresholdEnabled;
        psap.thresholdValues.forEach((metric) => {
            thresholdValuesData[psap.psapId + "_" + metric.metric + "_min"] = metric.min;
            thresholdValuesData[psap.psapId + "_" + metric.metric + "_max"] = metric.max;

            // Revalidate the input
            if (psap.minThresholdEnabled && psap.maxThresholdEnabled) {
                if (metric.min >= metric.max) {
                    thresholdValuesValidData[psap.psapId + "_" + metric.metric + "_min"] = false;
                    thresholdValuesValidData[psap.psapId + "_" + metric.metric + "_max"] = false;
                }
            }
        });
    });

    // ************************************************************************************************************************************
    // Threshold Enabled
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Min Threshold Enabled
    // ************************************************************************************************************************************
    const InitMinThresholdEnabldedValue = (psapId: string | number): boolean => {
        return thresholdData?.find(p => p.psapId === psapId)?.minThresholdEnabled ?? false;
    };

    const onUseMinThresholdChange = (psapId: string | number): void => {
        if (typeof thresholdEnabledData[psapId + "_min"] !== 'undefined') {
            thresholdEnabledData[psapId + "_min"] = !thresholdEnabledData[psapId + "_min"];
            if (typeof thresholdData.find(p => p.psapId === psapId) !== 'undefined') {
                thresholdData.find(p => p.psapId === psapId).minThresholdEnabled = thresholdEnabledData[psapId + "_min"];
            }
        }
        else {
            thresholdEnabledData[psapId + "_min"] = true;
            if (typeof thresholdData.find(p => p.psapId === psapId) !== 'undefined') {
                thresholdData.find(p => p.psapId === psapId).minThresholdEnabled = thresholdEnabledData[psapId + "_min"];
            }
        }

        // Re-validate input for the PSAP
        ValidatePsapInput(psapId);

        setThresholdEnabled(thresholdEnabledData);
        ValidatePopup();

        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdData);
    };
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Max Threshold Enabled
    // ************************************************************************************************************************************
    const InitMaxThresholdEnabldedValue = (psapId: string | number): boolean => {
        return thresholdData?.find(p => p.psapId === psapId)?.maxThresholdEnabled ?? false;
    };

    const onUseMaxThresholdChange = (psapId: string | number): void => {
        if (typeof thresholdEnabledData[psapId + "_max"] !== 'undefined') {
            thresholdEnabledData[psapId + "_max"] = !thresholdEnabledData[psapId + "_max"];
            if (typeof thresholdData.find(p => p.psapId === psapId) !== 'undefined') {
                thresholdData.find(p => p.psapId === psapId).maxThresholdEnabled = thresholdEnabledData[psapId + "_max"];
            }
        }
        else {
            thresholdEnabledData[psapId + "_max"] = true;
            if (typeof thresholdData.find(p => p.psapId === psapId) !== 'undefined') {
                thresholdData.find(p => p.psapId === psapId).maxThresholdEnabled = thresholdEnabledData[psapId + "_max"];
            }
        }

        // Re-validate input for the PSAP
        ValidatePsapInput(psapId);

        setThresholdEnabled(thresholdEnabledData);
        ValidatePopup();

        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdData);
    };
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Threshold Min
    // ************************************************************************************************************************************
    const InitialMinThresholdValue = (psapId: string | number, metricId: string | number): number => {
        let min = 0;

        if (thresholdData !== null && typeof (thresholdData.find(p => p.psapId === psapId)) !== 'undefined') {
            if (typeof (thresholdData.find(p => p.psapId === psapId).thresholdValues) !== 'undefined' &&
                typeof (thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId)) !== 'undefined') {
                if (Number.isInteger(thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).min)) {
                    return thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).min;
                }
            }
        }

        return min;
    };

    const onChangeMinThreshold = (psapId: string | number, metricId: string | number, newValue: number): void => {
        if (newValue < 0) {
            thresholdValuesData[psapId + "_" + metricId + "_min"] = 0;
            setThresholdValues(thresholdValuesData);
            thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).min = thresholdValuesData[psapId + "_" + metricId + "_min"];
        }
        else {
            thresholdValuesData[psapId + "_" + metricId + "_min"] = newValue;
            setThresholdValues(thresholdValuesData);
            thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).min = thresholdValuesData[psapId + "_" + metricId + "_min"];
        }

        ValidateInput(psapId, metricId);
        ValidatePopup();

        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdData);
    };
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Threshold Max 
    // ************************************************************************************************************************************
    const InitialMaxThresholdValue = (psapId: string | number, metricId: string | number): number => {
        let max = 100;

        if (thresholdData !== null && typeof (thresholdData.find(p => p.psapId === psapId)) !== 'undefined') {
            if (typeof (thresholdData.find(p => p.psapId === psapId).thresholdValues) !== 'undefined' &&
                typeof (thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId)) !== 'undefined') {
                if (Number.isInteger(thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).max)) {
                    return thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).max;
                }
            }
        }

        return max;
    };

    const onChangeMaxThreshold = (psapId: string | number, metricId: string | number, newValue: number): void => {
        if (newValue < 0) {
            newValue = newValue * -1;
        }
        else {
            thresholdValuesData[psapId + "_" + metricId + "_max"] = newValue;
            setThresholdValues(thresholdValuesData);
            thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId).max = thresholdValuesData[psapId + "_" + metricId + "_max"];
        }

        ValidateInput(psapId, metricId);
        ValidatePopup();

        typeof props.onThresholdValuesChanged === "function" && props.onThresholdValuesChanged(thresholdData);
    };
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Popup Valid
    // ************************************************************************************************************************************
    const ValidatePopup = () => {
        let popupValid = true;
        for (let i = 0; i < thresholdData.length; i++) {
            if (!thresholdData[i].minThresholdEnabled && !thresholdData[i].maxThresholdEnabled) continue;
            else if (thresholdData[i].minThresholdEnabled && thresholdData[i].maxThresholdEnabled) {
                for (let j = 0; j < thresholdData[i].thresholdValues.length; j++) {
                    const metricThresholdData = thresholdData[i].thresholdValues[j];
                    if (metricThresholdData.min >= metricThresholdData.max) {
                        popupValid = false;
                        break;
                    }
                }

                if (popupValid === false) break;
            }
        }
        setPopupValid(popupValid);
    }
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Threshold Valid
    // ************************************************************************************************************************************
    const ValidateInput = (psapId: string | number, metricId: string | number) => {
        const thresholdPsapMetricData = thresholdData.find(p => p.psapId === psapId).thresholdValues.find(m => m.metric === metricId);
        if (thresholdPsapMetricData.min >= thresholdPsapMetricData.max) {
            thresholdValuesValidData[psapId + "_" + metricId + "_min"] = false;
            thresholdValuesValidData[psapId + "_" + metricId + "_max"] = false;
            setThresholdValuesValid(thresholdValuesValidData);
        }
        else {
            thresholdValuesValidData[psapId + "_" + metricId + "_min"] = true;
            thresholdValuesValidData[psapId + "_" + metricId + "_max"] = true;
            setThresholdValuesValid(thresholdValuesValidData);
        }
    };

    const ValidatePsapInput = (psapId: string | number) => {
        const psapData = thresholdData.find(p => p.psapId === psapId);
        if (typeof psapData !== 'undefined' && psapData.minThresholdEnabled && psapData.maxThresholdEnabled) {
            psapData.thresholdValues.forEach((threshold) => {
                ValidateInput(psapId, threshold.metric);
            })
        }
    }
    // ************************************************************************************************************************************  

    // ************************************************************************************************************************************
    // Save Data
    // ************************************************************************************************************************************
    const cleanData = (): void => {
        thresholdData.forEach((item) => {
            if (typeof props.selectedPsaps.find(p => p.value === item.psapId) === 'undefined') {
                thresholdData.splice(thresholdData.indexOf(item), 1);
            }
            else {
                item.thresholdValues.forEach((value) => {
                    if (typeof props.selectedMetrics.find(m => m.value === value.metric) === 'undefined') {
                        item.thresholdValues.splice(item.thresholdValues.indexOf(value), 1);
                    }
                })
            }
        })
    };

    const onSave = (): void => {
        // Clean the data
        cleanData();

        // Save the data
        typeof props.onSave === "function" && props.onSave(thresholdData);
    };
    // ************************************************************************************************************************************

    // ************************************************************************************************************************************
    // Run useEffect only once and only if threshold data is empty.
    // ************************************************************************************************************************************
    useEffect(() => {
        // Initialize data (if empty)
        if (thresholdData === null || thresholdData.length === 0) {
            props.selectedPsaps.forEach((psap) => {
                let psapThreshold: MapPsapThresholdValues = {
                    psapId: psap.value,
                    minThresholdEnabled: false,
                    maxThresholdEnabled: false,
                    thresholdValues: []
                };
                props.selectedMetrics.forEach((metric) => {
                    let metricThreshold: MapThresholdValues = {
                        metric: metric.value,
                        min: 0,
                        max: 100
                    }
                    psapThreshold.thresholdValues.push(metricThreshold);
                });
                thresholdData.push(psapThreshold);
            });

            thresholdData.forEach((psap) => {
                thresholdEnabledData[psap.psapId + "_min"] = psap.minThresholdEnabled;
                thresholdEnabledData[psap.psapId + "_max"] = psap.maxThresholdEnabled;
                psap.thresholdValues.forEach((metric) => {
                    thresholdValuesData[psap.psapId + "_" + metric.metric + "_min"] = metric.min;
                    thresholdValuesData[psap.psapId + "_" + metric.metric + "_max"] = metric.max;
                    thresholdValuesValidData[psap.psapId + "_" + metric.metric + "_min"] = true;
                    thresholdValuesValidData[psap.psapId + "_" + metric.metric + "_max"] = true;
                });
            });

            setThresholdEnabled(thresholdEnabledData);
            setThresholdValues(thresholdValuesData);
            setThresholdValuesValid(thresholdValuesValidData);
        };

        localStorage.setItem("MapThresholdData", JSON.stringify(thresholdData));
    }, []);
    // ************************************************************************************************************************************

    return (
        <div className={style.thresholdConfigContainer}>
            <div className={style.header}>
                <div className={style.title}>
                    Set Threshold Values
                </div>
                <div className={style.buttons}>
                    <button className={classNames(style.button, style.cancel)} onClick={props.onCancel}>Cancel</button>
                    <button disabled={!popupValid} className={classNames(style.button, style.save)} onClick={onSave}>Save</button>
                </div>
            </div>
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.widthPSAPMetricTitle}>PSAP</div>
                    <div className={style.widthPSAPMetricTitle}>Metric</div>
                    <div className={style.widthThresholdTitle}>Min Threshold</div>
                    <div className={style.widthThresholdTitle}>Minimum</div>
                    <div className={style.widthThresholdTitle}>Max Threshold</div>
                    <div className={style.widthThresholdTitle}>Maximum</div>
                </div>
                <div className={style.data}>
                    {props.selectedPsaps.map((psap, psapIndex) => (
                        <div className={classNames(style.dataRow, psapIndex % 2 !== 0 ? style.dataRowOdd : null)} key={psapIndex}>
                            <div className={style.widthPSAPMetricData}>{psap.label}</div>
                            <div className={style.widthPSAPMetricData}>
                                {
                                    props.selectedMetrics.map((metric, metricIndex) => (
                                        <div className={style.metric} key={metric.value}>{metric.label}</div>
                                    ))
                                }
                            </div>
                            <div className={style.widthThresholdData}>
                                <Switch
                                    onColor={"#0277CB"}
                                    activeBoxShadow={null}
                                    height={20}
                                    width={48}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    checked={typeof (thresholdEnabled[psap.value]) !== 'undefined' ? thresholdEnabled[psap.value] : InitMinThresholdEnabldedValue(psap.value)}
                                    onChange={() => { onUseMinThresholdChange(psap.value) }}
                                />
                            </div>
                            <div className={style.widthThresholdData}>
                                {
                                    props.selectedMetrics.map((metric) => (
                                        <div className={style.thresholdInput} key={metric.value}>
                                            <input disabled={typeof (thresholdEnabled[psap.value]) !== 'undefined' ? !thresholdEnabled[psap.value] : !InitMinThresholdEnabldedValue(psap.value)}
                                                className={classNames("form-control", typeof (thresholdEnabled[psap.value + "_min"]) !== 'undefined' && thresholdEnabled[psap.value + "_min"] && typeof (thresholdEnabled[psap.value + "_max"]) !== 'undefined' && thresholdEnabled[psap.value + "_max"] && typeof thresholdValuesValid[psap.value + "_" + metric.value + "_min"] !== 'undefined' && !thresholdValuesValid[psap.value + "_" + metric.value + "_min"] ? "warn" : "")}
                                                min={0}
                                                onBlur={() => { }}
                                                type="number"
                                                value={typeof (thresholdValues[psap.value + "_" + metric.value + "_min"]) !== 'undefined' ? thresholdValues[psap.value + "_" + metric.value + "_min"] : InitialMinThresholdValue(psap.value, metric.value)}
                                                onChange={(e) => onChangeMinThreshold(psap.value, metric.value, +e.target.value)} />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className={style.widthThresholdData}>
                                <Switch
                                    onColor={"#0277CB"}
                                    activeBoxShadow={null}
                                    height={20}
                                    width={48}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    checked={typeof (thresholdEnabled[psap.value]) !== 'undefined' ? thresholdEnabled[psap.value] : InitMaxThresholdEnabldedValue(psap.value)}
                                    onChange={() => { onUseMaxThresholdChange(psap.value) }}
                                />
                            </div>
                            <div className={style.widthThresholdData}>
                                {
                                    props.selectedMetrics.map((metric) => (
                                        <div className={style.thresholdInput} key={metric.value}>
                                            <input disabled={typeof (thresholdEnabled[psap.value]) !== 'undefined' ? !thresholdEnabled[psap.value] : !InitMaxThresholdEnabldedValue(psap.value)}
                                                className={classNames("form-control", typeof thresholdEnabled[psap.value + "_min"] !== 'undefined' && thresholdEnabled[psap.value + "_min"] && typeof thresholdEnabled[psap.value + "_max"] !== 'undefined' && thresholdEnabled[psap.value + "_max"] && typeof thresholdValuesValid[psap.value + "_" + metric.value + "_max"] !== 'undefined' && !thresholdValuesValid[psap.value + "_" + metric.value + "_max"] ? "warn" : "")}
                                                min={0}
                                                onBlur={() => { }}
                                                type="number"
                                                value={typeof (thresholdValues[psap.value + "_" + metric.value + "_max"]) !== 'undefined' ? thresholdValues[psap.value + "_" + metric.value + "_max"] : InitialMaxThresholdValue(psap.value, metric.value)}
                                                onChange={(e) => onChangeMaxThreshold(psap.value, metric.value, +e.target.value)} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}