import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { MapDataAdapterProps } from "../components/MapDataAdapter";
import { MapDataTabName, MapDataTabProps } from "../components/WidgetOptions/Map/MapDataTab";
import { MapDesignTabProps, MapDesignTabName } from "../components/WidgetOptions/Map/MapDesignTab";
import { MapMainTabProps, MapMainTabName } from "../components/WidgetOptions/Map/MapMainTab";
import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import { MapWidgetProps, PsapDetails } from '@ecats911/edash-widgets-map';
import { DefaultIfUndefinedOrNull, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { defaultTitleFontIndex, defaultTitleFontSize, FontOptions } from "../misc/Constants";

export class MapConfigAdapter extends WidgetConfigAdapter<MapWidgetProps> {
    main: MapMainTabProps;
    data: MapDataTabProps;
    design: MapDesignTabProps;
    psaps: UserPsaps[];
    metrics: Metric[];
    selectedMetrics: Metric[];

    constructor(config: object, psaps: UserPsaps[], metrics: Metric[], sortOrder: any) {
        super();
        this.main = config[MapMainTabName];
        this.data = config[MapDataTabName];
        this.design = config[MapDesignTabName];


        if (IsUndefinedOrNull(this.main))
            this.main = {};

        if (IsUndefinedOrNull(this.data))
            this.data = { psaps: psaps, metrics: metrics, sortOrder: sortOrder };

        if (IsUndefinedOrNull(this.design))
            this.design = {};

        this.psaps = psaps;
        this.metrics = metrics;
    }

    getDataConfiguration = (): MapDataAdapterProps => {

        const selectedMetricIds = Array.isArray(this.data.selectedMetrics) && this.data.selectedMetrics.length > 0 ? this.data.selectedMetrics : [];

        const selectedPsapNenaIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds : [];

        const p: MapDataAdapterProps = {
            metricDefinitions: this.metrics.filter((m: Metric) => selectedMetricIds.some(x => x === m.id)).map(g => g),
            psapNenaIds: this.psaps.filter((p: UserPsaps) => selectedPsapNenaIds.some(x => x === p.nenaIdentifier)).map(g => g.nenaIdentifier)
        };

        this.selectedMetrics = p.metricDefinitions;

        return p;
    };

    getWidgetConfiguration = (): MapWidgetProps => {

        const styles = {
            title: {
                color: this.design.titleFontColor,
                fontSize: `${DefaultIfUndefinedOrNull(this.design.titleFontSize, defaultTitleFontSize)}px`,
                fontFamily: this.design?.titleFontValue ? FontOptions[this.design?.titleFontValue].label : FontOptions[defaultTitleFontIndex].label
            }
        };

        const selectedPsapIds = Array.isArray(this.data.selectedPsapIds) && this.data.selectedPsapIds.length > 0 ? this.data.selectedPsapIds : [];
        let psapData = this.psaps.filter((p: UserPsaps) => selectedPsapIds.some(x => x === p.nenaIdentifier)).map(g => g);
        let mapPsapDetails: PsapDetails[] = psapData.map(p => {
            let psapDetail: PsapDetails = {
                id: p.psapId,
                psapId: p.nenaIdentifier,
                location: new google.maps.LatLng(p.latitude, p.longitude),
                details: {
                    "PSAP Name": !StringIsNullOrWhiteSpace(p.psapName) ? p.psapName : "Unknown",
                    "FCC Id": p.fccId.toString(),
                    "Agency Type": !StringIsNullOrWhiteSpace(p.agencyTypeName) ? p.agencyTypeName : "Unknown"
                },
                shapeCoordinates: p.shapeCoords
            };

            return psapDetail;
        });

        const model: MapWidgetProps = {
            title: this.main.title,
            style: styles,
            cluster: true,
            psaps: mapPsapDetails,
            thresholds: this.data.thresholdValues,
            selectedMetrics: this.selectedMetrics,
            selectedPSAPIds: selectedPsapIds
        };

        return model;
    }
}