
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IsFunction, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from '../misc/Helpers';
import { OptionsTab } from './WidgetOptions/Tab';
import { ConfigBinder, WidgetConfig } from '../services/WidgetConfigBinder';

import style from '../scss/widgetoptions.module.scss';
import React from 'react';
import { Dictionary } from '../interfaces/Dictionary';

export interface WidgetOptionsProps {

    tabs: OptionsTab[];
    activeTab?: string;
    title?: string;
    configBinder: ConfigBinder;

    onDelete?: () => void;
    onHelp?: () => void;

    onBeforeTabChange?: (tab: TabState, isValid: boolean, errors: string[]) => boolean;

    onTabChange?: (tab: TabState) => void;

    onClose?: () => void;

    onSaveChanges: (isValid: boolean, errors: string[], config: any) => void;

    validators?: ((...args: any[]) => ValidationResult)[];
}

export interface TabState {
    enabled: boolean;
    name: string;
    label: string;
    isDirty: boolean;
    isActive: boolean;
    hasErrors: boolean;
}

interface OptionsContext {
    //configuration :  Dictionary<WidgetConfig>;
    data: Dictionary<WidgetConfig>;
}

export interface ValidationResult {
    isValid: boolean;
    message: string;
    tabName?: string;
    fieldName?:string;
}

export const TabContext = React.createContext<OptionsContext>({
    data: {}
});

export const WidgetOptions = (props: WidgetOptionsProps) => {

    const tabState = props.tabs.map(t => {
        return {
            enabled: t.enabled === false ? false : true,
            name: t.name,
            isDirty: false,
            isActive: t.label === props.activeTab,
            hasErrors: false,
            label: t.label
        } as TabState
    });

    const [tabs, setTabs] = useState(tabState);

    let activeTab: OptionsTab = null;
    const activeInState = tabs.find(t => t.isActive);

    const onTabClick = (event, tab: TabState): void => {

        if (tab.enabled === false) {
            return;
        }

        let invokeTabChange = true;

        if (typeof props.onBeforeTabChange === "function") {
            const { isValid, errors } = runValidators();

            invokeTabChange = props.onBeforeTabChange(tab, isValid, errors);
        }

        if (invokeTabChange) {
            setTabs(currentState => {

                const nextState = currentState.map(t => {
                    t.isActive = t.name === tab.name;
                    return t;
                });

                return nextState;
            });
        }

        if (typeof props.onTabChange === "function" && invokeTabChange) {
            props.onTabChange(tab);
        }

        window.setTimeout(() => {
            runValidators();
        }, 100);
    };

    const onClose = (): void => {
        if (IsFunction(props.onClose)) {
            props.onClose();
        }

        localStorage.removeItem("MapThresholdData");
    };

    const buildTabClass = (tab: TabState): string => {
        let css = style.tab;

        if (tab.enabled === false) {
            css = classNames(css, style.disabled);
        }

        if (tab.isActive) {
            css = classNames(css, style.active);
        }

        if (tab.isDirty) {
            css = classNames(css, style.dirty);
        }

        if (tab.hasErrors) {
            css = classNames(css, style.warn);
        }

        return css;
    }

    const runValidators = (): any => {

        if (IsUndefinedOrNull(props.validators)) {
            return { isValid: true, errors: [] };
        }

        const propArgs = props.tabs.map(tab => props.configBinder.getProps(tab.name, tab.component));

        const results = props.validators.map(fn => IsFunction(fn) && fn(...propArgs));

        const formsValid = results.every(r => r.isValid);

        const errors = results.filter(p => !p.isValid).map(p => p.message);

        // set red dot to tabs who fail validations
        let problematicTabs = results.filter(p => !StringIsNullOrWhiteSpace(p.tabName) && p.isValid === false).map(p => p.tabName);
        problematicTabs = [... new Set(problematicTabs)];

        setTabs(state => {
            const nextState = state.map(t => {
                if (problematicTabs.includes(t.name)) {
                    t.hasErrors = true;
                }
                else {
                    t.hasErrors = false;
                }
                return t;
            });
            return nextState;
        });


        return { isValid: formsValid, errors: errors };
    };

    const onSaveChanges = (): void => {

        const { isValid, errors } = runValidators();

        if (isValid) {
            clearDirtyFlags();
        }

        const config = props.configBinder.getConfiguration();

        // Remove local storage for map since we will no longer need it
        for (let key in config) {
            if (key.includes('Map')) {
                localStorage.removeItem("MapThresholdData");
                break;
            }
        }

        IsFunction(props.onSaveChanges) && props.onSaveChanges(isValid, errors, config);
    };

    props.configBinder.onDataChange = (tabComponentName: string, property: string, value: any): void => {

        setTabs(state => {
            const nextState = state.map(t => {
                if (t.name === activeInState.name) {
                    t.isDirty = true;
                }
                return t;
            });

            return nextState;
        });
    };

    const clearDirtyFlags = (): void => {
        setTabs(state => {
            const nextState = state.map(t => {
                t.isDirty = false;
                return t;
            });

            return nextState;
        });
    };


    if (!IsUndefinedOrNull(activeInState)) {
        activeTab = props.tabs.find(t => t?.name === activeInState.name);
    }

    // const [binderData, setBinderData] = useState({} as Dictionary<WidgetConfig>);

    /*
    useEffect(() => {
        props.configBinder.initialize(props.tabs.map(t => t.component));
        const data = props.configBinder.getData();
        setBinderData(data);
    }, []);*/

    return (

        <div className={style.container}>
            <div className={style.header}>
                <div className={style.f}>
                    <div className={style.g}>
                        {
                            StringIsNullOrWhiteSpace(props.title) &&
                            <span>Edit Widget</span>
                        }
                        {
                            !StringIsNullOrWhiteSpace(props.title) &&
                            <span>{props.title}</span>
                        }
                        <div>
                            {
                                IsFunction(props.onDelete) &&
                                <button onClick={props.onDelete} title="delete" className={classNames(style.iconButton, style.trash)}>
                                    <FontAwesomeIcon icon={"trash"} />
                                </button>
                            }

                            {
                                IsFunction(props.onHelp) &&
                                <button onClick={props.onHelp} className={style.iconButton}>
                                    <span>?</span>
                                </button>
                            }
                            <button onClick={onClose} className={style.iconButton}>
                                <FontAwesomeIcon icon={"x"} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.body}>
                <div className={style.q}>
                    <div className={style.c}>
                        <div className={style.tabs}>
                            {
                                tabs.map((tab: TabState, idx: number) => {

                                    return (
                                        <button
                                            className={buildTabClass(tab)}
                                            key={idx}
                                            onClick={e => onTabClick(e, tab)}>
                                            <span>{tab.label}</span>
                                        </button>)
                                })
                            }
                        </div>
                        <button onClick={onSaveChanges} className={style.saveButton}>
                            <FontAwesomeIcon icon={"floppy-disk"} />
                            <span>Save</span>
                        </button>
                    </div>
                    <div className={style.options}>
                        <TabContext.Provider value={{ data: props.configBinder.getData() }}>
                            {
                                activeTab !== null &&
                                activeTab.withConfiguration(props.configBinder).render()
                            }
                        </TabContext.Provider>
                    </div>

                </div>

            </div>

        </div>
    );
};