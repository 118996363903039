import React, { useContext, useEffect } from "react";
import { app } from "..";
import { AdminSettings } from "../components/AdminSettingsDialog";
import { AppContext } from "../interfaces/AppContext";
import { Dictionary } from "../interfaces/Dictionary";
import { DefaultWidgetDimensions, PubSubTopic } from "../misc/Constants";
import { DefaultIfUndefinedOrNull, IsUndefinedOrNull } from "../misc/Helpers";
import { Message } from "../misc/Messages";
import { GridLayout, Layout } from "../models/DashboardLayout";
import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import { WidgetConfiguration } from "../models/WidgetConfiguration";
import { LoadingScreen } from "../pages/LoadingScreen";
import { RegionalViewProps } from "../pages/RegionalView";
import { WidgetFactory } from "./WidgetFactory";


export interface RegionalViewLoaderProps {

    psaps: UserPsaps[];

    metrics: Metric[];

    children?: JSX.Element;

    layouts: Layout[];

    layoutsWidgets: Dictionary<WidgetConfiguration[]>;

    isDesignMode: boolean;

    adminSettings: AdminSettings;

    mapWidgetInEdit: boolean;
}

export const RegionalViewLoader = (props: RegionalViewLoaderProps): JSX.Element => {

    const { user, localization, psapFilter } = useContext(AppContext);

    const activeLayoutId = user.account.settings.activeLayoutId;

    const regionalLayouts = props.layouts.filter(l => l.context === "regional");

    const activeLayout = regionalLayouts.find(l => l.id === activeLayoutId);



    useEffect(() => {


        if (regionalLayouts.length === 0) {

            console.log("request create layout");

            PubSub.publish(PubSubTopic.Action, {
                id: Message.CreateLayout,
                data: {
                    layoutName: "default",
                    context: "regional",
                    ownerId: user.account.id
                }
            });
        }
        else if (IsUndefinedOrNull(activeLayout)) {

            const lastLayout = regionalLayouts[regionalLayouts.length - 1];

            PubSub.publish(PubSubTopic.Action, {
                id: Message.SelectLayout,
                data: {
                    layoutId: lastLayout.id
                }
            });
        }
    });


    if (regionalLayouts.length === 0) {
        return <LoadingScreen message="Preparing your workspace..." />;
    }

    if (IsUndefinedOrNull(activeLayout)) {
        return <LoadingScreen message="Selecting layout..." />;
    }

    if (activeLayout) {
        if (activeLayout.ownerId !== user.account.userId) {
            app.store.state.isDesignMode = false;
        }
    }


    const debug_AssureLayoutItemsRespectMinSize = (layout: Layout): Layout => {

        const defaults = DefaultWidgetDimensions;

        const items = layout.data as GridLayout[];

        if (IsUndefinedOrNull(items))
            return layout;

        return layout;
    };

    const widgets = DefaultIfUndefinedOrNull(props.layoutsWidgets[activeLayout.id], []);

    return React.cloneElement(props.children, {
        layout: debug_AssureLayoutItemsRespectMinSize(activeLayout),
        layoutId: activeLayout.id,
        layoutWidgets: widgets,
        widgetFactory: new WidgetFactory(
            activeLayoutId,
            props.psaps,
            props.metrics,
            props.layouts,
            props.layoutsWidgets,
            props.isDesignMode,
            props.adminSettings,
            localization,
            psapFilter,
            props.mapWidgetInEdit
        ),
        isDesignMode: props.isDesignMode
    } as RegionalViewProps);

}