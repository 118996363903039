import { useEffect, useState } from 'react';
import { Modal } from './Modal';
import style from "../scss/notificationsettingdialog.module.scss";
import Select from "react-select";
import { OptionType } from '../models/OptionType';
import { NotificationSetting, Recipient } from '../models/NotificationSetting';
import { WidgetEquations } from "../misc/WidgetEquations";
import { IsFunction, IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { app } from '../';
import { SortableSelect, SortableType } from "../components/SortableComponents/SortableSelect";
import { GetSortOrder, SortAscending, SortDescending } from "../components/SortableComponents/SortFunctions";
import { SortConstants } from "../components/SortableComponents/SortConstants";
import { Dictionary, Styles } from '../interfaces/Dictionary';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import { ValidationResult } from './WidgetOptions';
import classNames from 'classnames';
import { EmailRegex, Operators } from '../misc/Constants';
import { Metric, RowDefinition } from '../models/Metric';
import { TimeZone } from '../models/TimeZone';

export interface NotificationSettingDialogProps {
    onCancel: Function,
    onSave: Function,
    notificationSetting: NotificationSetting,
    userTimeZoneSettings: TimeZone,
    onChange?: (key: string, value: any) => void;
}
type NotificationFormOptions = {
    selectedMetric: Metric;
}

export const NotificationSettingDialogValidator = [
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (StringIsNullOrWhiteSpace(notification.name))
            return { isValid: false, message: "A name should be provided", fieldName: "name" }
        return { isValid: true, message: "" };
    }, (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (notification.recipients?.length < 1)
            return { isValid: false, message: "At least 1 recipient should be provided", fieldName: "recipients" }
        return { isValid: true, message: "" };
    },
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (StringIsNullOrWhiteSpace(notification.conditions[0].condition))
            return { isValid: false, message: "A condition should be selected", fieldName: "condition" }
        return { isValid: true, message: "" };
    },
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (StringIsNullOrWhiteSpace(notification.conditions[0].metricId))
            return { isValid: false, message: "At least one metric should be selected", fieldName: "metricId" }
        return { isValid: true, message: "" };
    },
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {

        const skipPSAPCheck = !IsUndefinedOrNull(options.selectedMetric) && options.selectedMetric.rowDefinition === RowDefinition.None;

        if (IsUndefinedNullOrEmpty(notification.conditions[0].psaps) && !skipPSAPCheck) {
            return { isValid: false, message: "At least one psap should be selected", fieldName: "psaps" }
        }
        else return { isValid: true, message: "" };
    },
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (IsUndefinedNullOrEmpty(notification.conditions[0].value))
            return { isValid: false, message: "A value should be selected", fieldName: "value" }
        return { isValid: true, message: "" };
    },
    (notification: NotificationSetting, options: NotificationFormOptions): ValidationResult => {
        if (StringIsNullOrWhiteSpace(notification.conditions[0].equation) && notification.conditions[0].psaps.length > 1)
            return { isValid: false, message: "A value should be selected", fieldName: "equation" }
        return { isValid: true, message: "" };
    }
];

export const NotificationSettingDialog = (props: NotificationSettingDialogProps): JSX.Element => {
    let metrics = app.store.state.metrics;

    if (metrics && metrics.length > 0) {
        const filteredOutGroups = ['Map'];
        filteredOutGroups.forEach(item => {
            metrics = metrics.filter(m => m.metricGroup !== item);
        });
    }

    const propsPsaps = app.store.state.psaps;
    const validators = NotificationSettingDialogValidator;
    //errors
    const [validatorErrors, setValidatorErrors] = useState({});

    //name
    const [name, setName] = useState(props.notificationSetting?.name || "");

    //recipients
    const flatRecientsToString = (): string => {
        const str = props.notificationSetting?.recipients?.map(p => p.recipientAddress?.trim())?.join(", ")?.trim();
        return StringIsNullOrWhiteSpace(str) ? "" : str;
    };

    const [recipients, setRecipients] = useState(flatRecientsToString());

    const initialValue = +props.notificationSetting?.conditions[0].value;
    const [value, setValue] = useState(isNaN(initialValue) ? 0 : initialValue);

    const valueChange = (newValue: number): void => {
        if (isNaN(newValue) || newValue < 0) {
            setValue(0);
        }
        else setValue(newValue);
    };


    //conditions
    const [selectedCondition, setSelectedCondition] = useState<OptionType>(
        props.notificationSetting !== null ?
            {
                label: props.notificationSetting?.conditions[0].condition,
                value: props.notificationSetting?.conditions[0].condition
            } as OptionType
            : null
    );

    const conditionsOptions = Operators.map(m => {
        return { label: m.value, value: m.value } as OptionType
    })

    const onConditionChange = (options: OptionType): void => {
        setSelectedCondition(options);
    }

    //metric
    const metricsOptions = metrics.sort((a, b) => a.displayNameKey.localeCompare(b.displayNameKey)).map(m => {
        return { label: m.displayNameKey, value: m.id } as OptionType
    });

    const [selectedMetricOption, setSelectedMetricOption] = useState<OptionType>(props.notificationSetting !== null ?
        metricsOptions.filter(x => x.value === props.notificationSetting.conditions[0].metricId)[0] : null
    );

    const onMetricChange = (option: OptionType): void => {
        setSelectedMetricOption(option);

        const clearPSAPSelection = isDisablePSAPSelection(option)

        if (clearPSAPSelection) {
            setPSAPs([]);
            setSelectedEquation(null);
        }

        typeof props.onChange === "function" && props.onChange("selectedMetric", option?.value);
    };

    //equation
    const equationsOptions = Object.values(WidgetEquations).filter(x => x !== WidgetEquations.Percentage && x !== WidgetEquations.Average)
        .map(e => {
            return { label: e, value: e }
        });

    let selectedEquationOption: OptionType;
    selectedEquationOption = props.notificationSetting !== null && props.notificationSetting.conditions[0].equation !== "" ? {
        label: props.notificationSetting.conditions[0].equation,
        value: props.notificationSetting.conditions[0].equation
    } as OptionType : null;

    const [selectedEquation, setSelectedEquation] = useState(selectedEquationOption);

    const onEquationChange = (options: OptionType): void => {
        setSelectedEquation(options);
        typeof props.onChange === "function" && props.onChange("selectedEquation", options?.value);
    };

    // Psaps
    const psapOptions = propsPsaps.map(p => {
        return { label: p.psapName, value: p.nenaIdentifier } as OptionType
    });

    let psapList = props.notificationSetting?.conditions[0].psaps;
    if (!Array.isArray(psapList)) {
        psapList = [];
    }

    const selectedPSAPs = psapList.map(psap => {
        return psapOptions.find(x => x.value === psap);
    }).filter(psap => !IsUndefinedOrNull(psap));

    const [psaps, setPSAPs] = useState<OptionType[]>(selectedPSAPs);

    let sortOrder: Dictionary<string> = {};

    const onSaveSort = (order: string, sorted: any[], type: SortableType): void => {
        const optionsArr = Array.isArray(sorted) ? sorted : [sorted];
        if (sortOrder[SortableType[type]] !== order) {
            sortOrder[SortableType[type]] = order;
            typeof props.onChange === "function" && props.onChange("sortOrder", sortOrder);
        }

        if (type === SortableType.PSAP) {
            setPSAPs(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(m => m.value));
        }
    };

    const onPSAPSelectionChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];

        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.PSAP]] !== 'undefined' && optionsArr.length > 1) {
            if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.ascending) {
                optionsArr = SortAscending(optionsArr);
            }
            else if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.descending) {
                optionsArr = SortDescending(optionsArr);
            }
        }

        setPSAPs(optionsArr);
        typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(p => p.value));

        // If we have 1 psap selected or less, unset equation
        if (optionsArr.length <= 1) {
            setSelectedEquation(null)
            typeof props.onChange === "function" && props.onChange("selectedEquation", null);
        }
    };

    const getEmailList = (emailString: string): Recipient[] => {
        if (StringIsNullOrWhiteSpace(emailString)) {
            return [];
        }

        let emails = emailString
            .split(",")
            .map(r => r.trim())
            .filter(r => EmailRegex.test(r));

        emails = [...new Set(emails)];

        return emails.map(r => {
            return {
                recipientAddress: r,
                recipientName: r,
                method: "email"
            } as Recipient
        });
    };

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const buildFormOptionsFromState = (): NotificationFormOptions => {
        const selectedMetric = metrics.find(m => m.id === selectedMetricOption?.value);

        return {
            selectedMetric: selectedMetric
        } as NotificationFormOptions;
    };

    const runValidators = (notification: NotificationSetting, options: NotificationFormOptions): any => {

        const formOptions = buildFormOptionsFromState();

        const results = validators.map(x => IsFunction(x) && x(notification, formOptions));

        const formsValid = results.every(r => r.isValid);

        const errors = Object.fromEntries(results.filter(p => !p.isValid).map(x => [x.fieldName, x.fieldName]));

        setValidatorErrors(errors);

        return { isValid: formsValid, errors: errors };
    };

    const onRecipientsFieldBlur = (): void => {
        const list = getEmailList(recipients);
        setRecipients(list.map(p => p.recipientAddress).join(", ").trim());
    };

    const OnSave = (): void => {

        const recipientList = getEmailList(recipients);

        if (props.notificationSetting === null) {

            let newNotification: NotificationSetting;
            newNotification = {
                id: "",
                name: name,
                conditions: [{
                    condition: selectedCondition?.value.toString(),
                    metricId: selectedMetricOption?.value.toString(),
                    equation: selectedEquation?.value.toString() || "",
                    psaps: psaps.map(x => x.value.toLocaleString()),
                    value: value
                }],
                recipients: recipientList,
                timeZoneWindowsValue: props.userTimeZoneSettings.windows,
                timeZoneIdentifier: props.userTimeZoneSettings.standard,
                timeZoneAbbreviation: props.userTimeZoneSettings.abbreviation,
                active: true
            }

            if (isFormValid) {
                setValidatorErrors({});
                props.onSave(newNotification);
            }
        } else {

            let updateNotification: NotificationSetting;
            updateNotification = {
                id: props.notificationSetting.id,
                name: name,
                conditions: [{
                    condition: selectedCondition?.value.toString(),
                    metricId: selectedMetricOption?.value.toString(),
                    equation: selectedEquation?.value.toString() || "",
                    psaps: psaps.map(x => x.value.toLocaleString()),
                    value: value
                }],
                recipients: recipientList,
                timeZoneWindowsValue: props.userTimeZoneSettings.windows,
                timeZoneIdentifier: props.userTimeZoneSettings.standard,
                timeZoneAbbreviation: props.userTimeZoneSettings.abbreviation,
                active: props.notificationSetting.active
            }

            if (isFormValid) {
                setValidatorErrors({});
                props.onSave(updateNotification);
            }
        }
    }

    const modalStyles: Styles = {
        container: {
            padding: 0,
            width: '685px',
            maxWidth: '685px'
        }
    };

    const onCancel = () => {
        setValidatorErrors({});
        props.onCancel();
    }

    const isDisablePSAPSelection = (metricOption: OptionType): boolean => {
        if (IsUndefinedOrNull(metricOption)) {
            return false;
        }
        const selectedMetric = metrics.find(m => m.id === metricOption.value);

        if (IsUndefinedOrNull(selectedMetric)) {
            return false;
        }

        const ds = selectedMetric.rowDefinition === RowDefinition.None;
        return ds;
    };

    useEffect(() => {
        const recipientList = getEmailList(recipients);

        let newNotification: NotificationSetting;
        newNotification = {
            id: "",
            name: name,
            conditions: [{
                condition: selectedCondition?.value.toString(),
                metricId: selectedMetricOption?.value.toString(),
                equation: selectedEquation?.value.toString() || "",
                psaps: psaps.map(x => x.value.toLocaleString()),
                value: value
            }],
            recipients: recipientList,
            timeZoneWindowsValue: props.userTimeZoneSettings.windows,
            timeZoneIdentifier: props.userTimeZoneSettings.standard,
            timeZoneAbbreviation: props.userTimeZoneSettings.abbreviation,
            active: true
        }

        const formOptions = buildFormOptionsFromState();
        const { isValid } = runValidators(newNotification, formOptions);
        setIsFormValid(isValid);
    }, [name, selectedCondition?.value, selectedMetricOption?.value, selectedEquation?.value, psaps, value, recipients]);

    const disablePsap = isDisablePSAPSelection(selectedMetricOption);

    const prepareNotificationLabelMessage = (): JSX.Element => {
        if (IsUndefinedOrNull(selectedCondition) || IsUndefinedOrNull(selectedMetricOption)) {
            return <></>
        }

        let psapStr: string;
        let forStr: string;

        if (!IsUndefinedNullOrEmpty(psaps)) {
            psapStr = psaps.length > 1 ? `${psaps[0].label} + ${psaps.length - 1} more` : psaps[0].label;
            forStr = " for ";
        }
        const conditionLabel = Operators.find(o => o.value === selectedCondition.value).label;
        const equationLabel = IsNotUndefinedOrNull(selectedEquation) ? <> the <b>{selectedEquation.label}</b> of the </> : <>{forStr}</>

        const messageElement = <>An <strong>Email</strong> for <b>{selectedMetricOption.label}</b>,
            if it's <b>{conditionLabel} {value?.toString()}</b>{equationLabel}<b>{psapStr}</b>.</>
        return messageElement;
    }

    return (
        <Modal style={modalStyles}
            handleCancel={() => onCancel()}
            handleOk={() => OnSave()}
            headerText={"Set up a Notification"}
            cancelButtonText={"Cancel"}
            okButtonText={"Save"}
            okDisabled={!isFormValid}>
            {
                <div className={style.settingConfigDialog}>
                    <div className={style.containerBorder}>

                        <div>
                            <div>
                                <p className={style.formQuestion}>What type of notification do you want to set up?</p>

                                <div className={style.boxContainer}>
                                    <div className={style.emailBox}>
                                        <EmailIcon className={style.boxIcon} fontSize='medium'></EmailIcon>
                                        <span className={style.boxLabel}>Email</span>
                                    </div>
                                    <div className={style.smsBox}>
                                        <SmsIcon className={style.boxIcon} fontSize='medium'></SmsIcon>
                                        <span className={style.boxLabel}>SMS</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className={style.formQuestion}>What do you want to be notified about?</p>
                            </div>
                        </div>

                        <div className={style.formContainer}>
                            <div className={style.formField}>
                                <div className={style.heading}>
                                    <label className={style.formLabel}>Metrics</label>
                                </div>
                                <div className={validatorErrors["metricId"] ? style.warnSelect : ""}>
                                    <Select
                                        key={"__rs" + selectedMetricOption?.value}
                                        value={selectedMetricOption}
                                        options={metricsOptions}
                                        onChange={onMetricChange}
                                    />
                                </div>
                            </div>
                            <div className={style.formField}>
                                <div className={style.heading}>
                                    <label className={style.formLabel}>Equation</label>
                                </div>
                                <div className={validatorErrors["equation"] ? style.warnSelect : ""}>
                                    <Select
                                        value={selectedEquation}
                                        options={equationsOptions}
                                        onChange={onEquationChange}
                                        isDisabled={psaps.length <= 1}
                                        placeholder={psaps.length <= 1 ? "Equation selection disabled" : 'Select...'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={style.formContainer}>
                            <div className={style.formField}>
                                <div className={style.heading}>
                                    <label className={style.formLabel}>Conditions</label>
                                </div>
                                <div className={validatorErrors["condition"] ? style.warnSelect : ""}>
                                    <Select
                                        value={selectedCondition}
                                        options={conditionsOptions}
                                        onChange={onConditionChange}
                                    />
                                </div>
                            </div>
                            <div className={style.formField}>
                                <div className={style.heading}>
                                    <label className={style.formLabel}>Value</label>
                                </div>

                                <input
                                    type="number"
                                    className={validatorErrors["value"] ? classNames(style.formControl, style.warn) : style.formControl}
                                    placeholder="set value"
                                    maxLength={100}
                                    min={0}
                                    onChange={e => valueChange(e.target.valueAsNumber)}
                                    value={value.toString()}
                                />
                            </div>
                        </div>

                        <div className={style.formContainer}>
                            <div className={style.formField}>
                                <div className={style.heading}>
                                    <label className={style.formLabel}>PSAPs</label>
                                </div>
                                <div className={validatorErrors["psaps"] ? style.warnSelect : ""}>
                                    <SortableSelect
                                        sortOrder={GetSortOrder(sortOrder, SortableType.PSAP)}
                                        onSaveSort={onSaveSort}
                                        sortableType={SortableType.PSAP}
                                        isDisabled={disablePsap}  //  
                                        hideSelectedOptions={false}
                                        isMulti={true}
                                        value={psaps}
                                        options={psapOptions}
                                        onChange={onPSAPSelectionChange}
                                        placeholder={disablePsap ? "PSAP selection disabled" : 'Select...'} />
                                </div>
                            </div>
                        </div>

                        <div className={style.infoBoxContainer}>
                            <div className={style.infoBox}>
                                <span className={style.infoHead}>This notification will be:</span>
                                <span className={style.infoBody}>{prepareNotificationLabelMessage()}</span>
                            </div>
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <div className={style.heading}>
                                <label className={style.formQuestion}>Who will be receiving this?</label>
                            </div>

                            <textarea
                                className={validatorErrors["recipients"] ? classNames(style.formControl, style.warn) : style.formControl}
                                placeholder="aaa@intrado.com, abc@intrado.com"
                                onChange={e => setRecipients(e.target.value)}
                                onBlur={onRecipientsFieldBlur}
                                value={recipients}
                            />
                        </div>


                        <div>
                            <div className={style.heading}>
                                <label className={style.formQuestion}>Give your notification a name</label>
                            </div>

                            <input
                                type="text"
                                className={validatorErrors["name"] ? classNames(style.formControl, style.warn) : style.formControl}
                                placeholder="eg. Calls Last Min Threshold"
                                maxLength={100}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                    </div>
                </div>
            }
        </Modal >
    )
}