
import { app } from '../..';
import { WidgetOptions } from '../../components/WidgetOptions';
import { WidgetConfig, WidgetConfigBinder } from '../../services/WidgetConfigBinder';
import { MultiMetricDataTab, MultiMetricDataTabName } from '../../components/WidgetOptions/MultiMetric/MultiMetricDataTab';
import { MultiMetricDesignTab, MultiMetricDesignTabName } from '../../components/WidgetOptions/MultiMetric/MultiMetricDesignTab';
import { MultiMetricMainTab, MultiMetricMainTabName } from '../../components/WidgetOptions/MultiMetric/MultiMetricMainTab';
import { MultiMetricConfigValidator } from '../../components/WidgetOptions/MultiMetric/MultiMetricConfigValidator';
import { Tab } from '../../components/WidgetOptions/Tab';
import { Dictionary } from '../../interfaces/Dictionary';


import style from '../../scss/devview.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../interfaces/AppContext';

const MultiMetricConfigDevView = (props) => {

    // demo configuration (this will arrive from API)


    const widgetConfig: Dictionary<WidgetConfig> = {};

    const mainTabOptions: WidgetConfig = {};
    const dataTabOptions: WidgetConfig = {};
    const designTabOptions: WidgetConfig = {};


    mainTabOptions["title"] = "Enter title here";
    mainTabOptions["minValue"] = 0;
    mainTabOptions["maxValue"] = 100;
    mainTabOptions["useThreshold"] = false;
    mainTabOptions["thresholdValue"] = 0;

    dataTabOptions["selectedPsapIds"] = ["28", "10"];
    dataTabOptions["useThreshold"] = true;
    dataTabOptions["thresholdValue"] = 120;
    dataTabOptions["selectedMetrics"] = null;
    dataTabOptions["selectedMetricGroup"] = null;


    designTabOptions["titleFontValue"] = 1;
    designTabOptions["titleFontSize"] = 12;
    designTabOptions["titleFontColor"] = "#ff2800";


    widgetConfig[MultiMetricMainTabName] = mainTabOptions;
    widgetConfig[MultiMetricDataTabName] = dataTabOptions;
    widgetConfig[MultiMetricDesignTabName] = designTabOptions;


    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(widgetConfig);

    // initialize tabs for WidgetOptions component
    // some properties are required and not part of the configuration
    // just pass directly to your tab component
    // ex: psap list or metric list
    // use your custom binder class to specify which properties to autobind

    const { localization } = useContext(AppContext);
    
    const multiMetricTabs = [
        new Tab({
            name: MultiMetricDataTabName,
            label: localization["configTab1"],
            component: <MultiMetricDataTab
                psaps={app.store.state.psaps}
                metrics={app.store.state.metrics}
                psapOverride={false}
                sortOrder="" />
        }),

        new Tab({
            name: MultiMetricMainTabName,
            label: localization["configTab2"],
            component: <MultiMetricMainTab />
        }),

        new Tab({
            name: MultiMetricDesignTabName,
            label: localization["configTab3"],
            component: <MultiMetricDesignTab />
        })
    ];

    binder.initialize(multiMetricTabs);

    return (
        <div className={style.container}>
            <h3>Component development</h3>

            <WidgetOptions
                activeTab={localization["configTab1"]}
                title="Edit Bar Chart Widget"
                configBinder={binder}
                tabs={multiMetricTabs}
                onBeforeTabChange={(tab) => { return true; }}
                onTabChange={tab => { }}

                validators={MultiMetricConfigValidator}

                onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                    if (allTabsValid) {
                        console.log("Configuration valid: ", config);
                    }
                    else {
                        console.log("Configuration invalid. Errors: ", errors);
                    }
                }}
            />

        </div>
    );
}

export { MultiMetricConfigDevView }