import { ReactElement, useContext } from "react";
import { app } from "../..";
import { AppContext } from "../../interfaces/AppContext";
import style from "./style.module.scss";
import { Link } from "react-router-dom";


export interface UserDropdownMenu {
    isAdmin: boolean;
    displayAdminOption: () => void;
}

export const UserDropdownMenu = (props: UserDropdownMenu): ReactElement => {

    const { user } = useContext(AppContext);

    return (
        <div className={style.container}>
            <div className={style.menu}>
                <div className={style.option}>
                    <label>Account</label>
                    <span>{user.account.userName}</span>
                </div>
                <div className={style.option}>
                    <label>Support</label>
                    <a href="#path-to-file" target="_blank">Download User Manual</a>
                </div>
                <div className={style.option}>
                    <label>Settings</label>
                    <Link to={"/notifications"}>Custom Notifications</Link>
                    {
                        props.isAdmin &&
                        <div onClick={() => props.displayAdminOption()}>Admin</div>
                    }
                </div>
                
                <div className={style.option}>
                    <a href={`${app.apiBaseUrl}/auth/signout`}>Log Out</a>
                </div>
            </div>
        </div>
    )
}