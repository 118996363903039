import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WidgetName } from '../misc/Constants';
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { IsFunction } from "../misc/Helpers";
import style from '../scss/layoutItem.module.scss';

export interface GridItemProps {

    onLockToggle?: (event: any, sender: any) => void;
    onDelete?: (event: any, sender: any) => void;
    onEditSettings?: (event: any, sender: any) => void;
    onDuplicate?: (event: any, sender: any) => void;
    reportSizes?: boolean;
    children: JSX.Element;
    isDesignMode: boolean;
    widgetName?: string;
    innerRef?: any;
    isVisbileEdit: boolean;
}

export const GridItem = (props: GridItemProps) => {

    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    
    const ref = useRef(null);

    useEffect(() => {

        const callback = (entries: ResizeObserverEntry[]): void => {
            if (entries.length > 0) {
                const entry = entries[0];
                if (entry.borderBoxSize.length > 0) {
                    const box = entry.borderBoxSize[0];
                    setHeight(box.blockSize);
                    setWidth(box.inlineSize);
                }
            }
        };

        const obs = new ResizeObserver(callback);

        props.reportSizes !== false && obs.observe(ref.current);

        return () => {
            if (ref.current) {
                props.reportSizes !== false && obs.unobserve(ref.current);
            }
        };
    }, [props.reportSizes]);

    let model = {
        height: height,
        width: width
    };

    return (
        <div className={style.container} ref={ref}>
            {
                props.isDesignMode &&
                <div className={style.controls}>
                    <div className={style.ls}></div>
                    <div className={style.rs} onMouseDown={e => e.stopPropagation()}>
                        {
                            IsFunction(props.onEditSettings) && props.isVisbileEdit &&
                            <button
                                onClick={(e) => props.onEditSettings(e, props.children)}
                                className={classnames(style.btn)}>
                                <FontAwesomeIcon icon="pen" title="Edit Configuration" />
                            </button>
                        }
                        {
                            IsFunction(props.onDuplicate) &&
                            <button
                                hidden={props.widgetName === WidgetName.GenericContainer ? false : true}
                                onClick={(e) => props.onDuplicate(e, props.children)}
                                className={classnames(style.btn)}>
                                <FontAwesomeIcon icon="clone" title="Duplicate" />
                            </button>
                        }
                        {
                            IsFunction(props.onDelete) &&
                            <button
                                onClick={(e) => props.onDelete(e, props.children)}
                                className={classnames(style.btn, style.delete)}>
                                <FontAwesomeIcon icon="trash" title="Delete" />
                            </button>
                        }
                    </div>
                </div>
            }
            {
                React.cloneElement(props.children, { ...model })
            }
        </div>
    );
}
