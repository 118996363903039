export class IndexedDbService {
    db: IDBDatabase;
    dbName = "edash";
    dbVersion = 1;
    tableName: string;

    storeNames = [{ name: "psaps", id: "PsapId" }, { name: "dataFetchedLog", id: "tableName" }];

    constructor(tableName: string) {
        this.tableName = tableName;
        
        this.init();
    }

    init = (): void => {

        const request = indexedDB.open(this.dbName, this.dbVersion);

        request.onupgradeneeded = (e) => {
            this.db = request.result;
            this.storeNames.forEach((storeName) => {
                if (!this.db.objectStoreNames.contains(storeName.name)) {
                    this.db.createObjectStore(storeName.name, { keyPath: storeName.id });
                }
            });
        };

        request.onsuccess = (e: any) => {
            this.db = (e.target as IDBOpenDBRequest).result

            this.onReady();
        };

        request.onerror = () => {
            console.error("Error opening IndexedDb");
            
        };

        request.onblocked = () => console.warn('pending till unblocked');
    }

    onReady = async (): Promise<void> => { }
}