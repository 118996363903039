
import { app } from '../..';
import { TabState, WidgetOptions } from '../../components/WidgetOptions';
import { WidgetConfig, WidgetConfigBinder } from '../../services/WidgetConfigBinder';
import { MapDataTab, MapDataTabName } from '../../components/WidgetOptions/Map/MapDataTab';
import { MapDesignTab, MapDesignTabName } from '../../components/WidgetOptions/Map/MapDesignTab';
import { MapMainTab, MapMainTabName } from '../../components/WidgetOptions/Map/MapMainTab';
import { Tab } from '../../components/WidgetOptions/Tab';
import { Dictionary } from '../../interfaces/Dictionary';
import { MapConfigValidator } from '../../components/WidgetOptions/Map/MapConfigValidator';

import style from '../../scss/devview.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../interfaces/AppContext';
const MapConfigDevView = (props) => {

    // demo configuration (this will arrive from API)


    const mapConfig: Dictionary<WidgetConfig> = {};

    const mainTabOptions: WidgetConfig = {};
    const dataTabOptions: WidgetConfig = {};
    const designTabOptions: WidgetConfig = {};

    mainTabOptions["radius"] = 7;

    dataTabOptions["selectedPsapIds"] = ["28", "10"];
    dataTabOptions["useThreshold"] = true;
    dataTabOptions["thresholdValue"] = 120;
    dataTabOptions["selectedMetric"] = null;


    designTabOptions["title"] = "config option accepted";
    designTabOptions["titleFontValue"] = 1;
    designTabOptions["titleFontSize"] = 12;
    designTabOptions["titleFontColor"] = "#ff2800";


    mapConfig["MapMainTab"] = mainTabOptions;
    mapConfig["MapDataTab"] = dataTabOptions;
    mapConfig["MapDesignTab"] = designTabOptions;


    // config to component binder
    const binder = new WidgetConfigBinder();

    // read configuration
    binder.readConfiguration(null);

    // initialize tabs for WidgetOptions component
    // some properties are required and not part of the configuration
    // just pass directly to your tab component
    // ex: psap list or metric list
    // use your custom binder class to specify which properties to autobind

    window.setTimeout(() => {
        console.log("update config");
        binder.readConfiguration(mapConfig);
    }, 3000);

    const { localization } = useContext(AppContext);

    const mapTabs = [
        new Tab({
            name: MapDataTabName,
            label: localization["configTab1"],
            component: <MapDataTab
                psaps={app.store.state.psaps}
                metrics={app.store.state.metrics}
                sortOrder={{}} />
        }),

        new Tab({
            name: MapMainTabName,
            label: localization["configTab2"],
            component: <MapMainTab />
        }),

        new Tab({
            name: MapDesignTabName,
            label: localization["configTab3"],
            component: <MapDesignTab />
        })
    ];

    binder.initialize(mapTabs);

    return (
        <div className={style.container}>
            <h3>Component development</h3>

            <WidgetOptions
                activeTab={localization["configTab1"]}
                title="Edit Map Widget"
                configBinder={binder}
                tabs={mapTabs}


                onBeforeTabChange={(tab: TabState, isValid: boolean, errors) => {

                    return true;
                }}

                onTabChange={(tab: TabState) => { }}

                validators={MapConfigValidator}

                onSaveChanges={(allTabsValid: boolean, errors: string[], config: any) => {
                    if (allTabsValid) {
                        console.log("Configuration valid: ", config);
                    }
                    else {
                        console.log("Configuration invalid. Errors: ", errors);
                    }
                }}
            />

        </div>
    );
}

export { MapConfigDevView }