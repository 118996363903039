import { components, OptionProps, PropsValue } from "react-select";
import style from "./optstyle.module.scss";
import { OptionType } from "../../models/OptionType";
import { ReactElement } from "react";
import classNames from "classnames";
/*
export const OptionDescription = ({ children, ...props }: OptionProps<any>) : ReactElement => {
    return (
        <components.Option {...props}>
            <strong className={style.optionbold}>{children}:</strong>
            <span className={style.optiondescription}>{props.data.description}</span>
        </components.Option>
    );
}*/

export type OptionTypeEq = OptionType & { description: string }

export const Option = ({ children, ...props }: OptionProps<OptionTypeEq>) => {


    return (
        <components.Option {...props}>
            <strong className={classNames(style.optionbold, props.isSelected ? style.selected : null)}>{children}:</strong>
            <span className={classNames(style.optiondescription, props.isSelected ? style.selected : null)}>{props.data.description}</span>
        </components.Option>
    );
};
