import { OptionType } from "../models/OptionType";

export enum PubSubTopic {
    Action = "action",
    Stream = "stream",
    Changes = "changes",
    EventsBackgroundClick = "events.backgroundclick",
    EventsKeyDown = "events.keydown",
    EventsKeyUp = "events.keyup",
    Grid = "component.grid"
};

// Define initial and minimum widget dimensions
export const DefaultWidgetDimensions = {
    h: 10,
    w: 8,
    minH: 5,
    minW: 5    
};

export enum WidgetName {
    Empty = "",
    Bars = "barWidget",
    Circle = "circleWidget",
    Map = "mapWidget",
    Numeric = "numericWidget",
    Ring = "ringWidget",
    SemiCircle = "semiCircleWidget",
    GenericContainer = "genericContainerWidget",
    MultiRing = "multiRingWidget",
    SortingContainer = "sortingContainerWidget"
}

export const Operators = [{ value: '<', label: 'Less Than' }, { value: '>', label: 'Greater Than' }];

export const ReactSelectStyles = {

    control: (provided, state) => ({
        ...provided,
        borderColor: '#ededed',
        backgroundColor: '#F7F7F7',
        minHeight: '31px',
        height: '31px',
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '31px',
        padding: '0 6px 2px',
        fontSize: '10pt'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),

    indicatorSeparator: state => ({
        display: 'none',
    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        fontSize: '13px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#555'
    })
};

export const ReactSelectStylesWarn = {
    ...ReactSelectStyles,
    control: (provided, state) => ({
        ...provided,
        borderColor: '#B21E1E' 
    }),

};

export const ReactMultiSelectStyles = {

    control: (provided, state) => ({
        ...provided,
        borderColor: '#ededed',
        backgroundColor: '#F7F7F7',
        fontSize: '10pt'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),

    indicatorSeparator: state => ({
        display: 'none',
    }),


    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        fontSize: '13px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#555'
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: '#B21E1E'
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        fontSize: '10pt',
        whiteSpace: "normal"
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: state.isSelected ? 'solid 1px #B4C0C6' : 'none',
        backgroundColor: state.isSelected ? "#20638f" : "#fff"
    }),
};

export const ReactMultiSelectStylesWarn = {
    ...ReactMultiSelectStyles,
    control: (provided, state) => ({
        ...provided,
        borderColor: '#B21E1E',
        fontSize: '10pt' 
    }),

};

export const FontSizes = [8, 9, 10, 11, 12, 14, 15, 16, 18, 20, 22, 24, 26, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100];

export const FontOptions = [
    { label: "Roboto", value: 0 },
    { label: "Open Sans", value: 1 },
    { label: "Poppins", value: 2 },
    { label: "Arial", value: 3 }
] as OptionType[];

// List of colors to be used as default for bar labels
export const defaultMetricColors = [
    "#FFB000",
    "#DC267F",
    "#785EF0",
    "#2C3E50",    
    "#2980B9",
    "green",
    "blue",
    "indigo",
    "turquoise",
    "gray",
    "SaddleBrown",
    "gold",
    "lightsteelblue",
    "mediumvioletred",
    "DarkSlateGray",
    "MediumAquaMarine",
    "Chocolate",
    "DarkOliveGreen",
    "lightsalmon",
    "CornflowerBlue"
]

// Default fonts types and sizes used in design tab and in config adapters when not configured.
export const defaultTitleFontIndex = 3; // Arial
export const defaultTitleFontSize = 15;
export const defaultValueFontIndex = 3; // Arial
export const defaultValueFontSize = 15;
export const defaultLegendFontIndex = 3;
export const defaultLegendFontSize = 12;
export const defaultNumberWidgetValueFontSize = 48;

export const BarChartWidgetMinMaxValues = {
    min: 0,
    max: 100
}

export const MultiMetricMinMaxValues = {
    min: 0,
    max: 100
}

export const SingleMetricWidgetMinMaxValues = {
    min: 0,
    max: 100
}

export const SingleMetricWidgetMinMaxThresholdValues = {
    min: 0,
    max: 100
}

export const defaultThresholdColor = "#ED1E1E";

export const EmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;