import { IsNotUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { LocationData } from "@ecats911/edash-widgets-map";

// Function definitions
enum BindFunctions {
    /*
    ** Bind functions for call array streams
    */

    // Can be reused. Was originally planned to be a simple item count with no propertly match, but Aggregate already does this if no property is specified
    None,

    // Unused bind function.
    Aggregate,

    // Counts all item if property specified in bind match a specific value. Requires a configurable string to be passed for comparison. Not used yet, but will be required for 'Calls Transferred To X' metrics
    AggregateWithPropertyMatch,

    // This function aggregates, adds an offset and multiply with multiplicator. Used by bandwidth calculation metric (ECATS-3844). The value offset and multiplicator will need to be provided by configuration. Hardcoded for now
    AggregateAndMultiply,

    // This function aggregates, apply a multiplicator and calculate a percentage ratio based on maxValue. Used by bandwidth calculation metric (ECATS-3844)
    AggregateAndMultiplyToPercentRatio,

    // Calculate a ratio in percentage. Not implemented. Would need a configurable value to use for ratio
    PercentRatio,

    // For Map widget, extract and return geodetic coordinates from all calls
    GoogleCoordinates,

    /*
    ** Bind functions for numerical values streams
    */

    // Use value as is with no modification
    UseValue = 1000,

    // Round value
    RoundValue = 1001,
}

export interface IDataModifier{
    data: any[] | number;
    bind?: string;
    defaultValue?: any;
    multiplicator?: number;
    valueOffset?: number;
    minValue?: number;
    maxValue?: number;
    propertyValue?: string;
}

const bindFunctionEnumToFct = (bindFunctionId : BindFunctions) : Function => {

    if (bindFunctionId === BindFunctions.None)
        return Aggregate;
    else if (bindFunctionId === BindFunctions.Aggregate)
        return Aggregate;
    else if (bindFunctionId === BindFunctions.AggregateWithPropertyMatch)
        return Aggregate; // AggregateWithPropertyMatch;  Temporarily return Aggregate instead of AggregateWithPropertyMatch because propertyName to match is not yet provided
    else if (bindFunctionId === BindFunctions.AggregateAndMultiply)
        return AggregateAndMultiply;
    else if (bindFunctionId === BindFunctions.AggregateAndMultiplyToPercentRatio)
        return AggregateAndMultiplyToPercentRatio;
    else if (bindFunctionId === BindFunctions.PercentRatio)
        return GoogleCoordinates;
    else if (bindFunctionId === BindFunctions.UseValue)
        return UseValue;
    else if (bindFunctionId === BindFunctions.RoundValue)
        return RoundValue;
    else 
        return Aggregate;
}

const Aggregate = (props: IDataModifier) : number =>{
	if (!Array.isArray(props.data)){
        return props.defaultValue;
    }

	// If no data provided, or default value is provided as the unique array element
    if (props.data === null || (props.data.length === 1 && props.data[0] === props.defaultValue))
        return props.defaultValue;
       
    let arr =  props.data.map(v=>{
        if(v === null)
            return 0;
    
				// If a bind property is specified, return the value of the bound property
				if (props.bind != null && props.bind.length > 0) {
					const b = v[props.bind];
					if(typeof b !== "undefined" && b !== null)
							return  b as number;
					else return 0;
				}
				// No specific bind property provided, count as 1
				else {
					return 1;
				}

    });
    
    if(arr === null || arr.length < 1)
        return 0;

    return arr.reduce((x, y)=>{return x + y;}) ?? 0; 
};

const AggregateWithPropertyMatch = (props: IDataModifier) : number =>{
	if (!Array.isArray(props.data)){
        return props.defaultValue;
    }

	// If no data provided, or default value is provided as the unique array element
    if (props.data === null || (props.data.length === 1 && props.data[0] === props.defaultValue))
        return props.defaultValue;
       
    let arr =  props.data.map(v=>{
        if(v === null)
            return 0;
    
				// If a bind property is specified, count it only if its value matches propertyValue
				if (props.bind != null && props.bind.length > 0) {
					const b = v[props.bind];
					if(typeof b !== "undefined" && b !== null && IsNotUndefinedOrNull(props.propertyValue))
							return (b === props.propertyValue ? 1 : 0) as number;
					else return 0;
				}
				// No specific bind property provided, do not cound
				else {
					return 0;
				}

    });
    
    if(arr === null || arr.length < 1)
        return 0;

    return arr.reduce((x, y)=>{return x + y;}) ?? 0;
 
};

// This function aggregates, adds an offset and multiply with multiplicator. Used by bandwidth calculation metric (ECATS-3844). The value offset and multiplicator will need to be provided by configuration. Hardcoded for now
const AggregateAndMultiply = (props: IDataModifier) : number =>{
    let valueOffset = IsNotUndefinedOrNull(props.valueOffset) ? props.valueOffset : 2000
    let multiplicator = IsNotUndefinedOrNull(props.multiplicator) ? props.multiplicator : 213
	let totalCalls = Aggregate(props);
	return valueOffset + (totalCalls * multiplicator);

};
// This function aggregates, apply a multiplicator and calculate a percentage ratio based on maxValue. Used by bandwidth calculation metric (ECATS-3844)
const AggregateAndMultiplyToPercentRatio = (props: IDataModifier) : Number =>{  
    let valueOffset = IsNotUndefinedOrNull(props.valueOffset) ? props.valueOffset : 0
    let multiplicator = IsNotUndefinedOrNull(props.multiplicator) ? props.multiplicator : 213
    let maxValue = IsNotUndefinedOrNull(props.maxValue) ? props.maxValue : 10000
    let totalCalls = Aggregate(props);
    return +(((valueOffset + (totalCalls * multiplicator)) / maxValue * 100)).toFixed(1);
  }
  
// This function calculate a percentage based on maxValue
const PercentRatio = (props: IDataModifier) : number =>{  
  let totalCalls = Aggregate(props);
  return totalCalls / props.maxValue * 100;
}

// This function is indended for the Map widget. It extracts the pos parameter from calls in the stream, split in lat/long and return in an array of LatLng
const GoogleCoordinates = (props: IDataModifier) : LocationData[] => {
	
    let psapCoords = new Array<LocationData>();
    let arr = props.data as any[];
	arr.forEach(call => {
		if (!StringIsNullOrWhiteSpace(call.ps)) {
			let lat = Number(call.ps.split(' ')[0]);
			let long = Number(call.ps.split(' ')[1]);

			if (!isNaN(lat) && lat !== 0 && !isNaN(long) && lat !== 0) {
				let item : LocationData = {
                    locationName : call.psap,
                    location : new google.maps.LatLng(lat, long)
                }
                
				psapCoords.push(item);
			}
		}
	})	
	return psapCoords;
}

// This function uses the provided value as is
const UseValue = (props: IDataModifier) : number =>{      
    return props?.data as number;
  }

// This function uses the rounded value
const RoundValue = (props: IDataModifier) : number =>{      
    return Math.round(props?.data as number);
  }
  
export { Aggregate } 
export { AggregateWithPropertyMatch } 
export { AggregateAndMultiply }
export { AggregateAndMultiplyToPercentRatio }
export { PercentRatio }
export { GoogleCoordinates }
export { bindFunctionEnumToFct }
export { BindFunctions }