import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from './style.module.scss';
import { SortableType } from "./SortableSelect";
import { OptionType } from "../../models/OptionType";
import { SortableArea } from "./SortableArea";
import { IsFunction, IsUndefinedOrNull } from "../../misc/Helpers";
import { SortAscending, SortDescending } from "./SortFunctions";
import { SortConstants } from "./SortConstants";

export interface SortableContainerProps {
    sortOrder?: string;
    type: SortableType;
    options: OptionType[];
    selectedOptions: OptionType[];
    onCancel: () => void;
    onSaveSort: (sortOrder: string, sorted: any[], type: SortableType) => void;
}

export const SortableContainer = (props: SortableContainerProps): JSX.Element => {
    const [isManualSort, setIsManualSort] = useState(props.sortOrder === SortConstants.manual ? true : false);
    const [sortOrder, setSortOrder] = useState(IsUndefinedOrNull(props.sortOrder) ? null : props.sortOrder);
    const [selectedOptions, setSelectedOptions] =  useState([]); 
    const [selectedItems, setSelectedItems] = useState([]);

    // Sort Event Handlers
    const onSortAscending = (): void => {
        setSortOrder(SortConstants.ascending);
        setIsManualSort(false);
        setSelectedOptions(SortAscending(selectedOptions));
    }

    const onSortDescending = (): void => {
        setSortOrder(SortConstants.descending);
        setIsManualSort(false);
        setSelectedOptions(SortDescending(selectedOptions));
    }

    const onSortDataReceived = (sorted: string[]): void => {
        setSelectedItems(sorted);
    }

    const onSortManual = (): void => {
        setSortOrder(SortConstants.manual);
        setIsManualSort(true);
    }

    const onRemove = (itemToRemove: string): void => {
        setSelectedOptions(selectedOptions.filter(x => x.label !== itemToRemove));
        setSelectedItems(selectedItems.filter(x => x !== itemToRemove));
    }

    const onSaveSort = (): void => {
        if (sortOrder !== SortConstants.manual) {
            IsFunction(props.onSaveSort) && props.onSaveSort(sortOrder, selectedOptions, props.type);
        }
        else {
            const selectedOptionsSorted = selectedItems.map((item) => {
                return props.options.find(x => x.label === item);
            })
            setSelectedOptions(selectedOptionsSorted);
            IsFunction(props.onSaveSort) && props.onSaveSort(sortOrder, selectedOptionsSorted, props.type);
        }
    }

    useEffect(() => {
        let options:OptionType[] = [];
        let selection:string[] = [];
        props.selectedOptions.forEach((option) => {
            options.push(option);
            selection.push(option.label);
        });
        setSelectedOptions(options);
        setSelectedItems(selection);
    }, []);

    return (
        <div className={style.sortableContainer}>
            <div className={style.title}>
                {
                    props.type === SortableType.Metric &&
                    <span>Sort Selected Metrics</span>
                }
                {
                    props.type === SortableType.PSAP &&
                    <span>Sort Selected PSAPs</span>
                }
            </div>
            <div className={style.buttons}>
                <div className={style.sortButtons}>
                    <button className={sortOrder === SortConstants.ascending ? style.selected : null} onClick={onSortAscending}><FontAwesomeIcon icon="arrow-down-a-z" />Ascending</button>
                    <button className={sortOrder === SortConstants.descending ? style.selected : null} onClick={onSortDescending}><FontAwesomeIcon icon="arrow-up-z-a" />Descending</button>
                    <button className={sortOrder === SortConstants.manual ? style.selected : null} onClick={onSortManual}><FontAwesomeIcon icon="bars" />Manual</button>
                </div>
                <div className={style.cancelSaveButtons}>
                    <button onClick={props.onCancel}>Cancel</button>
                    <button className={style.confirmButton} onClick={onSaveSort}>Save</button>
                </div>
            </div>
            <div className={style.selectedItems}>
                {
                    isManualSort &&
                    <SortableArea
                        selectedValues={selectedItems}
                        onRemove={onRemove}
                        onSaveSort={onSortDataReceived} />
                }
                {
                    !isManualSort &&
                    selectedOptions.map((item) => {
                        return <div className={style.item} key={item.value}>{item.label}<FontAwesomeIcon icon="x" title="Remove item from the list" onClick={(e) => onRemove(item.label)} /></div>
                    })
                }
            </div>
        </div>
    )
} 