import { ReactElement, useContext, useState } from "react";
import { Modal } from "./Modal";
import Select from "react-select";
import shared from "../components/WidgetOptions/sharedstyle.module.scss";
import axios from "axios";
import { app } from "..";
import { IsNotUndefinedOrNull, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../misc/Helpers";
import { AppContext } from "../interfaces/AppContext";
import { AssociatedUser } from "../models/AssociatedUser";
import { OptionType } from "../models/OptionType";
import { TooltipControl } from "./ToolTipControl";

export interface ShareLayoutDialogProps {
    layoutId: string;
    title: string;
    okButtonText?: string;
    associatedUser: AssociatedUser[];

    onCancel: () => void;
}


// what does this function returns?
// we're pushing numbers and strings?
// TODO: refactor this
function getResultMessage(status: number, users?: any[]) : any[] {
    let result = [];
    if (status === 200) {
        let successMessage = 'Successfuly shared the layout with: ';
        for (let i = 0; i < users.length; i++) {
            successMessage += users[i].label;
            if (i < users.length - 1) {
                successMessage += ', ';
            }
            else {
                successMessage += '.';
            }
        }
        result.push(status);
        result.push(successMessage);
    }
    else {
        result.push(500);
        result.push('Unable to share the layout. Please try again later or contact the system administrator');
    }

    return result;
}

export const ShareLayoutDialog = (props: ShareLayoutDialogProps): ReactElement => {

    const userOptions: OptionType[] = props.associatedUser.map(user => {
        return { label: user.displayName, value: user.userId } as OptionType
    });
 

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [resultMessage, setResultMessage] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState("Loading data please wait...");

    if (IsUndefinedOrNull(props.associatedUser)) {
        setTimeout(() => setLoadingMessage("Cannot load data. Please try again later."), 5000);
    }
    
    const context = useContext(AppContext);
    const customerId = context.user.customerIds[0];

    const onShareUserSelectionChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];
        setSelectedUsers(optionsArr);

        // If we have 0 users selected, unset equation
        if (optionsArr.length === 0) {
            setSelectedUsers([]);
        }
    };

    const onShare = (layoutId: string, users: any[], customerId?: string): void => {

        const usersIds = users.map(user => {
            return user.value;
        });

        const request = axios.post(`${app.apiBaseUrl}/api/layout/share`, {
            layoutId: layoutId,
            usersIds: usersIds,
            customerId: customerId
        });

        request.then(response => {
            setResultMessage(getResultMessage(response.status, users));
            setSelectedUsers([]);
        });

        request.catch(error => {
            console.log(error);
            setResultMessage(getResultMessage(500));
        });
    }

    return (
        <Modal
            handleCancel={props.onCancel}
            handleOk={() => onShare(props.layoutId, selectedUsers, customerId)}
            okDisabled={selectedUsers.length === 0}
            headerText={props.title}
            cancelButtonText={"Cancel"}
            okButtonText={StringIsNullOrWhiteSpace(props.okButtonText) ? "Share" : props.okButtonText}>
            {
                IsNotUndefinedOrNull(userOptions) &&
                <div style={{ paddingLeft: 10, paddingRight: 10 }} className={shared.row}>
                    <div className={`${resultMessage[0] === 200 ? shared.successmessage : shared.errormessage}`}>
                        <p>{resultMessage[1]}</p>
                    </div>
                    <div className={shared.heading}>
                        <TooltipControl children={undefined} label={"Select user(s)"} placement="right" showInfoIcon={true} title={"Select user(s) to share the layout with - required"} />
                    </div>
                    {userOptions.length > 0 &&
                        <Select
                            hideSelectedOptions={false}
                            isMulti={true}
                            value={selectedUsers}
                            options={userOptions}
                            onChange={onShareUserSelectionChange} />
                    }
                    {
                        userOptions.length == 0 &&
                        <p>No users available to select.</p>
                    }
                </div>
            }
            {
                IsUndefinedOrNull(userOptions) &&
                <div style={{ paddingLeft: 10, paddingRight: 10 }} className={shared.row}>
                    <p>{loadingMessage}</p>
                </div>
            }
        </Modal>
    );
};