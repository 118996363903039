import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PubSubTopic } from '../misc/Constants';
import { Header } from './partials/Header';
import { Layout } from '../models/DashboardLayout';
import { DashboardNotification, DashboardNotificationLocation } from "../models/DashboardNotification";
import { DashboardNotificationAlert } from '../components/DashboardNotificationAlerts/DashboardNotificationAlert';
import { DashboardConfirmationAlert } from '../components/DashboardNotificationAlerts/DashboardConfirmationAlert';
import { DashboardNotificationAlertAtTheBottom } from '../components/DashboardNotificationAlerts/DashboardNotificationAlertAtTheBottom';
import { app } from "../";

export interface ConfigurationMasterProps {
    layouts: Layout[];
    dashboardNotifications: DashboardNotification[]
}

const ConfigurationMaster = (props: ConfigurationMasterProps): JSX.Element => {

    const keysToMonitor = [
        'Escape',
        'Delete',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Control',
        'Shift',
        'Enter'
    ];

    const confirmationAlert = JSON.parse(localStorage.getItem(app.store.state.user.account.id + "_ShowConfirmationWindow"));
    const [showConfirmation, setShowConfirmation] = useState(confirmationAlert !== null ? true : false);

    useEffect(() => {

        const BackgroundClickHandler = (event) => {
            PubSub.publish(PubSubTopic.EventsBackgroundClick);
        };

        const KeyPressHandler = (event) => {
            const key = event.key;

            if (keysToMonitor.some(k => k === key)) {
                PubSub.publish(PubSubTopic.EventsKeyDown, key);
            }
        };

        const KeyReleaseHandler = (event) => {
            const key = event.key;

            if (keysToMonitor.some(k => k === key)) {
                PubSub.publish(PubSubTopic.EventsKeyUp, key);
            }
        };

        setTimeout(() => {
            localStorage.removeItem(app.store.state.user.account.id + "_ShowConfirmationWindow");
            setShowConfirmation(false);
        }, 5000);

        const elem = document.getElementsByTagName("body");

        if (elem.length > 0) {
            elem[0].addEventListener("click", BackgroundClickHandler);

            elem[0].addEventListener("keydown", KeyPressHandler);
            elem[0].addEventListener("keyup", KeyReleaseHandler);
        }

        return () => {
            if (elem.length > 0) {
                elem[0].removeEventListener("click", BackgroundClickHandler);
                elem[0].removeEventListener("keydown", KeyPressHandler);
                elem[0].removeEventListener("keyup", KeyReleaseHandler);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <Header layoutContext="regional" layouts={props.layouts} hideLayoutControls={true} />
            <Outlet />
            {
                props.dashboardNotifications.filter(x => !x.acknowledged).map(notification => {
                    if (notification.renderLocation === DashboardNotificationLocation.Default) {
                        return <DashboardNotificationAlert key={notification.id} dashboardNotification={notification} />
                    }
                    else {
                        return <DashboardNotificationAlertAtTheBottom key={notification.id} dashboardNotification={notification} />
                    }
                })
            }
            {
                showConfirmation &&
                <DashboardConfirmationAlert dashboardNotification={confirmationAlert} />
            }
        </React.Fragment>
    );
}

export { ConfigurationMaster }