import { MultiRingDesignTabProps } from './MultiRingDesignTab';
import { MultiRingMainTabProps } from './MultiRingMainTab';
import { MultiRingDataTabProps } from './MultiRingDataTab';
import { ValidationResult } from "../../WidgetOptions"
import { IsUndefinedNullOrEmpty } from '../../../misc/Helpers';
import { RowDefinition } from '../../../models/Metric';

/*  order of parameters in validation functions must follow order of tabs else mapping will not work */
export const MultiRingConfigValidator = [

    (data: MultiRingDataTabProps, main: MultiRingMainTabProps, design: MultiRingDesignTabProps, tabLabel: string): ValidationResult => {

        const pass = data.selectedMetrics?.length <= 3;

        if (pass) {
            return { isValid: true, message: "" }
        }
        else {
            return { isValid: false, message: `Maximum 3 metrics allowed`, tabName: tabLabel }
        }
    },

    (data: MultiRingDataTabProps, main: MultiRingMainTabProps, design: MultiRingDesignTabProps, tabLabel: string): ValidationResult => {

        const a = main?.minMaxValues?.[0]?.minValue >= main?.minMaxValues?.[0]?.maxValue;
        const b = main?.minMaxValues?.[1]?.minValue >= main?.minMaxValues?.[1]?.maxValue;
        const c = main?.minMaxValues?.[2]?.minValue >= main?.minMaxValues?.[2]?.maxValue;
        
        if (!a && !b && !c) {
            return { isValid: true, message: "" }
        }
        else {
            return { isValid: false, message: "Verify min-max range settings", tabName: tabLabel }
        }
    },
    (data: MultiRingDataTabProps, main: MultiRingMainTabProps, design: MultiRingDesignTabProps, tabLabel: string): ValidationResult => {

        const ta = (data.useMaxThreshold && data.useMinThreshold) && data?.thresholdValues?.[0]?.minValue >= data?.thresholdValues?.[0]?.maxValue;
        const tb = (data.useMaxThreshold && data.useMinThreshold) && data?.thresholdValues?.[1]?.minValue >= data?.thresholdValues?.[1]?.maxValue;
        const tc = (data.useMaxThreshold && data.useMinThreshold) && data?.thresholdValues?.[2]?.minValue >= data?.thresholdValues?.[2]?.maxValue;

        if (!ta && !tb && !tc) {
            return { isValid: true, message: "" }
        }
        else {
            return { isValid: false, message: "Verify min-max threshold settings", tabName: tabLabel }
        }

        return { isValid: true, message: "" }
    },
    
    // Metric validator
    (data: MultiRingDataTabProps, main: MultiRingMainTabProps, design: MultiRingDesignTabProps, tabLabel: string): ValidationResult => {
        if (IsUndefinedNullOrEmpty(data.selectedMetrics) || data.selectedMetrics.length === 0)
            return { isValid: false, message: "At least one metric (column) must be selected", tabName: tabLabel };
        return { isValid: true, message: "" }        
    },
    // PSAP validator
    (data: MultiRingDataTabProps, main: MultiRingMainTabProps, design: MultiRingDesignTabProps, tabLabel: string): ValidationResult => {

        // Allow not having any selected psap if the selected metric group contains metrics with rowDefinition=None
        let metricGroupWithNoRow = false;
        if (data.metrics.filter(m => m.metricGroup === data.selectedMetricGroup).every(m2 => m2.rowDefinition === RowDefinition.None)) 
            metricGroupWithNoRow = true;

        if (!data.psapOverride && !metricGroupWithNoRow && (IsUndefinedNullOrEmpty(data.selectedPsapIds) || data.selectedPsapIds.length === 0))
            return { isValid: false, message: "At least one PSAP (row) must be selected", tabName: tabLabel };
        return { isValid: true, message: "" }
    }        
];