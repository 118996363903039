import React, { useContext, useEffect, useState } from "react";
import shared from '../sharedstyle.module.scss';
import { UserPsaps } from "../../../models/UserPsaps";
import { TabComponent } from "../Tab";
import { ReactSortable } from "react-sortablejs";
import style from './style.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortingContainerDataTabName, SortingContainerDataTabProps } from "./SortingContainerDataTab";
import { TabContext } from "../../WidgetOptions";
import { IsNotUndefinedOrNull } from "../../../misc/Helpers";
import { LabelControlWithToolTip } from "../../Labels/LabelControlWithToolTip";

interface PsapItemType {
    id: string;
    name: string;
}

export interface SortingContainerSortingTabProps extends TabComponent {
    psaps: UserPsaps[];
    orderedPsaps?: PsapItemType[];
}

export const SortingContainerSortingTabName = "SortingContainerSortingTab";

export const SortingContainerSortingTab = (props: SortingContainerSortingTabProps): JSX.Element => {

    const context = useContext(TabContext);
    const dataTab = context.data[SortingContainerDataTabName] as SortingContainerDataTabProps;
    const selectedPsapsIds: string[] = Array.isArray(dataTab.selectedPsapIds) ? dataTab.selectedPsapIds : [];
    const selectedPsaps: UserPsaps[] = props.psaps.filter(psap => selectedPsapsIds.some(p => p === psap.nenaIdentifier));

    function orderChanged(updatedList) {

        typeof props.onChange === "function" && props.onChange("orderedPsaps", updatedList);
        setPsapOrder([...updatedList])
    }

    const [psapOrder, setPsapOrder] = useState(IsNotUndefinedOrNull(props.orderedPsaps) ? props.orderedPsaps : selectedPsaps.map(i => { return { id: i.nenaIdentifier, name: i.psapName } }));

    useEffect(() => {
        let updatedList: PsapItemType[] = psapOrder.filter(i => selectedPsaps.some(p => i.id === p.nenaIdentifier))
        let notInOrderedList = selectedPsaps.filter(i => updatedList.every(p => i.nenaIdentifier !== p.id))
        notInOrderedList.map(i => { return { id: i.nenaIdentifier, name: i.psapName } }).forEach(p => updatedList.push(p))
        orderChanged(updatedList);
    }, []);

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <LabelControlWithToolTip label={"PSAP Manual Ordering"} placement="right" title={"Select the PSAP container order within the sorting container"} />
                </div>

                <ReactSortable className={style.sortableList} list={psapOrder} setList={orderChanged}>
                    {psapOrder.map((item) => (
                        <div className={style.sortableItem} key={item.id}><FontAwesomeIcon key={item.id + "_icon"} className={style.arrowIcon} icon={"arrows-up-down"} /><div className={style.sortableItemText} key={item.id + "_text"}> {item.name}</div></div>
                    ))}
                </ReactSortable>
            </div>
        </React.Fragment>
    );
}