import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactElement, useContext, useState } from "react";
import { AppContext } from "../../interfaces/AppContext";
import { IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../misc/Helpers";
import { ContextMenu, LayoutOwnerOptions, LayoutInheritorOptions, ContextOption } from "./ContextMenu";
import style from "./style.module.scss";
import { app } from '../..';

export type LayoutOption = {
    layoutId: string;
    label: string;
    viewOnly: boolean;
};

export type LayoutClickAction = "create-layout" | "switch-layout" | "rename" | "share" | "duplicate" | "delete";

export interface LayoutDropdownProps {
    label?: string;
    options: LayoutOption[];

    onAction: (layoutId: string, action: LayoutClickAction) => void;
}

export const LayoutDropdown = (props: LayoutDropdownProps): ReactElement => {

    const context = useContext(AppContext);

    const activeLayoutId = context.user.account.settings.activeLayoutId;

    const contextOptions = IsUndefinedOrNull(props.options) ? [] : props.options;

    if (!Array.isArray(contextOptions)) {
        throw new TypeError("Unexpected options argument type");
    }

    const [options, setOptions] = useState(contextOptions);
    const [activeOptionId, setActiveOptionId] = useState("");

    const onBackgorundClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        closeLayoutOptions();
    };

    const onItemSettingsClick = (option: LayoutOption, e: React.MouseEvent): void => {
        e.stopPropagation();
        setActiveOptionId(option.layoutId);
    };

    const onLayoutOptionClick = (option: LayoutOption, event: React.MouseEvent): void => {
        if (option.layoutId !== activeLayoutId) {
            props.onAction(option.layoutId, "switch-layout");
        }
    };

    const closeLayoutOptions = (): void => {
        setActiveOptionId("");
    };

    return (
        <div onClick={onBackgorundClick}>
            <div className={style.container}>
                <div className={style.menu}>
                    {
                        !StringIsNullOrWhiteSpace(props.label) &&
                        <label>{props.label}</label>
                    }
                    {
                        options.map(opt => {
                            return (
                                <div
                                    key={opt.layoutId}
                                    className={
                                        classNames(
                                            style.option,
                                            opt.layoutId === activeOptionId ? style.active : null,
                                            opt.layoutId === activeOptionId ? style.selected : null
                                        )
                                    }
                                >
                                    <span onClick={e => onLayoutOptionClick(opt, e)}>{opt.label}</span>
                                    <button hidden={!app.store.state.user.isAllowedEdit} onClick={e => onItemSettingsClick(opt, e)}>
                                        <FontAwesomeIcon icon="ellipsis" />
                                    </button>
                                    {
                                        opt.layoutId === activeOptionId &&
                                        <ContextMenu
                                            onOptionClick={co => props.onAction(opt.layoutId, co.action)}
                                            onClose={closeLayoutOptions}
                                            options={opt?.viewOnly ? LayoutInheritorOptions : LayoutOwnerOptions}
                                        />
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                {app.store.state.user.isAllowedEdit &&
                    <div>
                        <hr />
                        <div className={classNames(style.menu, style.submenu)}>
                            <div className={style.option}>
                                <button onClick={() => props.onAction("", "create-layout")}>
                                    <strong>Create New Layout</strong>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
};