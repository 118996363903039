import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { DataAdapterProps } from "./DataAdapter";
import { ContainerMainTabProps, ContainerMainTabName } from "../components/WidgetOptions/Container/ContainerMainTab";
import { ContainerDesignTabProps, ContainerDesignTabName } from "../components/WidgetOptions/Container/ContainerDesignTab";
import { IsUndefinedOrNull } from "../misc/Helpers";
import { defaultTitleFontIndex, defaultTitleFontSize, FontOptions, WidgetName } from "../misc/Constants";
import { Styles } from "../interfaces/Dictionary";
import { GenericContainerProps } from "../components/GenericContainer";

export class ContainerConfigAdapter extends WidgetConfigAdapter<GenericContainerProps> {

    widgetId: string = "";
    main: ContainerMainTabProps;
    design: ContainerDesignTabProps;

    constructor(widgetId: string, config: object) {
        super();
        this.widgetId = widgetId;

        this.main = config[ContainerMainTabName];
        this.design = config[ContainerDesignTabName];

        if (IsUndefinedOrNull(this.main))
            this.main = {};

        if (IsUndefinedOrNull(this.design))
            this.design = {};
    }

    private getStylesForContainerWidget(): Styles {
        return {
            containerTitle: {
                color: this.design.containerTitleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.containerTitleFontValue)?.label ?? FontOptions[defaultTitleFontIndex].label,
                fontSize: this.design?.containerTitleFontSize ?? defaultTitleFontSize,
            },
            widgets: {
                color: this.design.widgetTitleFontColor,
                fontFamily: FontOptions.find(font => font.value === this.design.widgetTitleFontValue)?.label ?? "Arial",    // Not used for now
                fontSize: this.design.widgetTitleFontColor
            }
        }
    };

    getDataConfiguration = (): DataAdapterProps => {
        return null;
    }

    getWidgetConfiguration = (widgetName: WidgetName): GenericContainerProps => {

        let style = this.getStylesForContainerWidget();
        
        return {
            title: this.main?.title,
            style: { ...style }
        };
    }
}